import React from "react";
import { Box, Button, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import { ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import { API_STATUS } from "../../../../context/juno/VideoContext";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

// --------------------------------------------------
// 型定義
// --------------------------------------------------
type VideoType = {
  uuid: string;
  status: number;
  params: {
    action: string;
    aiEngine: string;
    model: string;
    prompt: string;
    duration: number;
    aspectRatio: string;
  };
  url: string;
};

interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  videos: VideoType[];
  currentIndex: number; // 現在の動画Index
  handleNextVideo: () => void; // 次の動画へ
  handlePrevVideo: () => void; // 前の動画へ
  // 親側の同じ削除ダイアログを開く関数
  handleOpenDeleteDialog: (uuid: string) => void;
  // 動画ダウンロード
  handleVideoDownload: (uuid: string) => Promise<void>;
}

/**
 * MUI Modal で動画を表示し、
 * 左右ボタンでの動画切り替えと削除・ダウンロードを行うコンポーネント
 */
const VideoModal: React.FC<VideoModalProps> = ({
  open,
  onClose,
  videos,
  currentIndex,
  handleNextVideo,
  handlePrevVideo,
  handleOpenDeleteDialog,
  handleVideoDownload,
}) => {
  // videos から currentIndex で動画を取得
  const modalVideo = videos[currentIndex] ?? null;
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          outline: "none",
          borderRadius: 2,
          maxHeight: "95vh",
        }}
        overflow={"auto"}
      >
        {/* 閉じるボタン */}
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>

        {modalVideo && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* 動画のAIエンジン情報など */}
              <RowBox alignItems="center" justifyContent="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    px: 1,
                    py: 0.2,
                    mr: 2,
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                    borderRadius: "2px",
                    display: "inline-flex",
                  }}
                  color="#fff"
                  component="span"
                >
                  {modalVideo.params.action === "i2v" ? (
                    <RowBox gap={0.5}>
                      <ImageIcon sx={{ fontSize: "16px" }} />
                      <span>I2V</span>
                    </RowBox>
                  ) : (
                    <RowBox gap={0.5}>
                      <FontDownloadIcon sx={{ fontSize: "16px" }} />
                      <span>T2V</span>
                    </RowBox>
                  )}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 600 }} color="secondary">
                  {t(`juno.video.ai.${modalVideo.params.aiEngine.toLowerCase()}.title`)} -{" "}
                  {t(`juno.video.ai.${modalVideo.params.aiEngine.toLowerCase()}.model.${modalVideo.params.model}`)}
                </Typography>
              </RowBox>
            </Grid>
            <Grid item xs={12} sm={10}>
              {/* 動画再生部分 */}
              <Box
                sx={{
                  width: "100%",
                  height: "80vh",
                  backgroundColor: "#000",
                }}
              >
                <video
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", // アスペクト比を維持してBox内に収める
                  }}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                  loop
                  autoPlay
                  crossOrigin="anonymous"
                  key={modalVideo.url}
                >
                  <source src={modalVideo.url} type="video/mp4" />
                </video>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ColumnCenteredBox gap={2}>
                <TextField
                  label={t("juno.video.params.input.label")}
                  value={modalVideo.params.prompt}
                  fullWidth
                  multiline
                  rows={20}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  label={t("juno.video.params.duration")}
                  value={modalVideo.params.duration}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  label={t("juno.video.params.aspect")}
                  value={modalVideo.params.aspectRatio}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </ColumnCenteredBox>
            </Grid>

            {/* 下部ボタン類 */}
            <Grid item xs={12}>
              <RowBox gap={1} justifyContent="space-between">
                {/* 前へ */}
                <IconButton onClick={handlePrevVideo}>
                  <ArrowBackIosNewIcon />
                </IconButton>

                {/* 削除・ダウンロード・閉じる */}
                <RowBox gap={1} justifyContent="center">
                  <Button
                    variant="outlined"
                    disableElevation
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      // 親の同じダイアログを開く関数を呼び出す
                      handleOpenDeleteDialog(modalVideo.uuid);
                    }}
                  >
                    {t("common.delete")}
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    disabled={modalVideo.status !== API_STATUS.SUCCESS}
                    onClick={() => handleVideoDownload(modalVideo.uuid)}
                    disableElevation
                  >
                    {t("common.download")}
                  </Button>
                  <Button variant="contained" onClick={onClose} disableElevation>
                    {t("common.close")}
                  </Button>
                </RowBox>

                {/* 次へ */}
                <IconButton onClick={handleNextVideo}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </RowBox>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default VideoModal;
