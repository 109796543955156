// Main以下で使うこと
export const uiHeight = () => {
  // const headerElement = document.getElementById('header');
  // const footerElement = document.getElementById('footer');
  // const headerHeight = headerElement ? headerElement.offsetHeight : 0;
  // const footerHeight = footerElement ? footerElement.offsetHeight : 0;
  // const height = `calc(100vh - ${footerHeight}px - ${headerHeight}px)`;
  const height = `calc(100vh - 56px)`;
  return height;
};
