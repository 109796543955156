import axios from "axios";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../context/AlertContext";
import { useLoginStatus } from "../context/LoginStatusContext";

// カスタムフックとして定義
export const useCheckCredit = () => {
  const { isLoggedIn } = useLoginStatus();
  const [credits, setCredits] = React.useState<number>(0);
  const [dailyCredits, setDailyCredits] = React.useState<number>(0);
  const [totalCredits, setTotalCredits] = React.useState<number>(0);
  const [plan, setPlan] = React.useState<string>("");

  // 小数点第1位までの値にフォーマットするヘルパー関数
  const formatToOneDecimal = (value: number): number => {
    return parseFloat(value.toFixed(1));
  };

  // ユーザーのクレジットを取得
  const fetchUserCredits = useCallback(async () => {
    try {
      if (!isLoggedIn) return;
      const res = await axios.get("/api/v1/credit/");
      const paidCredits = res.data.credits >= 0 ? res.data.credits : 0;
      const formattedCredits = formatToOneDecimal(Number(paidCredits));
      const formattedDailyCredits = formatToOneDecimal(Number(res.data.daily_credits));
      const formattedTotalCredits = formatToOneDecimal(formattedCredits + formattedDailyCredits);

      setCredits(formattedCredits);
      setDailyCredits(formattedDailyCredits);

      setTotalCredits(formattedTotalCredits);
      setPlan(res.data.plan);

      return {
        credits: formattedCredits,
        dailyCredits: formattedDailyCredits,
        plan: res.data.plan,
      };
    } catch (error) {
      console.error("Failed to obtain credits:", error);
      return {
        credits: 0,
        dailyCredits: 0,
        plan: "",
      };
    }
  }, [isLoggedIn]);

  const { t } = useTranslation();
  const { setAlert } = useAlert();

  // クレジットチェックのロジックをカプセル化した関数
  const checkCredit = () => {
    return fetchUserCredits().then((res) => {
      if (!res) {
        throw new Error("Unexpected undefined response from fetchUserCredits");
      }
      const credit = res.credits + res.dailyCredits;
      if (!isNaN(credit) && credit <= 0) {
        setAlert("error", t("validation.noCredit"));
        return false;
      } else if (!isNaN(credit) && credit > 0) {
        return true;
      } else {
        return false;
      }
    });
  };

  // このカスタムフックを使用するコンポーネントにチェック関数を提供
  return { checkCredit, credits, dailyCredits, fetchUserCredits, totalCredits, plan };
};
