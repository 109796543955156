// UploadImageContext.tsx
import React, { createContext, useContext, useState } from "react";

type UploadImageContextType = {
  image: string | null;
  setImage: React.Dispatch<React.SetStateAction<string | null>>;
  image2: string | null;
  setImage2: React.Dispatch<React.SetStateAction<string | null>>;
  imageStrength: number;
  setImageStrength: React.Dispatch<React.SetStateAction<number>>;
  imageStrength2: number;
  setImageStrength2: React.Dispatch<React.SetStateAction<number>>;
  editorInputImage: string | null;
  setEditorInputImage: React.Dispatch<React.SetStateAction<string | null>>;
  editorInputMask: string | null;
  setEditorInputMask: React.Dispatch<React.SetStateAction<string | null>>;
  editorPreviewImage: string | null;
  setEditorPreviewImage: React.Dispatch<React.SetStateAction<string | null>>;
};

const UploadImageContext = createContext<UploadImageContextType | undefined>(undefined);

export const UploadImageProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [image, setImage] = useState<string | null>(null);
  const [image2, setImage2] = useState<string | null>(null);
  const [imageStrength, setImageStrength] = useState<number>(0.35);
  const [imageStrength2, setImageStrength2] = useState<number>(0.35);
  const [editorInputImage, setEditorInputImage] = useState<string | null>(null);
  const [editorInputMask, setEditorInputMask] = useState<string | null>(null);
  const [editorPreviewImage, setEditorPreviewImage] = useState<string | null>(null);

  return (
    <UploadImageContext.Provider
      value={{
        image,
        setImage,
        image2,
        setImage2,
        imageStrength,
        setImageStrength,
        imageStrength2,
        setImageStrength2,
        editorInputImage,
        setEditorInputImage,
        editorInputMask,
        setEditorInputMask,
        editorPreviewImage,
        setEditorPreviewImage,
      }}
    >
      {children}
    </UploadImageContext.Provider>
  );
};

export const useUploadImageContext = (): UploadImageContextType => {
  const context = useContext(UploadImageContext);
  if (!context) {
    throw new Error("useUploadImageContext must be used within an UploadImageProvider");
  }
  return context;
};
