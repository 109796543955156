import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../utils/styledBox";
import AspectRatioIcons from "./AspectRatioIcons";
import ImageUpload from "./ImageUpload";

const ParamsHaiper = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, duration, modelVersion } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [{ ratio: "AUTO", icon: HdrAutoIcon }];

  const durationMarks = [
    {
      value: 4,
      label: "4s",
    },
    {
      value: 6,
      label: "6s",
    },
  ];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <Select
          value={modelVersion.HAIPER}
          onChange={(e) => updateParams("HAIPER", "modelVersion", e.target.value)}
          variant="standard"
          fullWidth
        >
          <MenuItem value={"V2_5"}>{t("juno.video.ai.haiper.model.V2_5")}</MenuItem>
          <MenuItem value={"V2_0"}>{t("juno.video.ai.haiper.model.V2_0")}</MenuItem>
        </Select>
      </ColumnBox>

      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={4}
          max={6}
          step={4}
          value={duration.HAIPER}
          onChange={(_, value) => updateParams("HAIPER", "duration", value)}
        />
      </ColumnBox>
      <ColumnBox>
        {/* aspect */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.aspect")}
        </Typography>
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsHaiper;
