import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { RootState } from "../../../redux/store";
import { useNotice } from "../../../context/NoticeContext";
import { ColumnBox, ColumnCenteredBox } from "../../../utils/styledBox";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { GradientTypography } from "../../../utils/gradientTypography";

const Credit = () => {
  const { t } = useTranslation();
  const { credits, dailyCredits, totalCredits, fetchUserCredits, plan } = useCheckCredit();
  const { drawerCreditTrigger } = useSelector((state: RootState) => state.trigger);
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const { triggerNotice } = useNotice();
  const [loading, setLoading] = useState(true);
  const navigate = useCustomNavigate();

  const GradientBackground = styled("div")(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundImage: "linear-gradient(135deg, rgba(107,84,74,1) 0%, rgba(255,216,91,1) 50%, rgba(107,84,74,1) 100%)",
  }));

  useEffect(() => {
    setLoading(true);
    const getCredit = async () => {
      await fetchUserCredits();
    };
    getCredit().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (drawerCreditTrigger) {
      fetchUserCredits();
      dispatch(setCreditTrigger(false));
    }
  }, [drawerCreditTrigger]);

  useEffect(() => {
    // サブスクリプションの購入時に表示するメッセージ
    if (params?.get("subscription") === "success") {
      triggerNotice("success", t("message.subscription.success"));
    } else if (params?.get("subscription") === "cancel") {
      triggerNotice("info", t("message.subscription.cancel"));
    } else if (params?.get("subscription") === "error") {
      triggerNotice("error", t("message.subscription.error"));
    }
  }, [params]);

  const CreditInfoRow = ({ label, credit, plan }: { label: string; credit?: number; plan?: string }) => (
    <Stack direction={"row"} spacing={1}>
      <Box sx={{ width: 240, backgroundColor: (theme) => theme.palette.background.default, borderRadius: 1, p: 0.5 }}>
        <Typography variant="body2" fontWeight={600} gutterBottom sx={{ textAlign: "center" }}>
          {label}
        </Typography>
      </Box>
      <ColumnCenteredBox sx={{}}>
        {credit && (
          <Typography variant="body1" fontWeight={600} gutterBottom>
            {credit}
          </Typography>
        )}
        {plan && (
          <GradientTypography variant="body1" fontWeight={600} gutterBottom>
            {plan.toUpperCase()}
          </GradientTypography>
        )}
      </ColumnCenteredBox>
    </Stack>
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={2}
      width={"100%"}
    >
      {/*<Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }} spacing={2}>*/}
      {/*  <GradientBackground>*/}
      {/*    <FaCoins color={"white"} style={{ fontSize: "18px" }} />*/}
      {/*  </GradientBackground>*/}
      {/*  <Typography variant="body1">CREDITS</Typography>*/}
      {/*</Stack>*/}
      {loading ? (
        <ColumnCenteredBox sx={{ gap: 1, width: "100%" }}>
          <Skeleton variant="text" width={240} height={34} />
          <Skeleton variant="text" width={240} height={34} />
          <Skeleton variant="text" width={240} height={34} />
          <Skeleton variant="text" width={240} height={34} />
        </ColumnCenteredBox>
      ) : (
        <ColumnBox sx={{ gap: 1, width: "100%" }}>
          <span onClick={() => navigate("/preferences/plan-and-billings")} style={{ cursor: "pointer" }}>
            <CreditInfoRow label="Your Plan" plan={plan} />
          </span>
          <Tooltip title={"Paid Credits + Bonus Credits"} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Total Credits" credit={totalCredits} />
            </span>
          </Tooltip>
          <Tooltip title={"Credits purchased through subscription"} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Paid Credits" credit={credits} />
            </span>
          </Tooltip>
          <Tooltip title={"You can get 50 bonus credits every day!"} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Bonus Credits" credit={dailyCredits} />
            </span>
          </Tooltip>
        </ColumnBox>
      )}

      {/*<GradationButton*/}
      {/*  startIcon={<CreditCardIcon />}*/}
      {/*  sx={{ borderRadius: 1, px: 2.4, py: 0.8, width: "100%" }}*/}
      {/*  onClick={() => navigate("/preferences/plan-and-billings")}*/}
      {/*>*/}
      {/*  {t("drawer.plan")}*/}
      {/*</GradationButton>*/}
    </Box>
  );
};
export default Credit;
