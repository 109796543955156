import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshChatHistories, setProcessing, triggerChatFetch } from "../redux/slices/browsingSlice";
import { setCreditTrigger } from "../redux/slices/triggerSlice";
import { RootState } from "../redux/store";
import { ChatMode, HistoryItem, SidebarData } from "../types/chatTypes";
import { scrollToBottom } from "../utils/utils";
import { useCheckCredit } from "./useCreditCheck";

export const useChat = (chatUuid: string) => {
  const { checkCredit } = useCheckCredit();
  const dispatch = useDispatch();
  const refreshHistoryTrigger = useSelector((state: RootState) => state.browsing.refreshChatHistories);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const [mode, setMode] = useState<ChatMode>("llm");
  const [historyData, setHistoryData] = useState<HistoryItem[]>([]);
  const [sidebarData, setSidebarData] = useState<SidebarData | null>(null);
  const [processingUuid, setProcessingUuid] = useState<string | null>(null);
  const [aiStatus, setAiStatus] = useState<string | null>(null);
  const handleModeChange = (newMode: ChatMode) => {
    setMode(newMode);
  };

  const makeHistory = async (preserveSidebar = false) => {
    try {
      const res = await axios.get(`/api/v1/chat/${chatUuid}/history`);
      if (res.data) {
        setHistoryData(res.data.history);

        // チャット切り替え時（preserveSidebar = false）またはサイドバーデータが存在する場合は更新
        if (!preserveSidebar || res.data.sidebar) {
          setSidebarData(res.data.sidebar);
        }

        // 最新のアシスタントメッセージのソースを表示
        const latestAssistantMessage = res.data.history.filter((item: HistoryItem) => item.role === "assistant").pop();
        if (latestAssistantMessage?.search_results) {
          setSidebarData({ content: JSON.stringify(latestAssistantMessage.search_results) });
        }
      }
    } catch (error) {
      console.error("履歴の取得に失敗しました:", error);
    }
  };

  useEffect(() => {
    makeHistory();
  }, [chatUuid]);

  useEffect(() => {
    if (refreshHistoryTrigger) {
      makeHistory(true);
    }
  }, [refreshHistoryTrigger]);

  const stopStream = () => {
    try {
      if (abortController) {
        abortController.abort();
        setAbortController(null);
      }
      dispatch(setProcessing({ chatUuid, processing: false }));
      setProcessingUuid(null);
      setAiStatus(null);
      dispatch(setCreditTrigger(true));
    } catch (error) {
      console.error("ストリームの停止に失敗しました:", error);
    }
  };

  const setSelectSourceId = (id: string) => {
    const search_results = historyData.find((item) => item.message_uuid === id)?.search_results;
    if (search_results) {
      setSidebarData({ content: JSON.stringify(search_results) });
    }
  };

  const sendMessage = async (
    message: string,
    images?: File[],
    files?: File[],
    editMessageUuid: string | null = null
  ) => {
    try {
      if (!(await checkCredit())) return;

      // 既存のストリームがある場合は停止
      if (abortController) {
        abortController.abort();
        setAbortController(null);
      }

      if (editMessageUuid) {
        setHistoryData((prevData) => {
          const index = prevData.findIndex((item) => item.message_uuid === editMessageUuid);
          const newHistoryData = index === -1 ? prevData : prevData.slice(0, index + 1);
          newHistoryData[newHistoryData.length - 1] = {
            message_uuid: "userMessage",
            role: "user",
            content: message,
            search_results: null,
          };
          return newHistoryData;
        });
      } else {
        setHistoryData((prevData) => [
          ...prevData,
          {
            message_uuid: "userMessage",
            role: "user",
            content: message,
            search_results: null,
          },
        ]);
      }

      dispatch(setProcessing({ chatUuid, processing: true }));
      scrollToBottom(200);
      setAiStatus(null);
      setSidebarData({ content: "" });

      const formData = new FormData();
      formData.append("query", message);
      if (editMessageUuid) {
        formData.append("edit_message_uuid", editMessageUuid);
      }
      if (images?.length) {
        images.forEach((image) => {
          formData.append("images[]", image);
        });
      }
      if (files?.length) {
        files.forEach((file) => {
          formData.append("files[]", file);
        });
      }
      formData.append("mode", mode);

      const controller = new AbortController();
      setAbortController(controller);

      const response = await fetch(`/api/v1/chat/${chatUuid}/create`, {
        signal: controller.signal,
        method: "POST",
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("ストリーミングがサポートされていません");
      }

      if (response.body) {
        const reader = response.body.getReader();
        let newAIMessage = true;
        let res = "";

        try {
          let streamDone = false;
          while (!streamDone) {
            const { done, value } = await reader.read();
            if (done) {
              streamDone = true;
              break;
            }

            const text = new TextDecoder().decode(value);
            const jsonParts = text.split("\n\n");

            for (const part of jsonParts) {
              if (part.trim() === "") continue;
              const parsedData = JSON.parse(part);

              console.log("parsedData", parsedData.data);

              if (parsedData.data?.role === "user") {
                setHistoryData((prevData) =>
                  prevData.map((item) =>
                    item.message_uuid === "userMessage" ? { ...item, message_uuid: parsedData.data.uuid } : item
                  )
                );
              }

              if (parsedData.data?.role === "function") {
                if (parsedData.data.search_results) {
                  setSidebarData({
                    content: JSON.stringify(parsedData.data.search_results),
                  });
                }
                if (parsedData.data.status) {
                  setAiStatus(parsedData.data.status);
                }
              }

              if (parsedData.data?.role === "assistant") {
                setAiStatus(null);
                res += parsedData.data.content;
                const uuid = parsedData.data.uuid;
                setProcessingUuid(uuid);

                if (newAIMessage) {
                  setHistoryData((prev) => [
                    ...prev,
                    {
                      message_uuid: uuid,
                      role: "assistant",
                      content: res,
                      search_results: null,
                    },
                  ]);
                  newAIMessage = false;
                } else {
                  setHistoryData((prevData) =>
                    prevData.map((item) => (item.message_uuid === uuid ? { ...item, content: res } : item))
                  );
                }
              }

              if (parsedData.data?.end) {
                setProcessingUuid(null);
                setAiStatus(null);
                dispatch(setProcessing({ chatUuid, processing: false }));
                dispatch(triggerChatFetch());
                dispatch(refreshChatHistories());
                setTimeout(() => {
                  dispatch(setCreditTrigger(true));
                  dispatch(refreshChatHistories());
                }, 1000);
                return;
              }
            }
          }
        } catch (error) {
          if (error instanceof DOMException && error.name === "AbortError") {
            console.log("ストリーミングが中断されました");
            return;
          }
          console.error("ストリーミング処理中にエラーが発生しました:", error);
          throw error;
        }
      }
    } catch (error) {
      dispatch(setProcessing({ chatUuid, processing: false }));
      setAiStatus(null);
      makeHistory();
      console.error("メッセージの送信に失敗しました:", error);
    }
  };

  return {
    mode,
    handleModeChange,
    historyData,
    sidebarData,
    processingUuid,
    aiStatus,
    sendMessage,
    stopStream,
    setSelectSourceId,
  };
};
