const message = {
  subscription: {
    success: "ご購入ありがとうございます！Tenak AIをお楽しみいただけることを願っています。",
    cancel:
      "サブスクリプションがキャンセルされました。現在の課金期間の終了まで、Tenak AIを引き続きご利用いただけます。",
    error:
      "サブスクリプション中にエラーが発生しました。後ほど、もう一度実行してください。または、サポートにお問い合わせください。",
  },
};
export default message;
