import React, { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { JunoImageProvider } from "../context/juno/JunoImageProvider";
import { useLoginStatus } from "../context/LoginStatusContext";
import { loadUserLanguageFromCookie } from "../i18n/i18n";
import Browsing from "../pages/Browsing/Browsing";
import ColorGenerator from "../pages/ColorGenerator/ColorGenerator";
import Dashboard from "../pages/Dashboard/Dashboard";
import Error404 from "../pages/Error404";
import Juno from "../pages/Juno/components/Layout";
import MindMap from "../pages/Library/MindMap";
import Voice from "../pages/Library/Voice";
import Text from "../pages/Library/Text";
import MindMapEditor from "../pages/MindMapGenerator/MindMapEditor";
import MindMapGenerator from "../pages/MindMapGenerator/MindMapGenerator";
import MindMapPublic from "../pages/MindMapGenerator/MindMapPublic";
import Article from "../pages/NotesNebula/Article";
import Nebula from "../pages/NotesNebula/NotesNebula";
import Preferences from "../pages/Preferences/components/Layout";
import Templates from "../pages/Template/Templates";
import TextFile from "../pages/TextFile/TextFile";
import AboutJuno from "../pages/Unauthenticated/Juno/AboutJuno";
import ActivateUser from "../pages/Unauthenticated/ActivateUser";
import Document from "../pages/Unauthenticated/Document";
import PasswordReset from "../pages/Unauthenticated/PasswordReset";
import Pricing from "../pages/Unauthenticated/Pricing/Pricing";
import Top from "../pages/Unauthenticated/Top/Top";
import VoiceGenerator from "../pages/VoiceGenerator/VoiceGenerator";
import WritingAI from "../pages/WritingAI/components/Layout";
import usePageTracking from "../hooks/usePageTracking";
import { MindMapProvider } from "../context/MindmapContext";
import Announcement from "../pages/Announcement/Announcement";

const MainRouter = () => {
  const { isLoggedIn } = useLoginStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const language = useMemo(() => loadUserLanguageFromCookie(), []);
  usePageTracking();

  useEffect(() => {
    const currentLanguage = location.pathname.split("/")[1];
    if (currentLanguage === "") {
      return;
    }
    if (["en", "ja"].includes(currentLanguage)) {
      if (currentLanguage !== language) {
        const newPath = `/${language}${location.pathname.slice(3)}`;
        navigate(newPath, { replace: true });
      }
    } else {
      navigate(`/${language}/404`, { replace: true });
    }
  }, [language]);

  const LoginContent = (components: React.ReactNode) => {
    if (isLoggedIn === null) return <div />;
    return isLoggedIn ? components : <Navigate to={`/${language}`} />;
  };

  return (
    <Routes>
      <Route path="/:lang">
        {/* ログイン後 Routes */}
        <Route path="" element={isLoggedIn ? <Dashboard /> : <Top />} />
        <Route path="workspace/:workspaceUuid" element={LoginContent(<Browsing />)} />
        <Route path="announcements" element={<Announcement />} />
        <Route path="preferences/*" element={LoginContent(<Preferences />)} />
        <Route path="file/:fileUuid" element={LoginContent(<TextFile />)} />
        <Route path="voice-generator" element={LoginContent(<VoiceGenerator />)} />
        <Route path="mind-map-generator" element={LoginContent(<MindMapGenerator />)} />
        <Route
          path="mind-map/:mindMapUuid"
          element={LoginContent(
            <MindMapProvider>
              <MindMapEditor />
            </MindMapProvider>
          )}
        />
        <Route
          path="mind-map/:mindMapUuid/:publicUuid"
          element={
            <MindMapProvider>
              <MindMapPublic />
            </MindMapProvider>
          }
        />
        <Route path="color-generator" element={LoginContent(<ColorGenerator />)} />
        <Route path="library/voices" element={LoginContent(<Voice />)} />
        <Route path="library/text" element={LoginContent(<Text />)} />
        <Route path="library/mind-maps" element={LoginContent(<MindMap />)} />
        <Route path="template" element={LoginContent(<Templates />)} />
        <Route
          path="juno/*"
          element={
            isLoggedIn ? (
              <JunoImageProvider>
                <Juno />
              </JunoImageProvider>
            ) : (
              <AboutJuno />
            )
          }
        />
        <Route path="writing-ai/*" element={LoginContent(<WritingAI />)} />

        {/* 共通 Routes */}
        <Route path="pricing" element={<Pricing />} />
        <Route path="privacy-policy" element={<Document />} />
        <Route path="terms-of-service" element={<Document />} />
        <Route path="legal-notice" element={<Document />} />
        <Route path="notes-nebula/">
          <Route path="" element={<Nebula />} />
          <Route path="articles/:fileUuid" element={<Article />} />
          <Route path="articles/:fileUuid/:publicUuid" element={<Article />} />
        </Route>
        <Route path="reset-password/:uid/:token" element={<PasswordReset />} />
        <Route path="activate-user/:uidb64/:token" element={<ActivateUser />} />

        {/* 404 */}
        <Route path="404" element={<Error404 />} />
      </Route>

      {/* その他 Routes */}
      <Route path="" element={<Navigate to={`/${language}`} />} />

      {/* Redirect to 404 for undefined paths */}
      <Route path="*" element={<Navigate to={`/${language}/404`} />} />
    </Routes>
  );
};
export default MainRouter;
