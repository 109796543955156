import ChatIcon from "@mui/icons-material/Chat";
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

interface ChatRoom {
  id: string;
  title: string;
  lastMessage: string;
  timestamp: Date;
  isActive?: boolean;
}

const mockChatRooms: ChatRoom[] = [
  {
    id: "1",
    title: "一般的な質問",
    lastMessage: "AIについて教えてください",
    timestamp: new Date(),
    isActive: true,
  },
  {
    id: "2",
    title: "技術的な相談",
    lastMessage: "Reactの最適化について",
    timestamp: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1日前
  },
  {
    id: "3",
    title: "プロジェクトの計画",
    lastMessage: "新機能の実装について",
    timestamp: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), // 2日前
  },
];

const formatDate = (date: Date): string => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));

  if (minutes < 1) {
    return "たった今";
  } else if (hours < 1) {
    return `${minutes}分前`;
  } else if (hours < 24) {
    return `${hours}時間前`;
  } else {
    return date.toLocaleDateString("ja-JP", {
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
};

interface ConversationHistoryProps {
  drawerWidth: number;
}

const ConversationHistory = ({ drawerWidth }: ConversationHistoryProps) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          top: 64,
          height: "calc(100vh - 64px)",
        },
      }}
    >
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
          <Typography variant="h6">チャットルーム</Typography>
        </Box>
        <List>
          {mockChatRooms.map((room) => (
            <ListItem
              key={room.id}
              disablePadding
              sx={{
                backgroundColor: room.isActive ? "action.selected" : "inherit",
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ChatIcon color={room.isActive ? "primary" : "inherit"} />
                </ListItemIcon>
                <ListItemText
                  primary={room.title}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {room.lastMessage}
                      </Typography>
                      <Typography component="span" variant="caption" color="text.secondary" sx={{ display: "block" }}>
                        {formatDate(room.timestamp)}
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default ConversationHistory;
