import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import ChatArea from "./components/ChatArea";
import { ChatMode } from "./components/ChatInput";
import ConversationHistory from "./components/ConversationHistory";
import RagSources from "./components/RagSources";

interface RagSource {
  title: string;
  relevance: number;
  snippet: string;
}

interface MessageResponse {
  type: "message";
  content: string;
}

interface SourcesResponse {
  type: "sources";
  content: RagSource[];
}

type StreamResponse = MessageResponse | SourcesResponse;

const DRAWER_WIDTH = 300;

const Argent = () => {
  const [messages, setMessages] = useState<{ user: string; text: string }[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [mode, setMode] = useState<ChatMode>("llm");
  const [ragSources, setRagSources] = useState<RagSource[]>([
    {
      title: "サンプルドキュメント",
      relevance: 95,
      snippet: "これはRAGのサンプル情報ソースです。実際のAPIレスポンスに基づいて更新されます。",
    },
  ]);

  const streamControllerRef = useRef<ReadableStreamDefaultController | null>(null);
  const isStreamClosedRef = useRef<boolean>(false);

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
    return undefined;
  };

  const handleSubmit = async (images?: File[], files?: File[]) => {
    if (streamControllerRef.current && !isStreamClosedRef.current) {
      isStreamClosedRef.current = true;
      streamControllerRef.current.close();
      console.log("Stream closed");
    }
    if (!inputValue.trim() && !images?.length && !files?.length) {
      return;
    }

    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage && lastMessage.user === "You") {
        return [...prevMessages, { user: "Bot", text: "" }];
      }
      return [...prevMessages, { user: "You", text: inputValue }];
    });

    try {
      const formData = new FormData();
      formData.append("message", inputValue);

      formData.append("mode", mode);
      // 画像の追加
      if (images?.length) {
        images.forEach((image) => {
          formData.append("images[]", image);
        });
      }

      // ファイルの追加
      if (files?.length) {
        files.forEach((file) => {
          formData.append("files[]", file);
        });
      }

      const csrfToken = getCookie("csrftoken");
      const headers: HeadersInit = {};
      if (csrfToken) {
        headers["X-CSRFToken"] = csrfToken;
      }

      const response = await fetch("/api/v1/argent/chat/", {
        method: "POST",
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        console.error("HTTP error!", response);
        return;
      }

      if (response.body !== null) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        new ReadableStream({
          start(controller) {
            streamControllerRef.current = controller;
            isStreamClosedRef.current = false;
            console.log("Stream start");

            function push() {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  isStreamClosedRef.current = true;
                  return;
                }
                const decodedValue = decoder.decode(value);
                const lines = decodedValue.split("\n");
                for (const line of lines) {
                  if (line.startsWith("data: ")) {
                    const data = line.slice(6);
                    try {
                      const parsedData = JSON.parse(data) as StreamResponse;
                      if (parsedData.type === "message" && parsedData.content) {
                        setMessages((prevMessages) => {
                          const updatedMessages = [...prevMessages];
                          const lastMessage = updatedMessages[updatedMessages.length - 1];
                          if (lastMessage && lastMessage.user === "Bot") {
                            lastMessage.text += parsedData.content;
                          } else {
                            updatedMessages.push({
                              user: "Bot",
                              text: parsedData.content,
                            });
                          }
                          return updatedMessages;
                        });
                      } else if (parsedData.type === "sources" && Array.isArray(parsedData.content)) {
                        setRagSources(
                          parsedData.content.map((source: RagSource) => ({
                            title: source.title || "無題のドキュメント",
                            relevance: Math.round(source.relevance * 100) || 0,
                            snippet: source.snippet || "スニペットなし",
                          }))
                        );
                      }
                    } catch (error) {
                      if (data.trim().length === 0) {
                        continue;
                      }
                      console.warn("Received non-JSON data, processing as text:", data, error);
                      setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];
                        const lastMessage = updatedMessages[updatedMessages.length - 1];
                        if (lastMessage && lastMessage.user === "Bot") {
                          lastMessage.text += data;
                        } else {
                          updatedMessages.push({ user: "Bot", text: data });
                        }
                        return updatedMessages;
                      });
                    }
                  } else {
                    if (line.trim().length === 0) {
                      continue;
                    }
                    console.log("Received non-data line:", line);
                    setMessages((prevMessages) => {
                      const updatedMessages = [...prevMessages];
                      const lastMessage = updatedMessages[updatedMessages.length - 1];
                      if (lastMessage && lastMessage.user === "Bot") {
                        lastMessage.text += line;
                      } else {
                        updatedMessages.push({ user: "Bot", text: line });
                      }
                      return updatedMessages;
                    });
                  }
                }
                controller.enqueue(value);
                push();
              });
            }

            push();
          },
        });
      }
    } catch (error) {
      console.error("Could not send message:", error);
    }

    setInputValue("");
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <DrawerHeader />
      <ConversationHistory drawerWidth={DRAWER_WIDTH} />
      <ChatArea
        messages={messages}
        inputValue={inputValue}
        onInputChange={(value) => setInputValue(value)}
        onSubmit={handleSubmit}
        drawerWidth={DRAWER_WIDTH}
        onModeChange={(newMode) => setMode(newMode)}
      />
      <RagSources sources={ragSources} drawerWidth={DRAWER_WIDTH} />
    </Box>
  );
};

export default Argent;
