import React, { useState, useRef } from "react";
import { Popover, Tooltip, IconButton, Tabs, Tab, Box, Chip, Grid, Stack } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LightModeIcon from "@mui/icons-material/LightMode";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import StyleIcon from "@mui/icons-material/Style";
import Typography from "@mui/material/Typography";
import ScrollableBox from "../../../../components/common/ScrollableBox";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import { useTranslation } from "react-i18next";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

interface Props {
  prompt: string;
  setPrompt: (prompt: string) => void;
}

const InputPreset = ({ prompt, setPrompt }: Props) => {
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChipClick = (label: string) => {
    let sample = `${prompt}, ${label}`.trim();
    if (sample.startsWith(", ")) {
      sample = sample.slice(1);
    }

    setPrompt(sample.trim());
  };

  const illuminations = [
    "sunlight",
    "naturalLighting",
    "directSunlight",
    "candlelight",
    "moonlight",
    "backlight",
    "accentLighting",
    "spotlight",
    "fluorescent",
    "neonLamp",
    "blacklight",
    "ultraviolet",
    "strobe",
    "dusk",
    "glowing",
    "blindingLight",
    "glowstick",
    "edisonBulb",
    "fire",
    "crepuscularRays",
    "concertLighting",
    "nightclubLighting",
    "electricArc",
    "lavaGlow",
    "glowingRadioactively",
    "nuclearWasteGlow",
    "quantumDotDisplay",
    "studioLight",
    "softBoxLighting",
    "threePointLighting",
    "highKeyLighting",
    "lowKeyLighting",
    "edgeLighting",
    "bounceLighting",
    "practicalLighting",
    "motivatedLighting",
    "cinematicOverhead",
    "strongSunsetLight",
    "goldenHourRadiance",
    "softMorningLight",
    "candleLitAmbience",
    "glowingCampfireLight",
    "forestSunbeams",
    "mistyMorningGlow",
    "underwaterRadiance",
    "twilightShimmer",
    "fierySunset",
    "rainReflectionGlow",
    "shimmeringWaterReflection",
    "glowingStreetLamps",
    "industrialSpotlight",
    "dramaticStageLighting",
    "auroraBorealisLight",
    "starlitSky",
    "warmAutumnAfternoon",
    "cascadingSunlightThroughLeaves",
    "mysteriousCavernGlow",
    "glowingCityScapeAtNight",
    "vividRainforestLight",
    "sunsetOverCityscape",
    "etherealCathedralLight",
    "radiantSpringMeadow",
    "festiveHolidayLights",
    "forestFloorDappledLight",
    "bustlingMarketLight",
    "glowingVolcanicCrater",
    "desertMirageLight",
    "eveningFirefliesGlow",
    "lighthouseBeacon",
    "chiaroscuroEffect",
    "harshSpotlight",
    "softAmbientGlow",
    "diffuseDaylight",
    "directionalBeam",
    "goldenBacklight",
    "softDiffusedWindowLight",
    "hazyLavenderFieldGlow",
    "dramaticRimLighting",
    "singleSourceAthleteSpotlight",
    "coldMoonlightGraveyard",
    "warmCandlelightRomance",
    "sereneYogaStudioLight",
    "nostalgicChildhoodSunlight",
    "goldenDinnerCandlelight",
    "UVBlacklightParty",
  ];

  const backgrounds = [
    "officeWorkspace",
    "bustlingNewYorkStreet",
    "traditionalJapaneseGarden",
    "modernTokyoIntersection",
    "suburbanNeighborhood",
    "quietLibrary",
    "historicalLandmark",
    "vibrantCafe",
    "trainStationPlatform",
    "urbanPark",
    "minimalistWhiteStudio",
    "mountainPeaks",
    "oceanHorizon",
    "citySkyline",
    "desertDunes",
    "snowyLandscape",
    "tropicalBeach",
    "medievalCastle",
    "futuristicCity",
    "underwaterScene",
    "bustlingMarket",
    "gothicCathedral",
    "tranquilLake",
    "goldenWheatField",
    "cobblestoneStreet",
    "carnivalGrounds",
    "bambooForest",
    "sunflowerField",
    "volcanicLandscape",
    "sereneVillage",
    "cherryBlossomPath",
    "bustlingUrbanAlley",
    "ancientLibrary",
    "bustlingHarbor",
    "sereneZenGarden",
    "vibrantCityPlaza",
    "sunlitCountryside",
    "grandOperaHall",
    "twilightMountainVillage",
    "cozyCottageInterior",
    "majesticWaterfall",
    "hiddenJungleTemple",
    "neonCyberpunkCity",
    "snowyMountainCabin",
    "underwaterCoralReef",
    "ancientDesertRuins",
    "livelyFarmyard",
    "vastGalaxyView",
    "enchantedForest",
    "luxuriousBallroom",
    "spaceshipInterior",
    "sereneBeachSunset",
    "industrialFactoryFloor",
    "medievalVillageMarket",
    "mysticalFloatingIslands",
    "urbanGraffitiAlley",
    "rusticFarmhouse",
    "crowdedCityFestival",
    "peacefulMonastery",
    "futuristicScienceLab",
    "vibrantCarnivalParade",
    "tranquilHotSpring",
    "abandonedHauntedMansion",
  ];

  const styles = [
    "realisticPhoto",
    "Rendering3D",
    "watercolorPainting",
    "oilPainting",
    "pencilSketch",
    "abstractArt",
    "popArt",
    "pixelArt",
    "cartoonStyle",
    "animeStyle",
    "disneyStyle",
    "impressionistPainting",
    "modernMinimalist",
    "surrealismArt",
    "steampunkArt",
    "cyberpunkArt",
    "fantasyArt",
    "gothicArt",
    "baroqueArt",
    "retroFuturism",
    "photorealism",
    "cubism",
    "fauvism",
    "ukiyoe",
    "renaissanceArt",
    "newMakotoShinkaiStyle",
    "vincentVanGoghStyle",
    "picassoStyle",
    "comicBookStyle",
    "graffitiArt",
    "photorealCGI",
    "monochromeFilm",
    "digitalPainting",
    "lowPoly",
    "stopMotion",
    "paperCraft",
    "botanicalArt",
    "silhouetteArt",
    "retroArcade",
    "photoCollage",
    "splatterArt",
    "pastelColor",
    "highFantasy",
    "darkFantasy",
    "flatDesign",
    "midCenturyModern",
    "visualNovel",
    "conceptualArt",
    "dadaism",
    "victorianArt",
    "artDeco",
    "artNouveau",
    "cartoonyRealism",
    "gameAsset",
    "militaryFiction",
    "cuteChibi",
    "medievalFantasy",
    "neoNoir",
    "technoArt",
    "proceduralArt",
  ];

  const tabs = [
    {
      label: "Illumination",
      icon: <LightModeIcon fontSize={"small"} />,
    },
    {
      label: "Background",
      icon: <WallpaperIcon fontSize={"small"} />,
    },
    {
      label: "Style",
      icon: <StyleIcon fontSize={"small"} />,
    },
  ];

  return (
    <>
      <Tooltip title={t("juno.inputPreset.icon")}>
        <IconButton onClick={handleOpen} ref={anchorRef}>
          <TipsAndUpdatesIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: 700 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{ height: 50 }}
                label={
                  <Stack direction="row" spacing={1}>
                    {tab.icon}
                    <Typography variant="body2" color="textSecondary">
                      {tab.label}
                    </Typography>
                  </Stack>
                }
              />
            ))}
          </Tabs>
          <ScrollableBox sx={{ height: 350 }}>
            <Box p={1}>
              {tabIndex === 0 && (
                <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
                  {illuminations.map((item, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={t(`juno.inputPreset.illumination.${item}`)}
                        onClick={() => handleChipClick(t(`juno.inputPreset.illumination.${item}`))}
                        size="small"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {tabIndex === 1 && (
                <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
                  {backgrounds.map((item, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={t(`juno.inputPreset.background.${item}`)}
                        onClick={() => handleChipClick(t(`juno.inputPreset.background.${item}`))}
                        size="small"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {tabIndex === 2 && (
                <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
                  {styles.map((item, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={t(`juno.inputPreset.style.${item}`)}
                        onClick={() => handleChipClick(t(`juno.inputPreset.style.${item}`))}
                        size="small"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </ScrollableBox>
          <ColumnCenteredBox>
            <Typography variant={"caption"} sx={{ py: 0.5 }}>
              {t("juno.inputPreset.description")}
            </Typography>
          </ColumnCenteredBox>
        </Box>
      </Popover>
    </>
  );
};

export default InputPreset;
