import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import MoneyIcon from "@mui/icons-material/Money";
import SubjectIcon from "@mui/icons-material/Subject";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ColumnCenteredBox, RowBox } from "../../../utils/styledBox";
import AIEditor from "./AIEditor";
// import MovieIcon from "@mui/icons-material/Movie";
// import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import axios from "axios";

const AIList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const [chats, setChats] = useState([]);

  const get_chats = async () => {
    try {
      const res = await axios.get("/api/v1/chat/");
      setChats(res.data.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    get_chats();
  }, []);

  const ais = [
    {
      key: "browsing",
      path: chats.length > 0 ? chats[0]["path"] : "",
      icon: <ChatBubbleIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.browsing",
    },
    // {
    //   key: "images",
    //   path: "/juno/image-generator",
    //   icon: <ImageOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
    //   label: "drawer.imageGenerator",
    // },
    // {
    //   key: "videos",
    //   path: "/juno/video-generator",
    //   icon: <MovieIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
    //   label: "drawer.videoGenerator",
    // },
    {
      key: "text",
      path: "/writing-ai/article",
      icon: <SubjectIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.writingAI",
    },
    {
      key: "voices",
      path: "/voice-generator",
      icon: <GraphicEqIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.voiceGenerator",
    },
    {
      key: "mind-maps",
      path: "/mind-map-generator",
      icon: <AccountTreeOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.mindmapGenerator",
    },
    {
      key: "color-generator",
      path: "/color-generator",
      icon: <ColorLensIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.colorGenerator",
    },
    {
      key: "templates",
      path: "/template",
      icon: <MoneyIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.templates",
    },
  ];

  const isPathMatch = (itemPath) => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const targetPath = itemPath.replace(/\/$/, "");

    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, "");

    if (targetPath === "/juno") {
      return pathWithoutLang === targetPath || pathWithoutLang === "/juno/";
    }

    return pathWithoutLang === targetPath;
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          pt: 1,
          pb: 1,
          backgroundColor: "transparent",
        }}
        elevation={0}
      >
        <RowBox sx={{ ml: 2, mb: 0.5, width: "inherit" }}>
          <AutoAwesomeIcon fontSize={"small"} color={"secondary"} sx={{ mr: 1 }} />
          <Typography variant={"caption"} component={"span"}>
            {t("drawer.AIs")}
          </Typography>
        </RowBox>
        <Grid container spacing={0.5} sx={{ px: 1, py: 0.5 }}>
          {ais.map((item) => (
            <Grid item xs={6} key={item.key}>
              <ButtonBase
                onClick={() => navigate(item.path)}
                sx={{
                  borderRadius: 1,
                  width: "100%",
                  height: "100%",
                  alignItems: "flex-start",
                  backgroundColor: (theme) =>
                    isPathMatch(item.path)
                      ? alpha(theme.palette.primary.main, 0.2)
                      : alpha(theme.palette.action.selected, 0.05),
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                  },
                }}
              >
                <ColumnCenteredBox sx={{ m: 1, whiteSpace: "break-spaces" }}>
                  {item.icon}
                  <Typography
                    variant={"caption"}
                    component={"span"}
                    whiteSpace={"nowrap"}
                    sx={{ fontWeight: isPathMatch(item.path) ? "bold" : "normal" }}
                  >
                    {t(item.label)}
                  </Typography>
                </ColumnCenteredBox>
              </ButtonBase>
            </Grid>
          ))}
          <Grid item xs={12}>
            <AIEditor />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AIList;
