import junoPreset from "./junoPreset";
import junoVideo from "./junoVideo";

const juno = {
  title: "Studio Juno",
  description: "Image Generation AI",
  explore: "Explore",
  goToGenerator: "Generator",
  goToVideoGenerator: "Video Generator",
  models: "Models",
  myGallery: "My Images",
  videos: "My Videos",
  likes: "My Likes",
  searchResult: "Search Results for",
  noResult: "No search results were found. (Please ensure you are entering correct English.)",
  menu: {
    title: "MENU",
    txt2img: "Text to Image",
    img2img: "Image to Image",
    upscale: "Upscale",
    editor: "Image Editor",
  },
  input: {
    engine: {
      title: "AI ENGINE",
      description: "Select the AI engine you want to use.",
      DallE3: "DALL·E 3",
      DallE3Description:
        "Flexible prompts allow for easy generation of desired images, and short English word inputs are also possible. It is beginner-friendly but tends to consume the most credits.",
      Flux11ProUltra: "FLUX1.1 Pro Ultra",
      Flux11ProUltraDescription:
        "Capable of generating high-resolution 4MP images. Also, RAW mode can be selected to generate natural images.",
      Flux1Pro: "FLUX.1 Pro",
      Flux11Pro: "FLUX1.1 Pro",
      Flux1ProDescription:
        "Capable of generating high-resolution images within the FLUX.1 series, with high prompt flexibility. It is also capable of generating higher resolution images than MidjourneyV6 and SD Ultra.",
      Flux1Dev: "FLUX.1 Dev",
      Flux1DevDescription: "Lower image quality than FLUX.1 Pro, but faster image generation.",
      Flux1Schnell: "FLUX.1 Schnell",
      Flux1SchnellDescription: "Lower image quality than FLUX.1 Dev, but faster image generation.",
      Flux1RealismLoRA: "FLUX.1 with Realism LoRA",
      Flux1RealismLoRADescription:
        "Realism model of the FLUX.1 series. Capable of generating images that emphasize realism.",
      FluxJuno: "FLUX with STUDIO JUNO",
      FluxJunoDescription:
        "Collaborative model of FLUX and STUDIO JUNO. Allows you to select styles that utilize STUDIO JUNO technology.",
      FluxPuLID: "FLUX PuLID",
      FluxPuLIDDescription:
        "Based on FLUX.1 Dev, it is capable of generating images that retain the facial features of the original image. It can be generated from a single portrait image.",
      Flux11ProRedux: "FLUX1.1 Pro Redux",
      Flux11ProReduxDescription:
        "The Redux model of FLUX1.1 Pro. Generates slightly altered variation images based on the input image. When combined with FLUX1.1 Pro Ultra, which features high-resolution output and flexible aspect ratios, it enables higher-quality image generation.",
      Flux1DevRedux: "FLUX.1 Dev Redux",
      Flux1DevReduxDescription:
        "The Redux model of FLUX.1 Dev. Generates slightly altered variation images based on the input image. Offered under the Flux Dev License for developers.",
      Flux1SchnellRedux: "FLUX.1 Schnell Redux",
      Flux1SchnellReduxDescription:
        "The Redux model of FLUX.1 Schnell. Generates slightly altered variation images based on the input image, enabling higher-resolution image generation.",
      Flux11ProUltraRedux: "FLUX1.1 Pro Ultra Redux",
      Flux11ProUltraReduxDescription:
        "The Redux model of FLUX1.1 Pro Ultra. Generates slightly altered variation images based on the input image. With 4-megapixel high-resolution output and flexible aspect ratios, it enables high-quality image generation.",
      Flux1ProCanny: "FLUX.1 Pro Canny",
      Flux1ProCannyDescription:
        "The Canny model of FLUX.1 Pro. Uses Canny edges extracted from the input image to allow text-guided editing while preserving the image structure. Particularly effective for texture modifications.",
      Flux1ProDepth: "FLUX.1 Pro Depth",
      Flux1ProDepthDescription:
        "The Depth model of FLUX.1 Pro. Uses depth maps extracted from the input image to allow text-guided editing while preserving the image structure. Enables higher-resolution image generation and delivers higher-quality results compared to traditional models like Midjourney ReTexture.",
      Flux1DevCannyLoRA: "FLUX JUNO Canny",
      Flux1DevCannyLoRADescription:
        "The Canny model of FLUX.1 Dev with STUDIO JUNO. Uses Canny edges extracted from the input image to enable realism-focused image generation. Based on FLUX.1 Dev to simplify development.",
      Flux1DevDepthLoRA: "FLUX JUNO Depth",
      Flux1DevDepthLoRADescription:
        "The Depth model of FLUX.1 Dev with STUDIO JUNO. Uses depth maps extracted from the input image to enable realism-focused image generation. Based on FLUX.1 Dev to simplify development.",
      Flux1ProFill: "FLUX.1 Pro Fill",
      Flux1ProFillDescription:
        "The Fill model of FLUX.1 Pro. Provides advanced inpainting capabilities using text descriptions and binary masks to seamlessly edit existing images. Also supports outpainting to expand images beyond their original boundaries.",
      Flux1DevFill: "FLUX.1 Dev Fill w/ JUNO",
      Flux1DevFillDescription:
        "The Fill model of FLUX.1 Dev. Provides advanced inpainting capabilities using text descriptions and binary masks to seamlessly edit existing images. Also supports outpainting to expand images beyond their original boundaries.",
      KolorsVTO: "Kolors Virtual Try-On",
      KolorsVTODescription:
        "Generates images that allow you to try on virtual clothes. It is capable of generating images that reflect the user's intentions.",
      RecraftV3: "Recraft V3",
      RecraftV3Description:
        "Recraft V3 has a very high understanding of prompts, allowing for the generation of precise and beautiful images that reflect the user's intentions. It is also highly rated for aesthetics like Midjourney.",
      Ideogram2: "Ideogram 2.0",
      Ideogram2Description:
        "A model that excels in text insertion, capable of generating images with text that is impossible with other models.",
      Ideogram2Turbo: "Ideogram 2 Turbo",
      Ideogram2TurboDescription:
        "A model that has been adjusted from Ideogram 2.0 to improve speed. It can be used for simple text insertion.",
      Ideogram2Edit: "Ideogram 2 Edit",
      Ideogram2EditDescription:
        "An advanced inpainting model of Ideogram 2.0 that enables seamless editing integrated naturally into existing images using textual descriptions and binary masks.",
      Ideogram2TurboEdit: "Ideogram 2 Turbo Edit",
      Ideogram2TurboEditDescription:
        "An optimized version of Ideogram 2 Edit with improved speed. It allows seamless editing naturally integrated into existing images using textual descriptions and binary masks.",
      SD: "Stable Diffusion",
      SDDescription:
        "Prompt knowledge is required, and if not entered accurately, images are prone to failure and it is more challenging. However, there are many types of models, enabling the generation of images that cannot be produced by others.",
      SDXL: "Stable Diffusion XL",
      SDXLDescription:
        "Capable of generating higher resolution images than the traditional Stable Diffusion. Also, the prompts are more flexible than SD, but there are still fewer types of models available.",
      AuraSR: "Aura SR",
      AuraSRDescription:
        "Uses next-generation upscaling technology to improve image resolution while maintaining the similarity of the original image.",
      ClarityUpscaler: "Clarity Upscaler",
      ClarityUpscalerDescription:
        "Improves image quality while maintaining the similarity of the original image, enhancing creativity and performing well as an enhancer.",
      CreativeUpscaler: "Creative Upscaler",
      CreativeUpscalerDescription:
        "Improves image resolution by upscaling the original image and generating new parts to fill in the missing parts. It can be enlarged up to 4,194,304px (approximately 2,048px square).",
      SD3: "Stable Diffusion 3 Large",
      SD3Description:
        "The latest version of Stable Diffusion. It excels in adhering to prompts and incorporating text into images.",
      SD35: "Stable Diffusion 3.5 Large",
      SD35Description:
        "The latest model developed by Stability AI, Stable Diffusion 3.5. With 8 billion parameters, excellent quality, and rapid compliance, the model has lower quality than FLUX.1 Dev but higher prompt accuracy.",
      SDUltra: "Stable Image Ultra",
      SDUltraDescription:
        "Ultra, created from the most advanced models including Stable Diffusion 3, excels in typography, complex compositions, dynamic lighting, vivid colors, and overall coherence and structure of artworks.",
      RealESRGAN: "Real-ESRGAN",
    },
    category: {
      vary: "Variation Generation Model: Reproduces images from original images, enabling image refinement. Generates image restyling and variations according to the prompt.",
      twoK: "2K Model: Capable of generating high-quality 2K resolution (4MP) images.",
      vector: "Vector Model: Also supports vector illustrations.",
      controlNet:
        "ControlNet: Allows control of image generation. Canny generates edges from the original image, Depth generates depth, and new images are generated from them.",
      editor:
        "Editor: Provides advanced inpainting capabilities using text descriptions and binary masks to seamlessly edit existing images.",
    },
    aspectRatio: {
      title: "Aspect Ratio",
      custom: "Custom",
    },
    width: "Width",
    height: "Height",
    step: "Steps",
    sample: "Number of Generated Images",
    sampleDescription:
      "'Width' or 'height' exceeding {{px}}px will limit the number of generated images to one. If you wish to generate multiple images, it is recommended to generate them below {{px}}px and then upscale.",
    rawMode: "RAW Mode",
    rawModeDescription:
      "Generates images with natural beauty rather than artificial beauty. Compared to models that generate images from text, RAW mode significantly enhances the diversity of human subjects and increases the realism of natural photos.",
    resolution: "High-resolution fix (Auto)",
    resolutionCaution: "The number of generations is limited to 1",
    resolutionTooltip:
      "Applying high-resolution fix improves the resolution of the generated image. It is automatically applied when either the width or height is {{px}}px or more.",
    guidanceScale: "Prompt Emphasis",
    seedSD: "Seed (Valid for the same model only)",
    dallE3RevisedPrompt: "AI-Enhanced Prompt",
    colorSelection: {
      title: "Color Palette",
    },
    style: {
      title: "Style",
      weight: "Influence of Style",
      dalle3: {
        vivid: "Vivid (Default)",
        natural: "Natural",
      },
      ideogram: {
        general: "General",
        realistic: "Realistic",
        design: "Design",
        anime: "Anime",
        render3d: "3D Rendering",
      },
      fluxJuno: {
        general: "General",
        anime: "Anime v1.0",
        japanese: "Japanese Portrait v1.0",
      },
      recraft: {
        any: "General",
        realisticImage: "Realistic Photo",
        realisticImageBW: "Realistic Photo - Black and White",
        realisticImageHardFlash: "Realistic Photo - Hard Flash",
        realisticImageHDR: "Realistic Photo - HDR",
        realisticImageNaturalLight: "Realistic Photo - Natural Light",
        realisticImageStudioPortrait: "Realistic Photo - Studio Portrait",
        realisticImageEnterprise: "Realistic Photo - Enterprise",
        realisticImageMotionBlur: "Realistic Photo - Motion Blur",
        realisticImageEveningLight: "Realistic Photo - Evening Light",
        realisticImageFadedNostalgia: "Realistic Photo - Faded Nostalgia",
        realisticImageForestLife: "Realistic Photo - Forest Life",
        realisticImageMysticNaturalism: "Realistic Photo - Mystic Naturalism",
        realisticImageNaturalTones: "Realistic Photo - Natural Tones",
        realisticImageOrganicCalm: "Realistic Photo - Organic Calm",
        realisticImageRealLifeGlow: "Realistic Photo - Real Life Glow",
        realisticImageRetroRealism: "Realistic Photo - Retro Realism",
        realisticImageRetroSnapshot: "Realistic Photo - Retro Snapshot",
        realisticImageUrbanDrama: "Realistic Photo - Urban Drama",
        realisticImageVillageRealism: "Realistic Photo - Village Realism",
        realisticImageWarmFolk: "Realistic Photo - Warm Folk",
        digitalIllustration: "Digital Illustration",
        digitalIllustrationPixelArt: "Digital Illustration - Pixel Art",
        digitalIllustrationHandDrawn: "Digital Illustration - Hand Drawn",
        digitalIllustrationGrain: "Digital Illustration - Grain 1.0",
        digitalIllustrationGrain20: "Digital Illustration - Grain 2.0",
        digitalIllustrationInfantileSketch: "Digital Illustration - Child's Sketch",
        digitalIllustration2DArtPoster: "Digital Illustration - 2D Art Poster",
        digitalIllustration2DArtPoster2: "Digital Illustration - 2D Art Poster 2",
        digitalIllustrationHandmade3D: "Digital Illustration - Clay 3D Art",
        digitalIllustrationHandDrawnOutline: "Digital Illustration - Hand Drawn Outline",
        digitalIllustrationEngravingColor: "Digital Illustration - Engraving Color",
        digitalIllustrationAntiquarian: "Digital Illustration - Antiquarian",
        digitalIllustrationBoldFantasy: "Digital Illustration - Bold Fantasy",
        digitalIllustrationChildBook: "Digital Illustration - Child's Book",
        digitalIllustrationChildBooks: "Digital Illustration - Children's Books",
        digitalIllustrationCover: "Digital Illustration - Cover Art",
        digitalIllustrationCrosshatch: "Digital Illustration - Crosshatching",
        digitalIllustrationDigitalEngraving: "Digital Illustration - Digital Engraving",
        digitalIllustrationExpressionism: "Digital Illustration - Expressionism",
        digitalIllustrationFreehandDetails: "Digital Illustration - Freehand Details",
        digitalIllustrationGraphicIntensity: "Digital Illustration - Graphic Intensity",
        digitalIllustrationHardComics: "Digital Illustration - Hard Comics",
        digitalIllustrationLongShadow: "Digital Illustration - Long Shadow",
        digitalIllustrationModernFolk: "Digital Illustration - Modern Folk",
        digitalIllustrationMulticolor: "Digital Illustration - Multicolor",
        digitalIllustrationNeonCalm: "Digital Illustration - Neon Calm",
        digitalIllustrationNoir: "Digital Illustration - Noir",
        digitalIllustrationNostalgicPastel: "Digital Illustration - Nostalgic Pastel",
        digitalIllustrationOutlineDetails: "Digital Illustration - Outline Details",
        digitalIllustrationPastelGradient: "Digital Illustration - Pastel Gradient",
        digitalIllustrationPastelSketch: "Digital Illustration - Pastel Sketch",
        digitalIllustrationPopArt: "Digital Illustration - Pop Art",
        digitalIllustrationPopRenaissance: "Digital Illustration - Pop Renaissance",
        digitalIllustrationStreetArt: "Digital Illustration - Street Art",
        digitalIllustrationTabletSketch: "Digital Illustration - Tablet Sketch",
        digitalIllustrationUrbanGlow: "Digital Illustration - Urban Glow",
        digitalIllustrationUrbanSketching: "Digital Illustration - Urban Sketching",
        digitalIllustrationVanillaDreams: "Digital Illustration - Vanilla Dreams",
        digitalIllustrationYoungAdultBook: "Digital Illustration - Young Adult Book",
        digitalIllustrationYoungAdultBook2: "Digital Illustration - Young Adult Book 2",
        vectorIllustration: "Vector Illustration (.SVG)",
        vectorIllustrationBoldStroke: "Vector Illustration - Bold Stroke (.SVG)",
        vectorIllustrationChemistry: "Vector Illustration - Chemistry (.SVG)",
        vectorIllustrationColoredStencil: "Vector Illustration - Colored Stencil (.SVG)",
        vectorIllustrationContourPopArt: "Vector Illustration - Contour Pop Art (.SVG)",
        vectorIllustrationCosmics: "Vector Illustration - Cosmics (.SVG)",
        vectorIllustrationCutout: "Vector Illustration - Cutout (.SVG)",
        vectorIllustrationDepressive: "Vector Illustration - Depressive (.SVG)",
        vectorIllustrationEditorial: "Vector Illustration - Editorial (.SVG)",
        vectorIllustrationEmotionalFlat: "Vector Illustration - Emotional Flat (.SVG)",
        vectorIllustrationInfographical: "Vector Illustration - Infographical (.SVG)",
        vectorIllustrationMarkerOutline: "Vector Illustration - Marker Outline (.SVG)",
        vectorIllustrationMosaic: "Vector Illustration - Mosaic (.SVG)",
        vectorIllustrationNaivector: "Vector Illustration - Naive Vector (.SVG)",
        vectorIllustrationRoundishFlat: "Vector Illustration - Roundish Flat (.SVG)",
        vectorIllustrationSegmentedColors: "Vector Illustration - Segmented Colors (.SVG)",
        vectorIllustrationSharpContrast: "Vector Illustration - Sharp Contrast (.SVG)",
        vectorIllustrationThin: "Vector Illustration - Thin (.SVG)",
        vectorIllustrationVectorPhoto: "Vector Illustration - Vector Photo (.SVG)",
        vectorIllustrationVividShapes: "Vector Illustration - Vivid Shapes (.SVG)",
        vectorIllustrationEngraving: "Vector Illustration - Engraving (.SVG)",
        vectorIllustrationLineArt: "Vector Illustration - Line Art (.SVG)",
        vectorIllustrationLineCircuit: "Vector Illustration - Line Circuit (.SVG)",
        vectorIllustrationLinocut: "Vector Illustration - Linocut (.SVG)",
      },
    },
    basicSettings: "Basic Settings",
    advancedSettings: "Advanced Settings",
    negativePrompt: "Negative Prompt",
    negativePromptHistory: "Negative Prompt",
    negativePromptPlaceholder: "e.g. worst quality, nsfw, bad anatomy, out of focus",
    negativePromptDescription: "Please specify elements you do not want included in the image.",
    negativePromptAI: "Generate Negative Prompt",
    negativePromptTitle: "Preset Name",
    negativePromptSave: "Save Negative Prompt",
    negativePromptList: "Load Negative Prompt",
    delete: {
      title: "Are you sure you want to delete the negative prompt?",
      description: "Deleting the negative prompt will remove all data within the prompt. This action cannot be undone.",
      success: "The negative prompt has been deleted.",
    },
    image: "Original Image",
    imageFace: "Original Image (Face)",
    imageHuman: "Original Image (Human)",
    imageVTO: "Original Image (Clothes)",
    imageStrength: "Influence of Original Image",
    imageEditor: {
      title: "Image Editor",
      mask: "Mask",
      original: "Original",
      open: "Open Editor",
      openImage: "Open Image",
      openImageDescription: "Automatically set from the image editor",
      save: "Save",
      brushSize: "Mask Brush Size",
      noImage: "Please upload at least one image.",
      reset: "Reset All",
      aspectRatio: "Image Size",
      eraser: "Eraser",
      eraserSize: "Eraser Size",
      select: "Image Selection Tool: Move, Enlarge, Reduce\n(Delete with DELETE key while selecting image)",
      upload: "Upload Image",
      generated: "Upload from Generated Images",
      unsplash: "Add images from Unsplash",
      description: "Insert an image. New images will be generated in the white margin and brush drawing area.",
    },
    idWeight: "Influence of Facial Features",
    alert: {
      imageType: "File must be in JPG or PNG format.",
      imageSize: "File size must be under 10MB.",
    },
    scale: "Scale",
    scaleInfo:
      "The scale determines the size of the generated image. The larger the scale, the more detailed the image will be, but it will consume more credits.",
    creativity: "Creativity",
    creativityInfo: "The higher the creativity, the more creative the generated image will be.",
    detail: "Detail",
    detailInfo: "The higher the detail, the more detailed the generated image will be.",
    resemblance: "Resemblance",
    resemblanceInfo: "The higher the resemblance, the more similar the generated image will be to the original image.",
    upscaleMode: "Upscaling Mode",
    smooth: "Smooth (Illustration)",
    details: "Detailed (Photorealistic)",
    faceFix: "Face Correction",
    uploadSuccess: "The image has been uploaded.",
    uploadError: {
      size: "The file size must be under {{size}}MB.",
      type: "The file must be in JPG or PNG format.",
      scale: "The maximum width of the image must be under 3,000px.",
    },
    filtered: "BLOCKED",
    filteredDescription: "It is not displayed because it may violate the policy.",
    show: "Display images that may violate the policy",
    hide: "Hide images that may violate the policy",
    publicStatus: "Public Setting",
    publicDescription:
      "Private settings are available for Standard plan and above. Even if set to public, it may be changed to private.",
    public: "Public",
    private: "Private",
  },
  history: {
    title: "The latest 10 messages",
    input: "Enter Text",
    dice: "Randomly generate sample text for image generation. * 0.1 credit will be consumed to prevent fraud.",
    improve:
      "Generate a prompt from the entered text (keywords) and translate it into English.  * 0.1 credit will be consumed to prevent fraud.",
    prompt: "Prompt",
    size: "Size",
    upscale: "Upscale",
    edit: "Edit",
    img2img: "Image to Image",
    img2vid: "Image to Video",
    useParams: "Parameters",
    download: "DOWNLOAD",
    generating: "Generating Images",
    generatingDescription:
      "The image is being generated. Please wait for a while.\nThe generation will continue even if you leave the page.",
    delete: {
      title: "Are you sure you want to delete the data?",
      description: "Deleting the data will remove all data within the image. This action cannot be undone.",
      success: "The data has been deleted.",
    },
    optionalPromptPlaceholder: "Please enter the image prompt (optional)",
    notNeededPlaceholder: "Image prompt is not needed",
    promptPlaceholder: "Please enter the image prompt",
  },
  modelList: {
    title: "Models",
    description: "STUDIO JUNO offers various models for image generation. ",
  },
  video: junoVideo,
  inputPreset: junoPreset,
  message: {
    delete: "The image has been deleted.",
  },
  validation: {
    model: "Please select a model.",
    image: "Please upload an image.",
    prompt: "Please enter a prompt within 1,000 characters.",
    negativePrompt: "Please enter a negative prompt within 1,000 characters.",
  },
};
export default juno;
