import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { ChatMode } from "../../../types/chatTypes";

interface ToolbarProps {
  mode: ChatMode;
  onModeChange: (mode: ChatMode) => void;
}

export const Toolbar: React.FC<ToolbarProps> = ({ mode, onModeChange }) => {
  useTranslation();

  return (
    <ToggleButtonGroup
      value={mode}
      exclusive
      onChange={(_: MouseEvent<HTMLElement>, newMode: ChatMode) => {
        if (newMode !== null) {
          onModeChange(newMode);
        }
      }}
      aria-label="chat mode"
      size="small"
      color="secondary"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 0,
        "& .MuiToggleButton-root": {
          px: { xs: 1, sm: 2 },
          py: { xs: 0.5, sm: 1 },
          fontSize: { xs: "0.75rem", sm: "inherit" },
          minWidth: { xs: "auto", sm: "120px" },
          "& .MuiSvgIcon-root": {
            mr: { xs: 0.5, sm: 1 },
            fontSize: { xs: "1rem", sm: "1.25rem" },
          },
          "& .MuiToggleButton-label": {
            display: { xs: "none", sm: "block" },
          },
        },
      }}
    >
      <ToggleButton value="llm" aria-label="llm mode">
        <ChatIcon fontSize="small" />
        <span className="MuiToggleButton-label">AI Chat</span>
      </ToggleButton>
      <ToggleButton value="web" aria-label="web search mode">
        <SearchIcon fontSize="small" />
        <span className="MuiToggleButton-label">WEB Search</span>
      </ToggleButton>
      <ToggleButton value="youtube" aria-label="youtube search mode">
        <YouTubeIcon fontSize="small" />
        <span className="MuiToggleButton-label">YOUTUBE Search</span>
      </ToggleButton>
      {/* <ToggleButton value="research" aria-label="research mode">
        <SmartToyIcon fontSize="small" />
        <span className="MuiToggleButton-label">Research Argent *Beta</span>
      </ToggleButton> */}
    </ToggleButtonGroup>
  );
};
