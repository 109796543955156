const meta = {
  title: "Tenak AI",
  keywords: "Tenak AI, tenak.ai, tenak, Studio Juno, Notes Nebula, Nebula",
  description:
    "Tenak AI is a content creation platform that provides writing AI, image generation AI, and voice generation AI. It supports creators in efficient and high-quality production.",
  notesNebula: {
    title: "Notes Nebula",
    keywords: "Tenak AI, Notes Nebula, Notes Nebula, Notes, Nebula",
    description:
      "Notes Nebula is a platform where you can easily publish articles written in Tenak AI's AI text editor. Share your ideas with the world.",
  },
  juno: {
    title: "STUDIO JUNO",
    keywords: "Tenak AI, STUDIO JUNO, Juno, Studio Juno, Image Generation AI",
    description:
      "STUDIO JUNO is Tenak AI's image generation service where you can use various image generation AIs on one platform. Shape your vision.",
  },
  pricing: {
    title: "Plans and Pricing",
    description:
      "Introducing the price plans of each service of Tenak AI. You can choose the best plan according to your needs.",
  },
  announcement: {
    title: "Announcements",
    description: "We will inform you of the latest information on Tenak.ai / Studio Juno / Notes Nebula.",
  },
};
export default meta;
