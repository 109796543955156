// src/hooks/usePageTracking.ts
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// GTMでページビューをトラッキングするためのカスタムフック
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // window.dataLayerを確認または初期化
    window.dataLayer = window.dataLayer || [];
    // GTMで設定されたページビューイベントを手動でプッシュ
    window.dataLayer.push({
      event: "page_view",
      page_path: location.pathname + location.search,
      page_title: document.title,
    });
  }, [location]);
};

export default usePageTracking;
