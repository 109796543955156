import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CasinoIcon from "@mui/icons-material/Casino";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Divider, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import { AiEngineList, MenuList } from "../../../../types/junoTypes";

import ProPlanModal from "../../../../components/common/ProPlanModal";
import { useCommonContext } from "../../../../context/juno/CommonContext";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";
import { useUpscaleContext } from "../../../../context/juno/UpscaleContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { useJunoModels } from "../../../../hooks/useJunoModels";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import InputPreset from "./InputPreset";

type AiEngine = AiEngineList;

interface InputData {
  requestUuid?: string | null;
  action: string;
  prompt: string;
  aiEngine: AiEngine;
  initImage?: string;
  imageStrength?: number;
  initImage2?: string;
  imageStrength2?: number;
  width?: number;
  height?: number;
  revisedPrompt?: boolean;
  style?: string;
  step?: number;
  sample?: number;
  guidanceScale?: number;
  negativePrompt?: string;
  seed?: number;
  model?: { name: string; id: string };
  lora?: { name: string; id: string };
  loraStrength?: number;
  uuidParent?: string;
  isPublic?: boolean;
  aspectRatio?: string;
  needFixPrompt: boolean;
  styleFluxJuno?: string;
}

type InputPromptProps = {
  fetchAndSetImageList: (scroll?: boolean) => void;
};

const InputPrompt: React.FC<InputPromptProps> = ({ fetchAndSetImageList }) => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const { getModelParams } = useJunoModels();

  const theme = useTheme();
  const {
    prompt,
    setPrompt,
    negativePrompt,
    revisedPrompt,
    aspectRatio,
    width,
    height,
    step,
    sample,
    guidanceScale,
    seed,
    style,
    colorsRecraft,
    styleWeight,
    rawMode,
    isPublic,
  } = useParamsContext();

  const { image, imageStrength, image2, imageStrength2, editorInputImage, editorInputMask } = useUploadImageContext();
  const { uuidParent, upscaleFactor, upscaleCreativity, upscaleDetail, upscaleResemblance } = useUpscaleContext();

  const { setProcessing, setRefreshTrigger } = useCommonContext();

  const [localPrompt, setLocalPrompt] = useState<string>("");
  const [rotationDegree, setRotationDegree] = useState(0);
  const { setAlert } = useAlert();
  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "Flux1Dev";
  const menu = (params.get("menu") as MenuList) || "txt2img";
  const [waitingGenerate, setWaitingGenerate] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [generatingPrompt, setGeneratingPrompt] = useState(false);
  const [openProDialog, setOpenProDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string | undefined>(undefined);
  const [requiredPrompt, setRequiredPrompt] = useState<"REQUIRED" | "OPTIONAL" | "NOT_NEEDED">("REQUIRED");

  useEffect(() => {
    setLocalPrompt(prompt);
  }, [prompt]);

  useEffect(() => {
    const text = location.state?.textEditor;
    if (text) {
      setLocalPrompt(text as string);
    }
  }, [location.state?.textEditor]);

  useEffect(() => {
    requiredPromptMange();
  }, [menu, ai_engine]);

  const validateContext = (aiEngine: string) => {
    const errorMessages: string[] = [];

    if (menu == "img2img" || menu == "upscale") {
      if (image === null) {
        errorMessages.push("・" + t("juno.validation.image"));
      }
      if (aiEngine === "KolorsVTO" && image2 === null) {
        errorMessages.push("・" + t("juno.validation.image"));
      }
    }

    if (localPrompt.trim().length > 1000) {
      errorMessages.push("・" + t("juno.validation.prompt"));
    }

    // エラーメッセージがある場合、一つのアラートで表示
    if (errorMessages.length > 0) {
      setAlert("error", errorMessages.join("\n"));
    }

    return errorMessages.length === 0;
  };

  // 画像生成APIへのリクエスト
  const handleSend = async (request_uuid?: string | null) => {
    setWaitingGenerate(true);
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/generate";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const aiEngine = ai_engine as AiEngine;

      if (!validateContext(aiEngine)) return;
      if (!validateInputs(aiEngine)) return;

      let data: InputData = {
        action: menu,
        prompt: localPrompt,
        aiEngine: aiEngine,
        requestUuid: request_uuid,
        isPublic: isPublic,
        needFixPrompt: false,
      };

      data = { ...data, ...getAiEngineData(aiEngine, data) };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });
      const res = await response.json();

      handleResponse(res);
    } catch (e) {
      setAlert("error", "Something went wrong. Please try again.");
    } finally {
      dispatch(setCreditTrigger(true));
      fetchAndSetImageList(false);
      setProcessing(false);
    }
  };

  const validateInputs = (aiEngine: AiEngine) => {
    const aiEnginesRequiringImage = ["Flux1Dev", "SD3", "Ideogram2", "Ideogram2Turbo"];
    if (aiEnginesRequiringImage.includes(aiEngine) && menu === "img2img" && !image) {
      setAlert("error", "Please upload an image.");
      return false;
    }
    return true;
  };

  const getAiEngineData = (aiEngine: AiEngine, data: InputData) => {
    const paramsArray = getModelParams(aiEngine, menu);
    let additionalData: { [key: string]: any } = {};

    // 全てのパラメータを保持しているオブジェクト（エラー回避込み）
    const allParams = {
      width: width?.[aiEngine] ?? 1024,
      height: height?.[aiEngine] ?? 1024,
      negativePrompt: negativePrompt ?? "",
      step: step?.[aiEngine],
      sample: sample?.[aiEngine] ?? 1,
      guidanceScale: guidanceScale?.[aiEngine],
      seed: seed?.[aiEngine] ?? null,
      revisedPrompt: revisedPrompt ?? false,
      aspectRatio: aspectRatio?.[aiEngine],
      style: style?.[aiEngine],
      styleWeight: styleWeight?.[aiEngine],
      rawMode: ["Flux11ProUltra", "Flux11ProUltraRedux"].includes(aiEngine) ? (rawMode ?? false) : undefined,
      colors: colorsRecraft ?? [],
      initImage: image,
      imageStrength: imageStrength,
      initImage2: image2,
      imageStrength2: imageStrength2,
    };

    // paramsArray に基づいて additionalData を動的に作成
    paramsArray.forEach((param) => {
      if (allParams.hasOwnProperty(param)) {
        additionalData[param] = allParams[param];
      }
    });

    if (data.action === "editor" && editorInputMask && editorInputImage) {
      additionalData = {
        ...additionalData,
        aspectRatio: aspectRatio?.[aiEngine],
        maskImage: editorInputMask,
        initImage: editorInputImage,
      };
    }

    // オリジナルのUUIDをセット
    if (data.action === "img2img" && image) {
      additionalData = {
        ...additionalData,
        uuidParent: uuidParent,
      };
    }

    return additionalData;
  };

  // 生成リクエストのUUIDを元に再度リクエストを送信
  const handleResponse = (res: any) => {
    if (res.status === "success") {
      fetchAndSetImageList();
    } else if (res.status === "temporary_success") {
      fetchAndSetImageList(false);
      handleSend(res.request_uuid);
    } else if (res.status === "processing") {
      // webhookを待つ
    } else {
      fetchAndSetImageList();
      if (res.message === "NO_CREDITS") {
        setDialogMessage(t("juno.error.noCredit"));
        setOpenProDialog(true);
      } else if (res.message === "UPGRADE_PLAN") {
        setDialogMessage(t("juno.error.upgrade"));
        setOpenProDialog(true);
      } else {
        setAlert("error", res.message);
      }
    }
  };

  // プロンプト生成APIへのリクエスト
  const generateSampleText = async () => {
    try {
      setGeneratingPrompt(true);
      if (!(await checkCredit())) return;
      setRotationDegree(rotationDegree + 360);
      setProcessing(true);

      const url = "/api/v1/juno/generate-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ aiEngine: ai_engine }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setGeneratingPrompt(false);
    }
  };

  // プロンプト生成APIへのリクエスト
  const updatePrompt = async () => {
    try {
      setGeneratingPrompt(true);
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/update-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          aiEngine: ai_engine,
          prompt: localPrompt,
        }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setGeneratingPrompt(false);
    }
  };

  useEffect(() => {
    if (waitingGenerate) {
      setTimeout(() => {
        setWaitingGenerate(false);
      }, 2000);
    }
  }, [waitingGenerate]);

  const GeneratingPrompt = () => {
    return (
      <div
        style={{
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={20} color="primary" />
      </div>
    );
  };

  // アップスケールリクエスト
  const handleUpscale = async (request_uuid?: string | null) => {
    setWaitingGenerate(true);
    try {
      if (!image) {
        setAlert("error", t("juno.validation.image"));
        return;
      }

      setProcessing(true);

      const url = "/api/v1/juno/generate-upscale";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      // 画像サイズをチェックする非同期関数
      const checkImageSize = async (): Promise<boolean> => {
        return new Promise((resolve) => {
          if (image && ai_engine === "CreativeUpscaler") {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              if (img.width * img.height > 4194304) {
                setAlert("error", "The image size is too large.");
                setProcessing(false);
                resolve(false); // サイズが大きすぎる場合、falseを返す
              } else {
                resolve(true); // サイズが問題なければtrueを返す
              }
            };
          } else {
            resolve(true); // サイズチェックが不要な場合はtrueを返す
          }
        });
      };

      // 画像サイズチェックを行う
      const isSizeValid = await checkImageSize();
      if (!isSizeValid) {
        return; // サイズが無効ならfetchを実行しない
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          action: "upscale",
          aiEngine: ai_engine,
          initImage: image,
          factor: upscaleFactor[ai_engine],
          creativity: upscaleCreativity[ai_engine],
          detail: upscaleDetail[ai_engine],
          resemblance: upscaleResemblance[ai_engine],
          requestUuid: request_uuid,
          uuidParent: uuidParent,
          isPublic: uuidParent ? isPublic : false,
          prompt: localPrompt,
        }),
      });

      const res = await response.json();
      if (res.status === "success") {
        setRefreshTrigger(new Date().getTime());
      } else if (res.status === "temporary_success") {
        setRefreshTrigger(new Date().getTime());
        await handleUpscale(res.request_uuid);
      } else {
        setAlert("error", res.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setCreditTrigger(true));
      setProcessing(false);
    }
  };

  const requiredPromptMange = () => {
    if (
      [
        "AuraSR",
        "Flux1DevRedux",
        "Flux1SchnellRedux",
        "KolorsVTO",
        "RecraftClarityUpscaler",
        "RecraftCreativeUpscaler",
      ].includes(ai_engine)
    ) {
      setRequiredPrompt("NOT_NEEDED");
      return t("juno.history.notNeededPlaceholder");
    }
    if (menu === "upscale") {
      setRequiredPrompt("OPTIONAL");
      return t("juno.history.optionalPromptPlaceholder");
    }
    if (menu === "img2img" && ["Flux11ProUltraRedux", "Flux11ProRedux"].includes(ai_engine)) {
      setRequiredPrompt("OPTIONAL");
      return t("juno.history.optionalPromptPlaceholder");
    }
    setRequiredPrompt("REQUIRED");
    return t("juno.history.promptPlaceholder");
  };

  const placeholder = () => {
    switch (requiredPrompt) {
      case "REQUIRED":
        return t("juno.history.promptPlaceholder");
      case "OPTIONAL":
        return t("juno.history.optionalPromptPlaceholder");
      case "NOT_NEEDED":
        return t("juno.history.notNeededPlaceholder");
      default:
        return t("juno.history.promptPlaceholder");
    }
  };

  const inputValue = () => {
    let disabled = false;
    if (menu === "upscale") {
      disabled = false;
    }
    if (menu === "txt2img" && localPrompt.trim().length === 0) {
      disabled = true;
    }
    if (menu === "img2img") {
      if (["Flux11ProUltraRedux", "Flux11ProRedux"].includes(ai_engine)) {
        disabled = false;
      }
      if (["Flux1DevRedux", "Flux1SchnellRedux", "KolorsVTO"].includes(ai_engine)) {
        disabled = true;
      }
    }
    return disabled ? "" : localPrompt;
  };

  return (
    <>
      <Box sx={{ px: 2, pb: 1, mt: 2 }}>
        <TextField
          label={t("juno.history.input")}
          variant="outlined"
          autoComplete={"off"}
          placeholder={placeholder()}
          autoFocus
          value={inputValue()}
          onChange={(event) => setLocalPrompt(event.target.value)}
          disabled={[
            "AuraSR",
            "Flux1DevRedux",
            "Flux1SchnellRedux",
            "RecraftClarityUpscaler",
            "RecraftCreativeUpscaler",
          ].includes(ai_engine)}
          multiline
          fullWidth
          maxRows={4}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  {localPrompt.trim().length === 0 ? (
                    generatingPrompt ? (
                      <GeneratingPrompt />
                    ) : (
                      // サンプルプロンプト生成
                      <Tooltip title={t("juno.history.dice")}>
                        <span>
                          <IconButton
                            onClick={generateSampleText}
                            disabled={ai_engine === "AuraSR" || generatingPrompt}
                          >
                            <CasinoIcon
                              sx={{
                                transform: `rotate(${rotationDegree}deg)`,
                                transition: "transform 1s ease-in-out",
                              }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )
                  ) : // プロンプトアップデート
                  generatingPrompt ? (
                    <GeneratingPrompt />
                  ) : (
                    <Tooltip title={t("juno.history.improve")}>
                      <span>
                        <IconButton
                          onClick={() => updatePrompt()}
                          disabled={ai_engine === "AuraSR" || generatingPrompt}
                        >
                          <AutoFixHighIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {/* delete */}
                  <Tooltip title={t("common.delete")}>
                    <span>
                      <IconButton
                        onClick={() => {
                          setLocalPrompt("");
                        }}
                        disabled={ai_engine === "AuraSR"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <InputPreset prompt={localPrompt} setPrompt={setLocalPrompt} />

                  <Divider orientation="vertical" sx={{ height: "28px", mx: 0.5 }} variant={"middle"} />

                  <Typography variant="body2" color="secondary" ml={1}>
                    @{t(`juno.input.engine.${ai_engine}`)}:
                  </Typography>
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" color="secondary">
                <IconButton
                  disabled={
                    waitingGenerate ||
                    generatingPrompt ||
                    (requiredPrompt === "REQUIRED" && localPrompt.trim().length === 0)
                  }
                  color="secondary"
                  onClick={menu === "upscale" ? () => handleUpscale() : () => handleSend()}
                >
                  {waitingGenerate ? <CircularProgress size={20} color="primary" /> : <SendIcon />}
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: 5, backgroundColor: theme.palette.background.paper },
            inputProps: { maxLength: 1000 },
          }}
        />
      </Box>
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} message={dialogMessage} />
    </>
  );
};
export default InputPrompt;
