import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import MicIcon from "@mui/icons-material/Mic";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { alpha, Box, Grid, Menu, MenuItem, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useCallback } from "react";
import { RowBox } from "../../../utils/styledBox";

// Web Speech API型定義
interface SpeechRecognitionErrorEvent extends Event {
  error: string;
  message?: string;
}

interface SpeechRecognitionEvent extends Event {
  results: SpeechRecognitionResultList;
  resultIndex: number;
}

interface SpeechRecognitionResultList {
  readonly length: number;
  [index: number]: SpeechRecognitionResult;
}

interface SpeechRecognitionResult {
  readonly length: number;
  [index: number]: SpeechRecognitionAlternative;
  isFinal: boolean;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

interface SpeechRecognition extends EventTarget {
  lang: string;
  continuous: boolean;
  interimResults: boolean;
  start: () => void;
  stop: () => void;
  onresult: (event: SpeechRecognitionEvent) => void;
  onerror: (event: SpeechRecognitionErrorEvent) => void;
}

interface SpeechRecognitionConstructor {
  new (): SpeechRecognition;
}

declare global {
  interface Window {
    SpeechRecognition?: SpeechRecognitionConstructor;
    webkitSpeechRecognition?: SpeechRecognitionConstructor;
  }
}

export type ChatMode = "llm" | "web" | "youtube" | "research";

interface ChatInputProps {
  inputValue: string;
  onInputChange: (value: string) => void;
  onSubmit: (images?: File[], files?: File[]) => void;
  onModeChange?: (mode: ChatMode) => void;
}

interface Attachment {
  type: "image" | "file";
  url: string;
  file: File;
}

const ChatInput = ({ inputValue, onInputChange, onSubmit, onModeChange }: ChatInputProps) => {
  const [mode, setMode] = React.useState<ChatMode>("llm");
  const [isRecording, setIsRecording] = React.useState(false);
  const [attachmentAnchorEl, setAttachmentAnchorEl] = React.useState<null | HTMLElement>(null);
  const [attachments, setAttachments] = React.useState<Attachment[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const imageInputRef = React.useRef<HTMLInputElement>(null);
  const recognitionRef = React.useRef<SpeechRecognition | null>(null);
  const [finalTranscript, setFinalTranscript] = React.useState("");

  const handleModeChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newMode: ChatMode) => {
      if (newMode !== null) {
        setMode(newMode);
        onModeChange?.(newMode);
      }
    },
    [onModeChange]
  );

  const handleVoiceInput = useCallback(async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Audio stream obtained:", stream);
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
          console.error("Speech Recognition APIがサポートされていません");
          return;
        }

        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.lang = "ja-JP";
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;

        const initialText = inputValue;
        setFinalTranscript(initialText);

        recognitionRef.current.onresult = (event) => {
          const results = Array.from(event.results);
          const lastResult = results[results.length - 1];

          if (lastResult) {
            const transcript = lastResult[0].transcript;
            if (lastResult.isFinal) {
              const newFinalTranscript = finalTranscript + transcript;
              setFinalTranscript(newFinalTranscript);
              onInputChange(newFinalTranscript);
            } else {
              onInputChange(finalTranscript + transcript);
            }
          }
        };

        recognitionRef.current.onerror = (event) => {
          console.error("音声認識エラー:", event.error, event.message);
          setIsRecording(false);
        };

        recognitionRef.current.start();
        setIsRecording(true);
      } catch (err) {
        console.error("音声の取得に失敗しました:", err);
      }
    } else {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
      setIsRecording(false);
    }
  }, [isRecording, inputValue, finalTranscript, onInputChange]);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, type: "file" | "image") => {
    const files = Array.from(e.target.files || []);
    const uploadedFiles: Attachment[] = files.map((file) => ({
      type,
      url: URL.createObjectURL(file),
      file,
    }));
    setAttachments((prev) => [...prev, ...uploadedFiles]);
  }, []);

  const handleRemoveAttachment = useCallback((index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        px: 2,
        py: 1,
      }}
    >
      <TextField
        fullWidth
        label="メッセージを入力"
        size="small"
        variant="outlined"
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            const images = attachments.filter((a) => a.type === "image").map((a) => a.file);
            const files = attachments.filter((a) => a.type === "file").map((a) => a.file);
            onSubmit(images, files);
            setAttachments([]);
          }
        }}
        sx={{ mb: 1 }}
        multiline
        maxRows={4}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <Box sx={{ display: "flex", height: "100%", alignItems: "flex-end" }}>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => {
                  const images = attachments.filter((a) => a.type === "image").map((a) => a.file);
                  const files = attachments.filter((a) => a.type === "file").map((a) => a.file);
                  onSubmit(images, files);
                  setAttachments([]);
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          ),
        }}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, "file")}
            />
            <input
              type="file"
              accept="image/*"
              ref={imageInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, "image")}
            />
            <IconButton onClick={(e) => setAttachmentAnchorEl(e.currentTarget)} size="small">
              <AddIcon />
            </IconButton>
            <Menu
              anchorEl={attachmentAnchorEl}
              open={Boolean(attachmentAnchorEl)}
              onClose={() => setAttachmentAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  imageInputRef.current?.click();
                  setAttachmentAnchorEl(null);
                }}
              >
                <ImageIcon sx={{ mr: 1 }} />
                画像を添付
              </MenuItem>
              <MenuItem
                onClick={() => {
                  fileInputRef.current?.click();
                  setAttachmentAnchorEl(null);
                }}
              >
                <AttachFileIcon sx={{ mr: 1 }} />
                ファイルを添付
              </MenuItem>
            </Menu>

            {attachments.map((attachment, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: 36,
                  height: 36,
                  // overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {attachment.type === "image" ? (
                  <img
                    src={attachment.url}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "4px",
                      border: "1px solid",
                      borderColor: "divider",
                    }}
                  />
                ) : (
                  <ArticleIcon />
                )}
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: -6,
                    right: -6,
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7),
                    width: 18,
                    height: 18,
                    "&:hover": {
                      backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9),
                    },
                    transition: "background-color 0.2s",
                  }}
                  onClick={() => handleRemoveAttachment(index)}
                >
                  <CloseIcon fontSize="small" sx={{ width: 12, height: 12 }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            aria-label="chat mode"
            size="small"
            color="secondary"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <ToggleButton value="llm" aria-label="llm mode" sx={{ px: 2 }}>
              <ChatIcon sx={{ mr: 1 }} fontSize="small" />
              LLM
            </ToggleButton>
            <ToggleButton value="web" aria-label="web search mode" sx={{ px: 2 }}>
              <SearchIcon sx={{ mr: 1 }} fontSize="small" />
              WEB検索
            </ToggleButton>
            <ToggleButton value="youtube" aria-label="youtube search mode" sx={{ px: 2 }}>
              <YouTubeIcon sx={{ mr: 1 }} fontSize="small" />
              YOUTUBE検索
            </ToggleButton>
            <ToggleButton value="research" aria-label="research mode" sx={{ px: 2 }}>
              <SmartToyIcon sx={{ mr: 1 }} fontSize="small" />
              Research Argent
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={3}>
          <RowBox justifyContent="flex-end">
            <IconButton onClick={handleVoiceInput} color={isRecording ? "error" : "default"} size="small">
              <MicIcon />
            </IconButton>
          </RowBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatInput;
