import { Box, BoxProps, Fade, PaperProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface ScrollableBoxProps extends Omit<BoxProps & PaperProps, "component"> {
  component?: React.ElementType; // BoxまたはPaperを選択可能
}

const ScrollableBox: React.FC<ScrollableBoxProps> = ({
  children,
  component = Box, // デフォルトはBox
  sx = {},
  ...props
}) => {
  // const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(true);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;

      // 上部にスクロール可能かどうか
      // setShowTopShadow(scrollTop > 0);

      // 下部にスクロール可能かどうか (誤差対策として 1px を許容)
      setShowBottomShadow(scrollTop + clientHeight < scrollHeight - 1);
    }
  };

  useEffect(() => {
    const element = scrollableRef.current;
    if (element) {
      handleScroll(); // 初期状態の判定
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const borderRadius = (sx as any)?.borderRadius || 0;

  // 選択された動的なコンポーネント
  const ScrollableComponent = component;

  return (
    <Box
      position="relative"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius,
        ...sx,
      }}
    >
      {/* 上部シャドウ */}
      {/*<Fade in={showTopShadow} timeout={1500}>*/}
      {/*  <Box*/}
      {/*    position="absolute"*/}
      {/*    top={0}*/}
      {/*    left={0}*/}
      {/*    right={0}*/}
      {/*    height="20px"*/}
      {/*    sx={{*/}
      {/*      background: `linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)`,*/}
      {/*      zIndex: 1,*/}
      {/*      pointerEvents: "none",*/}
      {/*      borderTopLeftRadius: borderRadius, // 上部の左角丸*/}
      {/*      borderTopRightRadius: borderRadius, // 上部の右角丸*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Fade>*/}

      {/* 下部シャドウ */}
      <Fade in={showBottomShadow} timeout={1500}>
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          height="20px"
          sx={{
            background: (theme) =>
              theme.palette.mode === "dark"
                ? "linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)"
                : "linear-gradient(to top, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%)",
            zIndex: 1,
            pointerEvents: "none",
            borderBottomLeftRadius: borderRadius, // 下部の左角丸
            borderBottomRightRadius: borderRadius, // 下部の右角丸
          }}
        />
      </Fade>

      {/* スクロール可能領域 */}
      <ScrollableComponent
        ref={scrollableRef}
        sx={{
          height: "100%",
          overflowY: "auto",
          position: "relative",
        }}
        {...props} // すべてのpropsを展開
      >
        {children}
      </ScrollableComponent>
    </Box>
  );
};

export default ScrollableBox;
