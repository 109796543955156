import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomInstructionsDialog from "./CustomInstructionsDialog";
import DeleteChatDialog from "./DeleteChatDialog";
import DeleteHistoriesDialog from "./DeleteHistoriesDialog";

interface HistoryMenuProps {
  historyExists: boolean;
  chatCount: number;
}

export default function HistoryMenu({ historyExists, chatCount }: HistoryMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openHistoriesDelete, setOpenHistoriesDelete] = React.useState(false);
  const [openCustomInstructions, setOpenCustomInstructions] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenCustomInstructions = () => {
    handleClose();
    setOpenCustomInstructions(true);
  };

  const handleCloseCustomInstructions = () => {
    setOpenCustomInstructions(false);
  };

  const handleClickOpenHistoriesDelete = () => {
    handleClose();
    setOpenHistoriesDelete(true);
  };

  const handleCloseHistoriesDelete = () => {
    setOpenHistoriesDelete(false);
  };

  const handleClickOpenDelete = () => {
    handleClose();
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: 1,
          my: 1,
          position: "sticky",
          top: 0,
        }}
      >
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }} justifyContent={"flex-end"}>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleClickOpenCustomInstructions}>
              <SettingsSuggestIcon sx={{ mr: 2 }} fontSize="small" />
              {t("browsing.customize")}
            </MenuItem>
            <MenuItem onClick={handleClickOpenHistoriesDelete} disabled={!historyExists}>
              <ClearAllIcon sx={{ mr: 2 }} fontSize="small" />
              {t("browsing.clear")}
            </MenuItem>
            <MenuItem onClick={handleClickOpenDelete} sx={{ color: "error.main" }} disabled={chatCount <= 1}>
              <DeleteIcon sx={{ mr: 2 }} fontSize="small" />
              {t("browsing.delete")}
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Dialogs */}
      <DeleteChatDialog open={openDelete} onClose={handleCloseDelete} />
      <DeleteHistoriesDialog open={openHistoriesDelete} onClose={handleCloseHistoriesDelete} />
      <CustomInstructionsDialog open={openCustomInstructions} onClose={handleCloseCustomInstructions} />
    </>
  );
}
