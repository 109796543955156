/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Drawer, Fade, Hidden } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";

import * as React from "react";
import { useLocation } from "react-router-dom";
import { drawerWidth } from "../../../theme";
import AIList from "./AIList";
import Home from "./Home";
import LibraryList from "./LibraryList";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import ScrollableBox from "../../common/ScrollableBox";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  minHeight: "56px",
  height: "56px",
  backgroundColor: "transparent",
}));

export default function DrawerMain() {
  const theme = useTheme();
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawer = (open?: boolean) => {
    if (open !== undefined) {
      setDrawerOpen(open);
    } else {
      setDrawerOpen(!drawerOpen);
    }
  };

  // locationが変わり、スマホならドロワーを閉じる
  React.useEffect(() => {
    if (drawerOpen && window.innerWidth < theme.breakpoints.values.sm) {
      handleDrawer(false);
    }
  }, [location]);

  return (
    <>
      <Fade in={!drawerOpen} timeout={500}>
        <IconButton
          aria-label="open drawer"
          onMouseEnter={() => handleDrawer(true)}
          edge="start"
          sx={{
            color: theme.palette.text.primary,
            position: "fixed",
            bottom: { xs: 30 },
            left: { xs: 24 },
            backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.9),
            zIndex: 1300,
            width: { xs: 50 },
            height: { xs: 50 },
            transition: "all 0.5s ease-in-out",
            display: { md: "none" },
          }}
        >
          <MenuIcon fontSize="small" sx={{ display: { xs: "block", md: "none" } }} />
        </IconButton>
      </Fade>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: drawerWidth },
            boxSizing: "border-box",
            borderRight: "none",
            top: 56,
            height: "calc(100% - 56px)",
            backgroundColor: "transparent",
          },
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
          },
        }}
        variant={theme.breakpoints.values.sm < window.innerWidth ? "persistent" : "temporary"}
        anchor={theme.breakpoints.values.sm < window.innerWidth ? "left" : "bottom"}
        open={drawerOpen}
        color={"primary"}
        onMouseLeave={() => handleDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor:
              theme.palette.mode === "dark" ? theme.palette.background.custom1 : theme.palette.background.custom1,
            mx: { md: 2 },
            mb: { md: 2 },
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <Hidden mdUp>
            <IconButton
              onClick={() => handleDrawer(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: 20,
                zIndex: 1,
                color: theme.palette.text.primary,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Hidden>

          <ScrollableBox
            sx={{
              borderRadius: 0,
              overflowY: "auto",
              height: "100%",
              overflowX: "hidden",
              width: "100%",
              pt: 1,
              pb: 2,
            }}
          >
            <Home />
            <Divider sx={{ mx: 2, my: 2 }} />

            {/* AI */}
            <AIList />

            {/* Library */}
            <LibraryList />
          </ScrollableBox>

          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Drawer>
    </>
  );
}
