import React, { createContext, useContext, useState } from "react";

export const API_STATUS = {
  SUCCESS: 1,
  ERROR: 2,
  PROCESSING: 3,
} as const;

export type AiEngineList = "KLING" | "RUNWAY" | "LUMA" | "HAILUO" | "HAIPER";
export type MenuList = "t2v" | "i2v" | "v2v" | "library";

export type AiEngineNumber = Record<AiEngineList, number>;
export type AiEngineString = Record<AiEngineList, string>;
export type AspectRatio = "AUTO" | "1:1" | "16:9" | "4:3" | "3:4" | "9:16";
export type AspectRatios = Record<AiEngineList, AspectRatio>;
export type AspectRatioIconType = { ratio: AspectRatio; icon: React.ElementType; rotate?: string };
export type RunwayModel = "GEN3_ALPHA_TURBO";
export type LumaModel = "V1_5";
export type KlingModel = "V1_0_STANDARD" | "V1_0_PRO" | "V1_5_PRO" | "V1_6_STANDARD" | "V1_6_PRO";
export type HailuoModel = "MINI_MAX" | "MINI_MAX_LIVE" | "MINI_MAX_REF";
export type HaiperModel = "V2_0" | "V2_5";
export type ModelVersion = Record<AiEngineList, RunwayModel | LumaModel | KlingModel | HailuoModel | HaiperModel>;
export type KlingDuration = 5 | 10;
export type LumaDuration = 0;
export type RunwayDuration = 5 | 10;
export type HailuoDuration = 5;
export type HaiperDuration = 4 | 6;
export type Duration = Record<
  AiEngineList,
  KlingDuration | LumaDuration | RunwayDuration | HailuoDuration | HaiperDuration
>;

interface VideoContextType {
  fetchVideoTrigger: boolean;
  setFetchVideoTrigger: (fetchVideoTrigger: boolean) => void;
  prompt: string;
  setPrompt: (prompt: string) => void;
  menu: MenuList;
  setMenu: (menu: MenuList) => void;
  aiEngine: AiEngineList;
  setAiEngine: (aiEngine: AiEngineList) => void;
  processing: boolean;
  setProcessing: (processing: boolean) => void;
  image1: string;
  setImage1: (image: string) => void;
  image2: string;
  setImage2: (image: string) => void;
  modelVersion: ModelVersion;
  aspectRatios: AspectRatios;
  duration: Duration;
  lumaLoop: boolean;
  setLumaLoop: (loop: boolean) => void;
  updateParams: (
    aiEngine: AiEngineList,
    state: "modelVersion" | "aspectRatio" | "duration",
    value: string | number | number[]
  ) => void;
}

const VideoContext = createContext<VideoContextType>({
  fetchVideoTrigger: false,
  setFetchVideoTrigger: () => {},
  prompt: "",
  setPrompt: () => {},
  menu: "" as MenuList,
  setMenu: () => {},
  aiEngine: "" as AiEngineList,
  setAiEngine: () => {},
  processing: false,
  setProcessing: () => {},
  image1: "",
  setImage1: () => {},
  image2: "",
  setImage2: () => {},
  modelVersion: {} as ModelVersion,
  aspectRatios: {} as AspectRatios,
  duration: {} as Duration,
  lumaLoop: false,
  setLumaLoop: () => {},
  updateParams: () => {},
});

export const useVideo = () => useContext(VideoContext);

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fetchVideoTrigger, setFetchVideoTrigger] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [menu, setMenu] = useState<MenuList>("i2v");
  const [processing, setProcessing] = useState<boolean>(false);
  const [image1, setImage1] = useState<string>("");
  const [image2, setImage2] = useState<string>("");
  const [aiEngine, setAiEngine] = useState<AiEngineList>("LUMA");
  const [modelVersion, setModelVersion] = useState<ModelVersion>({
    KLING: "V1_6_PRO",
    RUNWAY: "GEN3_ALPHA_TURBO",
    LUMA: "V1_5",
    HAILUO: "MINI_MAX_LIVE",
    HAIPER: "V2_5",
  });
  const [aspectRatios, setAspectRatios] = useState<AspectRatios>({
    KLING: "16:9",
    RUNWAY: "16:9",
    LUMA: "16:9",
    HAILUO: "AUTO",
    HAIPER: "AUTO",
  });
  const [duration, setDuration] = useState<Duration>({
    KLING: 5,
    RUNWAY: 5,
    LUMA: 5,
    HAILUO: 5,
    HAIPER: 4,
  });
  const [lumaLoop, setLumaLoop] = useState<boolean>(false);

  const updateParams = (
    aiEngine: AiEngineList,
    state: "modelVersion" | "aspectRatio" | "duration",
    value: string | number | number[]
  ) => {
    switch (state) {
      case "modelVersion":
        setModelVersion((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "aspectRatio":
        setAspectRatios((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "duration":
        setDuration((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      default:
        break;
    }
  };

  return (
    <VideoContext.Provider
      value={{
        fetchVideoTrigger,
        setFetchVideoTrigger,
        prompt,
        setPrompt,
        menu,
        setMenu,
        aiEngine,
        setAiEngine,
        processing,
        image1,
        setImage1,
        image2,
        setImage2,
        setProcessing,
        modelVersion,
        aspectRatios,
        duration,
        lumaLoop,
        setLumaLoop,
        updateParams,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
