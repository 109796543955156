// AlertContext.js
import React, { createContext, useContext, useState } from "react";

const AlertContext = createContext({
  alertType: "info",
  alertMessage: "",
  showAlert: false,
  setAlert: (type: string, message: string) => {},
  closeAlert: () => {},
});

export const useAlert = () => useContext(AlertContext);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alertType, setAlertType] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const setAlert = (type: string, message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider value={{ alertType, alertMessage, showAlert, setAlert, closeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
