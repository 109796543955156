import DeleteIcon from "@mui/icons-material/Delete";
import { Divider, Grid, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ImageInfo } from "../../../../types/junoTypes";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { localDate } from "../../../../utils/utils";
import ActionLabels from "./ActionLabels";
import HistoryImageSlider from "./HistoryImageSlider";
import ImageModal from "./ImageModal";
import InputPrompt from "./InputPrompt";
import { useCommonContext } from "../../../../context/juno/CommonContext";
import { useJunoMethods } from "../../../../hooks/useJunoMethods";
import HistoryParams from "./HistoryParams";
import HistoryActions from "./HistoryActions";
import ScrollableBox from "../../../../components/common/ScrollableBox";

export default function History() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { fetchHistory, fetchQueuedImages } = useJunoMethods();

  const { refreshTrigger, setRefreshTrigger } = useCommonContext();

  const [requests, setRequests] = useState<[ImageInfo][]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const [selectedImageUuid, setSelectedImageUuid] = useState<string | null>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();
  const uiLanguage = Cookies.get("ui_language") || "en";

  // 画像一覧取得
  const fetchAndSetImageList = async (scroll = true) => {
    try {
      const json = await fetchHistory();
      let flat_json = json.flat();
      flat_json = flat_json.filter((image) => image.request_status === "Success");
      setModalImages(flat_json);
      setRequests(json);
      if (scroll) {
        scrollToBottom();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // modalImagesの何番目にあるかさがす
    if (selectedImageUuid) {
      const index = modalImages.findIndex((image) => image.uuid === selectedImageUuid);
      setModalActiveIndex(index);
    }
  }, [openModal]);

  // マウント時、画像一覧取得
  useEffect(() => {
    fetchAndSetImageList();
  }, [refreshTrigger]);

  useEffect(() => {
    // 15秒後に8秒毎のFetch
    let intervalId: number | null = null;
    const startTimers = () => {
      intervalId = window.setInterval(() => {
        let isProcessing = false;

        requests.forEach((request) => {
          if (request[0].request_status === "Processing") {
            isProcessing = true;
          }
        });

        if (isProcessing) {
          setRefreshTrigger(new Date().getTime());
          dispatch(setCreditTrigger(true));
        }
      }, 8000);
    };

    const timeoutId = setTimeout(startTimers, 15000);
    return () => {
      clearTimeout(timeoutId);
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [requests, refreshTrigger]);

  // リクエストヒストリー削除処理
  const handleConfirmedDelete = async (request_uuid: string) => {
    try {
      const url = "/api/v1/juno/generated-images";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      fetch(url, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify({ uuid: request_uuid }),
      });

      // リストからも削除
      const newList = requests.filter((item) => {
        return item[0].request_uuid !== request_uuid;
      });
      setRequests(newList);
    } catch (e) {
      console.error(e);
    }
  };

  // 削除確認ダイアログを開く
  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  // 読み込み時に一番下までスクロール.
  const scrollToBottom = () => {
    setTimeout(() => {
      const container = document.getElementById("history-container");
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth", // スクロールを滑らかにする
        });
      }
    }, 100);
  };

  const [activeIndexes, setActiveIndexes] = useState<{ [request_uuid: string]: number }>({});
  const updateActiveIndex = (request_uuid: string, newIndex: number) => {
    setActiveIndexes((prev) => ({ ...prev, [request_uuid]: newIndex }));
  };

  const [modalImages, setModalImages] = React.useState<ImageInfo[]>([]);
  const [modalActiveIndex, setModalActiveIndex] = React.useState(0);

  const rowNums = (length: number, action) => {
    if (action === "img2img" || action === "txt2img") {
      return length > 1 ? 3 : 3;
    } else {
      return length > 1 ? 5 : 3;
    }
  };

  return (
    <>
      <Paper sx={{ height: "100%" }} elevation={theme.palette.mode === "dark" ? 1 : 0}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography variant={"subtitle2"} component={"h3"}>
              {t("juno.history.title")}
            </Typography>
          </Box>
          <Divider />
          <ScrollableBox id={"history-container"} sx={{ overflowY: "auto", height: "100%" }}>
            {requests.map((request, index) => {
              const params = request[0].params;
              return (
                <Box key={index} my={2} px={2}>
                  {index !== 0 && <Divider sx={{ my: 3 }} />}
                  <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    {/* Slider */}
                    <Grid
                      item
                      md={2.5}
                      xs={12}
                      // sx={{ height: document.getElementById(`history-${index}`)?.clientHeight }}
                    >
                      <HistoryImageSlider
                        images={request as ImageInfo[]}
                        activeIndex={activeIndexes[request[0]["request_uuid"]] || 0}
                        setActiveIndex={(newIndex) => updateActiveIndex(request[0]["request_uuid"], newIndex)}
                        setOpenModal={setOpenModal}
                        setSelectedImageUuid={setSelectedImageUuid}
                      />
                    </Grid>

                    {/* 画像情報 */}
                    <Grid
                      item
                      md={9.5}
                      xs={12}
                      sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 3 }}
                      id={`history-${index}`}
                    >
                      <Box sx={{ display: "flex", flexDirection: "row", overflow: "auto" }} gap={1}>
                        <ActionLabels params={params} />
                        <Box flexGrow={1} />
                        <Box>
                          <Typography variant={"body2"} component={"p"} sx={{ py: 1, whiteSpace: "nowrap" }}>
                            {request[0]["created_at"] && localDate(request[0]["created_at"], uiLanguage)}
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title={t("common.delete")} placement={"top"}>
                            <span>
                              <IconButton
                                color={"error"}
                                disabled={request[0].request_status === "Processing"}
                                onClick={() => handleDialogOpen(request[0].request_uuid)}
                              >
                                <DeleteIcon fontSize={"small"} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>

                      {/* プロンプト */}
                      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }} gap={1}>
                        {params["prompt"] && (
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <TextField
                              label={t("juno.history.prompt")}
                              fullWidth
                              multiline
                              rows={rowNums(request.length, params["action"])}
                              value={params["prompt"]}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        )}

                        {/* ネガティブプロンプト */}
                        {params["negative_prompt"] && (
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <TextField
                              label={t("juno.input.negativePromptHistory")}
                              fullWidth
                              multiline
                              rows={rowNums(request.length, params["action"])}
                              value={params["negative_prompt"]}
                              InputProps={{ readOnly: true }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Box>
                        )}
                      </Box>

                      {/* 一行に表示するパラメータ */}
                      <HistoryParams params={params} grid={2.4} />

                      {/* アクション */}
                      <Grid container spacing={1}>
                        {/*<Box flexGrow={1}/>*/}
                        <HistoryActions
                          params={params}
                          grid={2}
                          request={request}
                          targetIndex={activeIndexes[request[0]["request_uuid"]] ?? 0}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </ScrollableBox>

          {/* 削除確認ダイアログ */}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} disableEnforceFocus>
            <DialogTitle>{t("juno.history.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("juno.history.delete.description")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
                {t("common.cancel")}
              </Button>
              <Button
                onClick={() => {
                  handleConfirmedDelete(selectedUuid!);
                  setDialogOpen(false);
                }}
                color="error"
              >
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>
          <Box flexGrow={1} />
          <InputPrompt fetchAndSetImageList={fetchAndSetImageList} />
        </Box>
      </Paper>

      {openModal && (
        <ImageModal
          images={modalImages}
          activeIndex={modalActiveIndex}
          setActiveIndex={setModalActiveIndex}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
    </>
  );
}
