import GoogleIcon from "@mui/icons-material/Google";
import PublicIcon from "@mui/icons-material/Public";
import { Box, Fade, Grid, Paper, Typography, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ScrollableBox from "../../../components/common/ScrollableBox";
import { RowCenteredBox } from "../../../utils/styledBox";

interface SearchResult {
  title: string;
  snippet: string;
  snippet_highlighted_words: string[];
  url: string;
  displayed_link: string;
  favicon: string;
  google_url: string;
  keyword: string;
}

interface SearchContent {
  organic_results: SearchResult[];
  google_url: string;
  keyword: string;
}

const SearchBox = memo(({ content, aiStatus }: { content: SearchContent; aiStatus: string | null }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const hoverStyles = {
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-5px)",
      opacity: 0.9,
    },
  };

  return (
    <>
      <RowCenteredBox sx={{ my: 2 }}>
        <GoogleIcon sx={{ mr: 2 }} fontSize={"small"} color={"success"} />
        <Typography
          variant="subtitle1"
          color="textSecondary"
          fontWeight={800}
          sx={{ maxWidth: 400, textAlign: "center" }}
        >
          {t("browsing.sidebar.preSearchTitle")}
        </Typography>
      </RowCenteredBox>

      <ScrollableBox sx={{ borderRadius: "0 0 4px 4px" }}>
        {content ? (
          <Grid container spacing={2} sx={{ mb: 2, px: 2 }}>
            {content[0]?.organic_results ? (
              content[0].organic_results.map((result, index) => (
                <Grid item xs={6} key={index}>
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: "4px",
                      minWidth: { xs: "80%", sm: "10px" },
                      ...hoverStyles,
                      backgroundColor: "background.custom1",
                    }}
                  >
                    <Link
                      to={result.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: theme.palette.text.secondary,
                        textDecoration: "none",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                          color: theme.palette.text.secondary,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "linear-gradient(115deg, rgba(73, 56, 186,1) 0%, rgba(206, 63, 235,1) 100%)",
                          borderRadius: "4px 4px 0 0",
                          height: 80,
                        }}
                      >
                        {result.favicon ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundImage: `url(${result.favicon})`,
                              backgroundSize: "cover",
                              height: 80,
                              width: "100%",
                              borderRadius: "4px 0 0 0",
                              backgroundPosition: "center",
                            }}
                          />
                        ) : (
                          <PublicIcon sx={{ fontSize: 40, color: "white" }} />
                        )}
                      </Box>
                      <Box p={1} gap={1} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "white",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            minHeight: 40,
                          }}
                          dangerouslySetInnerHTML={{ __html: result.title }}
                        />

                        <Box sx={{ maxHeight: 200, overflowY: "hidden" }}>
                          <Typography
                            variant="caption"
                            style={{
                              overflowWrap: "break-word",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              overflow: "hidden",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: result.snippet,
                            }}
                          />
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "right" }}>
                          <Typography
                            variant="caption"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {result.displayed_link}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Fade in={true} timeout={1000}>
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", m: 0, p: 1 }}>
                  <Typography variant="body1">{t("browsing.error.search")}</Typography>
                </Box>
              </Fade>
            )}
          </Grid>
        ) : aiStatus === "searching" || aiStatus == "reading" ? (
          <Grid container spacing={2} sx={{ mb: 2, px: 2 }}>
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <Grid item xs={6} key={index}>
                  <Paper
                    key={index}
                    sx={{ borderRadius: "4px", minWidth: { xs: "100%" }, ...hoverStyles }}
                    elevation={0}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                      sx={{
                        color: theme.palette.text.secondary,
                        height: "56px",
                        width: "100%",
                        background: "linear-gradient(115deg, rgba(73, 56, 186,1) 0%, rgba(206, 63, 235,1) 100%)",
                        borderRadius: "4px 4px 0 0",
                        p: 2,
                      }}
                    >
                      <Skeleton variant="rounded" width={"100%"} height={28} />
                    </Box>
                    <Skeleton variant="rounded" sx={{ minWidth: { xs: "100%" }, height: "120px" }} />
                  </Paper>
                </Grid>
              ))}
          </Grid>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%", m: 0, p: 2 }}>
            <Typography variant="body1">No source found</Typography>
          </Box>
        )}
      </ScrollableBox>
    </>
  );
});

SearchBox.displayName = "SearchBox";

export default SearchBox;
