// SearchBox.tsx
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase } from "../../../utils/searchBox";

const SearchBox: React.FC<{ templates: any[]; onSearch: (results: any) => void }> = ({ templates, onSearch }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const navigate = useCustomNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get("q") || "";

    if (q) {
      setQuery(q);
      handleSearch(q);
    }
  }, [location.search, templates]);

  const handleSearch = (searchQuery: string) => {
    const filteredTemplates = templates.filter((template) => {
      const title = t(`template.label.${template.id}.title`).toLowerCase();
      const description = t(`template.label.${template.id}.description`).toLowerCase();
      const searchLower = searchQuery.toLowerCase();
      return title.includes(searchLower) || description.includes(searchLower);
    });
    onSearch(filteredTemplates);
  };

  const handleSubmit = () => {
    navigate(`/template?q=${encodeURIComponent(query)}`);
  };

  return (
    <ColumnCenteredBox mb={4}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        />
      </Search>
    </ColumnCenteredBox>
  );
};

export default SearchBox;
