// トリガーが必要な場合に使用するSlice
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TriggerState = {
  drawerChatTrigger: boolean;
  drawerCreditTrigger: boolean;
};

const initialState: TriggerState = {
  drawerChatTrigger: false,
  drawerCreditTrigger: false,
};

const triggerSlice = createSlice({
  name: "trigger",
  initialState,
  reducers: {
    setChatTrigger: (state, action: PayloadAction<boolean>) => {
      state.drawerChatTrigger = action.payload;
    },
    setCreditTrigger: (state, action: PayloadAction<boolean>) => {
      state.drawerCreditTrigger = action.payload;
    },
  },
});

export const { setChatTrigger, setCreditTrigger } = triggerSlice.actions;
export const triggerReducer = triggerSlice.reducer;
