// ParamsContext.tsx
import React, { createContext, useContext, useState } from "react";
import { AiEngineList, AiEngineNumber, AspectRatios, RecraftColors, Style } from "../../types/junoTypes";

type ParamsContextType = {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  negativePrompt: string;
  setNegativePrompt: React.Dispatch<React.SetStateAction<string>>;
  revisedPrompt: boolean;
  setRevisedPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  style: Style;
  setStyle: React.Dispatch<React.SetStateAction<Style>>;
  styleWeight: AiEngineNumber;
  setStyleWeight: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  width: AiEngineNumber;
  setWidthPartial: (key: AiEngineList, value: number) => void;
  height: AiEngineNumber;
  setHeightPartial: (key: AiEngineList, value: number) => void;
  aspectRatio: AspectRatios;
  setAspectRatio: React.Dispatch<React.SetStateAction<AspectRatios>>;
  guidanceScale: AiEngineNumber;
  setGuidanceScale: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  step: AiEngineNumber;
  setStep: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  sample: AiEngineNumber;
  setSample: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  seed: AiEngineNumber;
  setSeed: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  colorsRecraft: RecraftColors[];
  setColorsRecraft: React.Dispatch<React.SetStateAction<RecraftColors[]>>;
  rawMode: boolean;
  setRawMode: React.Dispatch<React.SetStateAction<boolean>>;
  sampleLimit: AiEngineNumber;
};

const ParamsContext = createContext<ParamsContextType | undefined>(undefined);

export const ParamsProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [prompt, setPrompt] = useState<string>("");
  const [negativePrompt, setNegativePrompt] = useState<string>("");
  const [revisedPrompt, setRevisedPrompt] = useState<boolean>(true);
  const [style, setStyle] = useState<Style>({
    DallE3: "vivid",
    Ideogram2: "GENERAL",
    Ideogram2Turbo: "GENERAL",
    Ideogram2Edit: "GENERAL",
    Ideogram2TurboEdit: "GENERAL",
    FluxJuno: "ANIME",
    Flux1DevCannyLoRA: "GENERAL",
    Flux1DevDepthLoRA: "GENERAL",
    Flux1DevFill: "GENERAL",
    RecraftV3: "any",
  });
  const [styleWeight, setStyleWeight] = useState<AiEngineNumber>({
    FluxJuno: 0.8,
    Flux1DevCannyLoRA: 0.8,
    Flux1DevDepthLoRA: 0.8,
    Flux1DevFill: 0.8,
  });

  const [width, setWidth] = useState<AiEngineNumber>({
    DallE3: 1024,
  });
  const [height, setHeight] = useState<AiEngineNumber>({
    DallE3: 1024,
  });
  const [aspectRatio, setAspectRatio] = useState<AspectRatios>({
    SD35: "1:1",
    SDUltra: "1:1",
    Flux11ProUltra: "1:1",
    Flux11Pro: "1:1",
    Flux1Dev: "1:1",
    Flux1Schnell: "1:1",
    Flux1RealismLoRA: "1:1",
    FluxJuno: "1:1",
    FluxPuLID: "1:1",
    Flux11ProUltraRedux: "1:1",
    Flux11ProRedux: "1:1",
    Flux1ProCanny: "1:1",
    Flux1ProDepth: "1:1",
    Flux1DevRedux: "1:1",
    Flux1DevCannyLoRA: "1:1",
    Flux1DevDepthLoRA: "1:1",
    Flux1SchnellRedux: "1:1",
    Flux1ProFill: "1:1",
    Flux1DevFill: "1:1",
    RecraftV3: "1:1",
    Ideogram2: "1:1",
    Ideogram2Turbo: "1:1",
    Ideogram2Edit: "1:1",
    Ideogram2TurboEdit: "1:1",
  });
  const [guidanceScale, setGuidanceScale] = useState<AiEngineNumber>({
    Flux1Dev: 3.5,
    Flux1Schnell: 3.5,
    Flux1RealismLoRA: 3.5,
    FluxJuno: 3.5,
    FluxPuLID: 4,
    Flux11ProUltraRedux: 3.5,
    Flux11ProRedux: 3.5,
    Flux1ProCanny: 3.5,
    Flux1ProDepth: 3.5,
    Flux1DevRedux: 3.5,
    Flux1DevCannyLoRA: 30,
    Flux1DevDepthLoRA: 10,
    Flux1SchnellRedux: 3.5,
    Flux1DevFill: 3.5,
    // Flux1ProFill: 3.5,
  });
  const [step, setStep] = useState<AiEngineNumber>({
    Flux1Dev: 28,
    Flux1Schnell: 4,
    Flux1RealismLoRA: 28,
    FluxJuno: 28,
    FluxPuLID: 20,
    Flux1ProCanny: 28,
    Flux1ProDepth: 28,
    Flux1DevRedux: 28,
    Flux1DevCannyLoRA: 28,
    Flux1DevDepthLoRA: 28,
    Flux1SchnellRedux: 28,
    Flux1DevFill: 28,
  });
  const [sample, setSample] = useState<AiEngineNumber>({
    DallE3: 1,
    SD35: 1,
    SDUltra: 1,
    Flux11ProUltra: 1,
    Flux11Pro: 1,
    Flux1Dev: 1,
    Flux1Schnell: 1,
    Flux1RealismLoRA: 1,
    FluxJuno: 1,
    FluxPuLID: 1,
    Flux11ProUltraRedux: 1,
    Flux11ProRedux: 1,
    Flux1ProCanny: 1,
    Flux1ProDepth: 1,
    Flux1DevRedux: 1,
    Flux1DevCannyLoRA: 1,
    Flux1DevDepthLoRA: 1,
    Flux1SchnellRedux: 1,
    Flux1ProFill: 1,
    Flux1DevFill: 1,
    Ideogram2: 1,
    Ideogram2Turbo: 1,
    Ideogram2Edit: 1,
    Ideogram2TurboEdit: 1,
  });
  const [seed, setSeed] = useState<AiEngineNumber>({
    SD35: 0,
    SDUltra: 0,
    Flux11ProUltra: 0,
    Flux11Pro: 0,
    Flux1Dev: 0,
    Flux1Schnell: 0,
    Flux1RealismLoRA: 0,
    FluxJuno: 0,
    FluxPuLID: 0,
    Ideogram2: 0,
    Ideogram2Turbo: 0,
    Ideogram2Edit: 0,
    Ideogram2TurboEdit: 0,
    Flux11ProUltraRedux: 0,
    Flux11ProRedux: 0,
    Flux1ProCanny: 0,
    Flux1ProDepth: 0,
    Flux1DevRedux: 0,
    Flux1DevCannyLoRA: 0,
    Flux1DevDepthLoRA: 0,
    Flux1SchnellRedux: 0,
    Flux1ProFill: 0,
    Flux1DevFill: 0,
  });
  const [isPublic, setIsPublic] = useState<boolean>(true);

  const setWidthPartial = (key: AiEngineList, value: number) => {
    setWidth((prev) => ({ ...prev, [key]: value }));
  };
  const setHeightPartial = (key: AiEngineList, value: number) => {
    setHeight((prev) => ({ ...prev, [key]: value }));
  };

  const [colorsRecraft, setColorsRecraft] = useState<RecraftColors[]>([]);
  const [rawMode, setRawMode] = useState<boolean>(false);

  const [sampleLimit] = useState<AiEngineNumber>({
    SD35: 1,
    SDUltra: 1,
    Flux11ProUltra: 1,
    Flux11Pro: 1,
    Flux1Dev: 4,
    Flux1Schnell: 4,
    Flux1RealismLoRA: 1,
    FluxJuno: 4,
    FluxPuLID: 1,
    Ideogram2: 4,
    Ideogram2Turbo: 4,
    Flux11ProUltraRedux: 1,
    Flux11ProRedux: 1,
    Flux1ProCanny: 1,
    Flux1ProDepth: 1,
    Flux1DevRedux: 1,
    Flux1DevCannyLoRA: 4,
    Flux1DevDepthLoRA: 4,
    Flux1SchnellRedux: 4,
    Flux1ProFill: 1,
    Flux1DevFill: 4,
  });

  return (
    <ParamsContext.Provider
      value={{
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        revisedPrompt,
        setRevisedPrompt,
        style,
        setStyle,
        styleWeight,
        setStyleWeight,
        width,
        setWidthPartial,
        height,
        setHeightPartial,
        aspectRatio,
        setAspectRatio,
        guidanceScale,
        setGuidanceScale,
        step,
        setStep,
        sample,
        setSample,
        seed,
        setSeed,
        isPublic,
        setIsPublic,
        colorsRecraft,
        setColorsRecraft,
        rawMode,
        setRawMode,
        sampleLimit,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
};

export const useParamsContext = (): ParamsContextType => {
  const context = useContext(ParamsContext);
  if (!context) {
    throw new Error("useParamsContext must be used within a ParamsProvider");
  }
  return context;
};
