const dashboard = {
  title: "ダッシュボード",
  greeting: "こんにちは、{{name}}。",
  welcome: "ようこそ、Tenak AIへ。",
  search: "調べる、会話する",
  addChat: "チャット作成",
  text: "文書を作成する",
  addText: "白紙の文書",
  addMindmap: "白紙のマインドマップ",
  image: "STUDIO JUNO - 画像・動画生成",
  mindmap: "マインドマップ / ブレインストーミングを作る",
  voice: "音声生成",
  notesNebula: "記事投稿\nプラットフォーム",
  misc: "MISC AI",
  library: "ライブラリ",
};
export default dashboard;
