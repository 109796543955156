import ArticleIcon from "@mui/icons-material/Article";
import { Box, Drawer, List, ListItem, ListItemText, Typography } from "@mui/material";

interface RagSource {
  title: string;
  relevance: number;
  snippet: string;
}

interface RagSourcesProps {
  sources: RagSource[];
  drawerWidth: number;
}

const RagSources = ({ sources = [], drawerWidth }: RagSourcesProps) => {
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          top: 64,
          height: "calc(100vh - 64px)",
        },
      }}
    >
      <Box sx={{ overflow: "auto", p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          情報ソース
        </Typography>
        <List>
          {sources.length > 0 ? (
            sources.map((source, index) => (
              <ListItem
                key={index}
                sx={{
                  py: 1,
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "&:last-child": { borderBottom: "none" },
                }}
              >
                <ArticleIcon sx={{ mr: 2 }} />
                <ListItemText
                  primary={source.title}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.secondary" sx={{ display: "block" }}>
                        関連度: {source.relevance}%
                      </Typography>
                      <Typography component="span" variant="body2" sx={{ display: "block" }}>
                        {source.snippet}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="関連する情報ソースはありません" />
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default RagSources;
