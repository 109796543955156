const pricing = {
  title: "プランと価格",
  pricing: "PRICING",
  description: "Tenak AIのプランと価格",
  legalNotice: "特定商取引法に基づく表記",
  free: {
    title: "Free",
    description: "無料プラン",
    price: "0",
    credit: "50",
  },
  basic: {
    title: "Basic",
    description: "ベーシックプラン",
    price: "18",
    credit: "900",
  },
  standard: {
    title: "Standard",
    description: "スタンダードプラン",
    price: "36",
    credit: "1,800 + 200",
  },
  premium: {
    title: "Premium",
    description: "プレミアムプラン",
    price: "72",
    credit: "3,600 + 600",
  },
  perMonth: "月額",
  credit: "クレジット",
  dailyCredit: "クレジット（デイリーボーナス）",
  aiEngine: "パワフルAIエンジン",
  juno: "STUDIO JUNO（画像生成）",
  junoPrivate: "生成画像の非公開設定",
  junoEngine: "全ての画像生成AIエンジン",
  junoEnginePart: "一部の画像生成AIエンジン",
  junoVideo: "動画生成",
  mindmap: "マインドマップ生成",
  export: "マインドマップのエクスポート",
  template: "全てのAIテンプレート",
  textEditor: "AIテキストエディター",
  voice: "音声生成",
  support: "サポート",
  supportPriority: "優先サポート",
  supportNo: "サポートなし",
  subscribe: "購入する",
  aboutCredit: {
    title: "クレジットについて",
    description:
      "Tenak AIのプランは、クレジット制を採用しています。クレジットは、文字、画像、音声生成などのAI機能を利用するための通貨です。以下に目安の消費クレジットを記載しています。",
    text: {
      title: "テキスト生成",
      "openai-gpt4o-input": "知能優先モード（入力）",
      "openai-gpt4o-output": "知能優先モード（出力）",
      "openai-gpt4o-mini-input": "速度優先モード（入力）",
      "openai-gpt4o-mini-output": "速度優先モード（出力）",
      note: "NOTE: システムプロンプトやWeb検索時は内部で使用されたトークン加算されます",
    },
    image: {
      title: "画像生成",
      "openai-dalle3-hd": "OPEN AI: DALL-E 3",
      sdapi: "Stable Diffusion (XL)",
      sd3: "Stable Diffusion 3.5",
      "sd-ultra": "Stable Image Ultra",
      "flux11-pro-ultra": "Flux 1.1 Pro Ultra",
      "flux11-pro-ultra-redux": "Flux 1.1 Pro Ultra Redux",
      "flux11-pro": "Flux 1.1 Pro",
      "flux1-pro-fill": "Flux.1 Pro Fill",
      "flux11-pro-redux": "Flux 1 Pro Redux",
      "flux1-pro-canny": "Flux 1 Pro Canny",
      "flux1-pro-depth": "Flux 1 Pro Depth",
      "flux1-dev": "Flux.1 Dev",
      "flux1-dev-redux": "Flux 1 Dev Redux",
      "flux1-dev-fill": "Flux.1 Dev Fill",
      "flux1-dev-canny-lora": "Flux 1 Dev Canny",
      "flux1-dev-depth-lora": "Flux 1 Dev Depth",
      "flux1-schnell": "Flux.1 Schnell",
      "flux1-schnell-redux": "Flux 1 Schnell Redux",
      "flux1-realism-lora": "Flux.1 with Realism LoRA",
      "flux-juno": "Flux.1 with STUDIO JUNO",
      "flux-palid": "Flux PuLID",
      "recraft-v3": "Recraft V3",
      "kolors-vto": "Kolors Vitrual Try-On",
      ideogram2: "Ideogram 2.0",
      "ideogram2-turbo": "Ideogram 2.0 Turbo",
      "ideogram2-edit": "Ideogram 2.0 Edit",
      "ideogram2-turbo-edit": "Ideogram 2.0 Turbo Edit",
      "fal-aura-sr": "Upscaler AuraSR",
      "fal-clarity-upscaler": "Upscaler Clarity Upscaler",
      "fal-creative-upscaler": "Upscaler Creative Upscaler",
      "fal-recraft-creative-upscaler": "Recraft Creative Upscaler",
      "fal-recraft-clarity-upscaler": "Recraft Clarity Upscaler",
    },
    video: {
      title: "動画生成",
      "runway-gen3a-turbo": "Runway Gen3 Alpha Turbo",
      "fal-kling-v1.0-standard": "Kling v1.0 Standard",
      "fal-kling-v1.6-standard": "Kling v1.6 Standard",
      "fal-kling-v1.0-pro": "Kling v1.0 Pro",
      "fal-kling-v1.5-pro": "Kling v1.5 Pro",
      "fal-kling-v1.6-pro": "Kling v1.6 Pro",
      "fal-luma-dream-machine-v1.5": "Dream Machine v1.5",
      "fal-hailuo-mini-max": "Mini Max",
      "fal-haiper-v2.0": "Haiper v2.0",
      "fal-haiper-v2.5": "Haiper v2.5",
    },
    voice: {
      title: "音声生成",
      "google-tts": "Google TTS（1,000文字）",
      "openai-tts-hd": "OPEN AI TTS（1,000トークン）",
      "openai-tts-hd-advanced": "OPEN AI Advanced Voice Mode（1,000トークン）",
      note: "NOTE: OPEN AI Advanced Voice Modeは、チャットモデルを使用した音声生成のモードのため、クレジット数が前後します",
    },
    mindmap: {
      title: "マインドマップ生成",
      description: "AIを使用しない場合（AIジェネレーター使用時はテキスト生成に依存）",
    },
    annotation:
      "* この表は目安であり、AIエンジンのアップデートにより予告なしに変更される可能性があります。 * 小数点第二位以下は切り上げて計算されます。",
  },
  faq: {
    title: "よくある質問",
    description: "Tenak AIのプランと価格に関するよくある質問",
    q1: "クレジットは繰り越せますか？",
    a1: "いいえ。クレジットは繰り越せません。",
    q2: "アップグレードしたら料金は日割り計算されますか？",
    a2: "いいえ。クレジット制を導入しているため、全額請求されます。そして、お持ちのクレジットと新しいプランのクレジットが合算され、その日から1ヶ月後がクレジット失効日かつ次回請求日となります。",
    q3: "クレジットだけ購入することはできますか？",
    a3: "いいえ。クレジットはプランに含まれており、プランを購入することでクレジットが付与されます。",
    q4: "プランを解約した場合、クレジットはどうなりますか？",
    a4: "プランを解約した場合、その更新月でクレジットは失効します。",
    q5: "クレジットが足りなくなった場合、どうすればいいですか？",
    a5: "クレジットが足りなくなった場合、プランをアップグレードしてください。",
  },
};
export default pricing;
