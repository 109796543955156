// utils/api.ts
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import Cookies from "js-cookie";

export const apiRequest = async <T = any>(
  url: string,
  method: Method = "GET",
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  try {
    const csrfToken = Cookies.get("csrftoken");
    const response: AxiosResponse<T> = await axios({
      url,
      method,
      data,
      headers: {
        "X-CSRFToken": csrfToken,
        ...config?.headers,
      },
      ...config,
    });

    return response;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
