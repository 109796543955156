import { Circle, Image as KonvaImage, Layer, Line, Rect, Stage, Transformer } from "react-konva";
import { ColumnCenteredBox } from "../../../../../utils/styledBox";
import React, { useEffect, useRef, useState } from "react";
import Konva from "konva";
import { ImageObject, LineData } from "../../../../../types/junoTypes";

interface StageProps {
  stageWidth: number;
  stageHeight: number;
  images: ImageObject[];
  setImages: React.Dispatch<React.SetStateAction<ImageObject[]>>;
  selectedImageId: string | null;
  setSelectedImageId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTool: string;
  brushSize: number;
  stageRef: React.RefObject<Konva.Stage>;
  lines: LineData[];
  setLines: React.Dispatch<React.SetStateAction<LineData[]>>;
}

const EditorStage = ({
  stageWidth,
  stageHeight,
  images,
  setImages,
  selectedImageId,
  setSelectedImageId,
  selectedTool,
  brushSize,
  stageRef,
  lines,
  setLines,
}: StageProps) => {
  const isEraser = selectedTool === "eraser";
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [pointerPos, setPointerPos] = useState<{ x: number; y: number } | null>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const vh90 = (window.innerHeight * 90) / 100;
  const stageScale = vh90 > 1150 ? 1 : vh90 / 1150;
  const SNAP_THRESHOLD = 10;
  const [snapLines, setSnapLines] = useState<{ horizontal?: number; vertical?: number } | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  // ポインターの座標を取得
  useEffect(() => {
    const stage = stageRef.current;
    const tr = transformerRef.current;
    if (tr && stage && selectedImageId) {
      const selectedNode = stage.findOne(`#${selectedImageId}`);
      if (selectedNode && selectedTool === "select") {
        tr.nodes([selectedNode as Konva.Image]);
        tr.getLayer()?.batchDraw();
      } else {
        tr.nodes([]);
      }
    } else if (tr) {
      tr.nodes([]);
    }
  }, [selectedImageId, images, selectedTool]);

  // Deleteキーで画像の削除
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (selectedImageId && (e.key === "Delete" || e.key === "Backspace")) {
        setImages((prev) => prev.filter((image) => image.id !== selectedImageId));
        setSelectedImageId(null); // 削除後、選択解除
      }
    };

    // イベントリスナーを追加
    window.addEventListener("keydown", handleKeyDown);

    // クリーンアップ時にリスナーを削除
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImageId]);

  const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (selectedTool !== "brush" && selectedTool !== "eraser") return;
    const pos = getTransformedPointerPosition();
    if (!pos) return;

    setIsDrawing(true);
    setLines((prev) => [
      ...prev,
      {
        points: [pos.x, pos.y],
        globalCompositeOperation: isEraser ? "destination-out" : "source-over",
        strokeWidth: brushSize / stageScale, // スケール補正
        stroke: isEraser ? "black" : "red",
        opacity: isEraser ? 1 : 0.5,
      },
    ]);
  };

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const point = getTransformedPointerPosition();
    if (point) {
      setPointerPos({ x: point.x, y: point.y });
    }

    if ((selectedTool !== "brush" && selectedTool !== "eraser") || !isDrawing || !lines.length) return;

    const lastLine = lines[lines.length - 1];
    if (!lastLine || !point) return;
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    setLines((prev) => {
      const newLines = [...prev];
      newLines[newLines.length - 1] = lastLine;
      return newLines;
    });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleSelectImage = (id: string) => {
    if (selectedTool === "select") {
      setSelectedImageId(id);
    }
  };

  const handleTransformEnd = (id: string, e: Konva.KonvaEventObject<Event>) => {
    const node = e.target as Konva.Image;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const { x, y } = node.position();
    setImages((prev) => prev.map((img) => (img.id === id ? { ...img, x, y, scaleX, scaleY } : img)));
  };

  // ポインタ位置の取得
  const getTransformedPointerPosition = () => {
    const stage = stageRef.current;
    if (!stage) return null;

    const pointer = stage.getPointerPosition();
    if (!pointer) return null;

    const scale = stage.scaleX(); // ステージのスケール（XとYは同じ値）
    return { x: pointer.x / scale, y: pointer.y / scale }; // スケール補正後の座標
  };

  // 選択された画像にクロスラインを表示
  const selectedImageData = images.find((img) => img.id === selectedImageId);
  let crossLines: JSX.Element | null = null;
  if (selectedImageData && selectedTool === "select") {
    const img = selectedImageData;
    const centerX = img.x + (img.width * img.scaleX) / 2;
    const centerY = img.y + (img.height * img.scaleY) / 2;
    crossLines = (
      <>
        <Line
          points={[centerX - (img.width * img.scaleX) / 2, centerY, centerX + (img.width * img.scaleX) / 2, centerY]}
          stroke="#00A3AA"
          dash={[3, 3]}
        />
        <Line
          points={[centerX, centerY - (img.height * img.scaleY) / 2, centerX, centerY + (img.height * img.scaleY) / 2]}
          stroke="#00A3AA"
          dash={[3, 3]}
        />
      </>
    );
  }

  // ドラッグ中を追跡
  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (e: Konva.KonvaEventObject<DragEvent>, id: string) => {
    setIsDragging(false); // ドラッグ終了時
    setSnapLines(null); // スナップラインをクリア

    const node = e.target;
    const { x, y } = node.position();
    setImages((prev) => prev.map((img) => (img.id === id ? { ...img, x, y } : img)));
  };

  const handleDragMove = (e: Konva.KonvaEventObject<DragEvent>, img: ImageObject) => {
    const node = e.target;
    const stage = stageRef.current;
    if (!stage) return;

    const stageCenterX = stage.width() / 2;
    const stageCenterY = stage.height() / 2;

    const imgWidth = img.width * img.scaleX;
    const imgHeight = img.height * img.scaleY;

    const imgLeft = node.x();
    const imgRight = node.x() + imgWidth;
    const imgTop = node.y();
    const imgBottom = node.y() + imgHeight;

    const imgCenterX = imgLeft + imgWidth / 2;
    const imgCenterY = imgTop + imgHeight / 2;

    let newX = node.x();
    let newY = node.y();
    let snapX: number | undefined;
    let snapY: number | undefined;

    const snappingPointsX: number[] = [];
    const snappingPointsY: number[] = [];

    // Stage snapping points
    snappingPointsX.push(stageCenterX, 0, stage.width());
    snappingPointsY.push(stageCenterY, 0, stage.height());

    // Other images' snapping points
    images.forEach((otherImg) => {
      if (otherImg.id !== img.id) {
        const otherWidth = otherImg.width * otherImg.scaleX;
        const otherHeight = otherImg.height * otherImg.scaleY;
        const otherLeft = otherImg.x;
        const otherRight = otherImg.x + otherWidth;
        const otherTop = otherImg.y;
        const otherBottom = otherImg.y + otherHeight;
        const otherCenterX = otherLeft + otherWidth / 2;
        const otherCenterY = otherTop + otherHeight / 2;

        // Add centers
        snappingPointsX.push(otherCenterX);
        snappingPointsY.push(otherCenterY);

        // Add edges
        snappingPointsX.push(otherLeft, otherRight);
        snappingPointsY.push(otherTop, otherBottom);
      }
    });

    // Check snapping for X axis
    snappingPointsX.forEach((point) => {
      if (Math.abs(imgCenterX - point) < SNAP_THRESHOLD) {
        newX = point - imgWidth / 2;
        snapX = point;
      }
      // Left and Right edges
      if (Math.abs(imgLeft - point) < SNAP_THRESHOLD) {
        newX = point;
        snapX = point;
      }
      if (Math.abs(imgRight - point) < SNAP_THRESHOLD) {
        newX = point - imgWidth;
        snapX = point;
      }
    });

    // Check snapping for Y axis
    snappingPointsY.forEach((point) => {
      if (Math.abs(imgCenterY - point) < SNAP_THRESHOLD) {
        newY = point - imgHeight / 2;
        snapY = point;
      }
      // Top and Bottom edges
      if (Math.abs(imgTop - point) < SNAP_THRESHOLD) {
        newY = point;
        snapY = point;
      }
      if (Math.abs(imgBottom - point) < SNAP_THRESHOLD) {
        newY = point - imgHeight;
        snapY = point;
      }
    });

    // Apply snapping if needed
    if (snapX !== undefined || snapY !== undefined) {
      node.position({ x: newX, y: newY });
      setImages((prev) => prev.map((image) => (image.id === img.id ? { ...image, x: newX, y: newY } : image)));
      setSnapLines({ horizontal: snapY, vertical: snapX });
    } else {
      setSnapLines({});
    }
  };

  return (
    <ColumnCenteredBox
      id="stage-container"
      sx={{
        height: "90%",
        transform: `scale(${stageScale})`,
      }}
    >
      <Stage
        width={stageWidth}
        height={stageHeight}
        ref={stageRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMou
        style={{
          border: "1px solid black",
        }}
      >
        {/* Background Layer */}
        <Layer>
          <Rect width={stageWidth} height={stageHeight} fill="white" />
        </Layer>

        {/* Images Layer */}
        <Layer>
          {images.map((img) => (
            <KonvaImage
              key={img.id}
              id={img.id}
              image={img.image}
              x={img.x}
              y={img.y}
              scaleX={img.scaleX}
              scaleY={img.scaleY}
              draggable={selectedTool === "select" && selectedImageId === img.id}
              onClick={() => handleSelectImage(img.id)}
              onTap={() => handleSelectImage(img.id)}
              onDragStart={handleDragStart}
              onDragMove={(e) => handleDragMove(e, img)}
              onDragEnd={(e) => handleDragEnd(e, img.id)}
              onTransformEnd={(e) => handleTransformEnd(img.id, e)}
            />
          ))}
          {selectedTool === "select" && selectedImageId && (
            <Transformer
              ref={transformerRef}
              anchorStroke="#00A3AA"
              anchorFill="#fff"
              anchorSize={10}
              borderStroke="#00A3AA"
              borderDash={[3, 3]}
              keepRatio={true}
              enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
              rotateEnabled={true}
            />
          )}
        </Layer>

        {/* Lines Layer */}
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke={line.stroke}
              opacity={line.opacity}
              strokeWidth={line.strokeWidth}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={line.globalCompositeOperation}
            />
          ))}
        </Layer>

        {/* Brush Size Preview Layer */}
        <Layer>
          {pointerPos && !isDrawing && (selectedTool === "brush" || selectedTool === "eraser") && (
            <Circle
              x={pointerPos.x}
              y={pointerPos.y}
              radius={brushSize / 2 / stageScale}
              stroke="#808080"
              fill="transparent"
              strokeWidth={1}
            />
          )}
        </Layer>

        {/* Cross Lines Layer */}
        <Layer>{crossLines}</Layer>

        {/* Snap Lines Layer */}
        {selectedTool === "select" && (
          <Layer>
            {isDragging && snapLines?.vertical !== undefined && (
              <Line points={[snapLines.vertical, 0, snapLines.vertical, stageHeight]} stroke="blue" dash={[4, 4]} />
            )}
            {isDragging && snapLines?.horizontal !== undefined && (
              <Line points={[0, snapLines.horizontal, stageWidth, snapLines.horizontal]} stroke="blue" dash={[4, 4]} />
            )}
          </Layer>
        )}
      </Stage>
    </ColumnCenteredBox>
  );
};
export default EditorStage;
