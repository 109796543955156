import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";

export const GradientTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: "bold",
  background: theme.custom.gradient.main,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  width: "fit-content",
  color: "transparent",
}));
