const summarize = {
  title: "パワフル\nサマライザー",
  library: "ライブラリ",
  description: "Tenak AI が文章の要約文を生成",
  type: "出力タイプを選択",
  outputText: "文章形式",
  outputList: "箇条書きリスト",
  URLToSummarize: "URL",
  URLToSummarizeDescription: "URLの内容をAI解析し、要約を生成します。",
  YouTubeToSummarize: "YouTube",
  YouTubeToSummarizeDescription: "YouTubeの内容をAI解析し、要約を生成します。",
  TextToSummarize: "テキスト",
  TextToSummarizeDescription: "テキストをAI解析し、要約を生成します。（高精度）",
  URL: "URL",
  urlPlaceholder: "https://www.example.com",
  youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )",
  textPlaceholder: "要約したい文章を入力して下さい。（最大30,000文字）",
  additional: "追加指示（オプション）",
  additionalPlaceholder: "追加の指示がある場合、入力して下さい。（最大400文字）",
  submit: "要約を生成",
};
export default summarize;
