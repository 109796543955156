import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import ButtonAI from "./ButtonAI";

interface MindmapData {
  mind_maps: Array<{
    uuid: string;
    title: string;
    content: string;
  }>;
}

const Mindmap = ({ data }: { data: MindmapData | undefined }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  function extractTextFromHTML(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

  // 空ファイル新規作成
  const handleNewFile = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      const res = await axios.post("/api/v1/mind-map/create", {}, config);
      if (res.status === 200) {
        navigate("/mind-map/" + res.data.uuid);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const AIs = [{ title: t("drawer.mindmapGenerator"), icon: <AccountTreeIcon />, path: "/mind-map-generator" }];

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <AccountTreeIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.mindmap")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/library/mind-maps")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {AIs.map((ai: { title: string; icon: JSX.Element; path: string }, index: number) => {
          return (
            <Grid item xs={6} sm={4} key={index}>
              <ButtonAI title={ai.title} icon={ai.icon} path={ai.path} image={`mindmap_${index + 1}`} />
            </Grid>
          );
        })}

        {data?.mind_maps?.map((mm, index) => {
          return (
            <Grid item xs={6} sm={4} key={index}>
              <ButtonBase
                onClick={() => navigate("/mind-map/" + mm.uuid)}
                sx={{
                  transition: "0.3s",
                  width: "100%",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Grow in={true} timeout={(index + 1) * 500}>
                  <Paper
                    elevation={theme.palette.mode === "dark" ? 1 : 0}
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                      height: 150,
                    }}
                  >
                    <Box
                      sx={{
                        height: "5px",
                        width: "100%",
                        background: theme.custom.gradient.main,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: 140,
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        variant={"subtitle2"}
                        component={"p"}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          mb: 1,
                          textAlign: "left",
                        }}
                      >
                        {mm.title || "Untitled"}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          mb: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          textAlign: "left",
                        }}
                      >
                        {extractTextFromHTML(mm.content)}
                      </Typography>
                    </Box>
                  </Paper>
                </Grow>
              </ButtonBase>
            </Grid>
          );
        })}
        <Grid item xs={6} sm={4}>
          <ButtonBase
            onClick={handleNewFile}
            sx={{
              transition: "0.3s",
              width: "100%",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            }}
          >
            <Grow in={true} timeout={500 * (data?.mind_maps?.length || 0 + 1)}>
              <Paper
                elevation={theme.palette.mode === "dark" ? 1 : 0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: 150,
                  border: "2px dashed" + theme.palette.primary.main,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    m: 1,
                  }}
                >
                  <Typography variant={"subtitle1"} textAlign={"center"} component={"p"} sx={{ mb: 1 }}>
                    Create new Document
                  </Typography>
                  <AddIcon sx={{ mb: 1, color: theme.palette.primary.main }} />
                  <Typography variant={"caption"} component={"p"}>
                    {t("dashboard.addMindmap")}
                  </Typography>
                </Box>
              </Paper>
            </Grow>
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Mindmap;
