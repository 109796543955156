import React, { useEffect, useRef, useState } from "react";
import { Box, Link, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Toc = ({ toc, side = false }) => {
  const { t } = useTranslation();
  const tocRef = useRef<HTMLDivElement | null>(null);
  const [activeId, setActiveId] = useState(null);
  const scrollPosition = useScrollPosition();
  const theme = useTheme();

  useEffect(() => {
    let closestId = null;
    let closestDistance = Infinity;

    toc.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + window.pageYOffset;
        const distance = Math.abs(scrollPosition - elementTop);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestId = item.id;
        }
      }
    });

    setActiveId(closestId);
  }, [scrollPosition, toc]);

  // スムーススクロール関数
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 70; // スクロール位置に適用するオフセット
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset; // オフセットを適用した位置

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const TocItem = ({ id, tag, text }) => {
    // 目次の項目コンポーネント
    const isActive = side ? id === activeId : false;
    const marginLeft = tag === "H2" ? 0 : tag === "H3" ? 1 : tag === "H4" ? 2 : tag === "H5" ? 3 : 4;

    return (
      <Typography
        variant="body2"
        style={{
          fontWeight: isActive ? "bold" : "normal",
          marginTop: "0.5em",
          marginLeft: `${marginLeft}em`,
        }}
      >
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            scrollToElement(id);
          }}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      </Typography>
    );
  };

  return (
    // 目次の表示
    <Box
      ref={tocRef} // Boxコンポーネントにrefを設定
      sx={{
        mb: 2,
        position: { xs: "static", md: side ? "sticky" : "static" },
        top: 80,
        width: "100%",
        display: toc.length > 0 ? "block" : "none",
      }}
    >
      <Paper sx={{ bgcolor: theme.palette.background.custom1, p: 2, width: "100%" }} elevation={0}>
        <Typography
          variant="subtitle1"
          component={"h6"}
          gutterBottom
          sx={{ fontFamily: "Noto Serif JP", fontWeight: "bold", justifyContent: "center", display: "flex" }}
        >
          {t("notesNebula.article.toc")}
        </Typography>
        {toc.map((item) => (
          <TocItem key={item.id} id={item.id} text={item.text} tag={item.tag} />
        ))}
      </Paper>
    </Box>
  );
};
export default Toc;
