import { Box, Slider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useUploadImageContext } from "../../../../../context/juno/UploadImageContext";

type ImageStrengthProps = {
  type: "primary" | "secondary";
};

const ImageStrength: React.FC<ImageStrengthProps> = ({ type }) => {
  const { t } = useTranslation();
  const { imageStrength, setImageStrength, imageStrength2, setImageStrength2 } = useUploadImageContext();

  const currentStrength = type === "primary" ? imageStrength : imageStrength2;
  const setCurrentStrength = type === "primary" ? setImageStrength : setImageStrength2;
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
          <Typography variant={"body2"}>
            {aiEngine === "FluxPuLID" ? t("juno.input.idWeight") : t("juno.input.imageStrength")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", ml: 0 }} gap={2}>
          <Slider
            value={currentStrength}
            onChange={(e, value) => setCurrentStrength(value as number)}
            step={0.01}
            min={0.1}
            max={1}
          />
          <Box
            sx={{
              width: "120px",
              bgcolor: "rgba(0, 0, 0, 0.1)",
              px: 1,
              py: 0.5,
              justifyContent: "center",
              display: "flex",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body2"}>{currentStrength}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ImageStrength;
