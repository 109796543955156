const pricing = {
  title: "Plans and Pricing",
  pricing: "PRICING",
  description: "Plans and pricing of Tenak AI",
  legalNotice: "Act on specified commercial transactions.",
  free: {
    title: "FREE",
    description: "Free Plan",
    price: "0",
    credit: "50",
  },
  basic: {
    title: "Basic",
    description: "Standard Plan",
    price: "18",
    credit: "900",
  },
  standard: {
    title: "Standard",
    description: "Business Plan",
    price: "36",
    credit: "1,800 + 200",
  },
  premium: {
    title: "Premium",
    description: "Premium Plan",
    price: "72",
    credit: "3,600 + 600",
  },
  perMonth: "Per Month",
  credit: "Credits",
  dailyCredit: "Daily Bonus Credits",
  aiEngine: "Powerful AI Engine",
  juno: "Image Generation",
  junoPrivate: "Private Generated Images",
  junoEngine: "All AI Engines (Images)",
  junoEnginePart: "Part of AI Engines (Images)",
  junoVideo: "Video Generation",
  mindmap: "Mindmap Generation",
  export: "Export Mindmap",
  template: "All AI Templates",
  textEditor: "AI Text Editor",
  voice: "Voice Generation",
  support: "Support",
  supportPriority: "Priority Support",
  supportNo: "No Support",
  subscribe: "Subscribe",
  aboutCredit: {
    title: "About Credits",
    description:
      "Tenak AI's plans use a credit system. Credits are the currency used for utilizing AI functions such as text, image, and voice generation. Below are the estimated credit consumption guidelines. ",
    text: {
      title: "Text Generation",
      "openai-gpt4o-input": "Intelligence Mode (Input)",
      "openai-gpt4o-output": "Intelligence Mode (Output)",
      "openai-gpt4o-mini-input": "Speed Mode (Input)",
      "openai-gpt4o-mini-output": "Speed Mode (Output)",
      note: "NOTE: System prompts and web searches will incur additional token usage.",
    },
    image: {
      title: "Image Generation",
      "openai-dalle3-hd": "OPEN AI: DALL-E 3",
      sdapi: "Stable Diffusion (XL)",
      sd3: "Stable Diffusion 3.5",
      "sd-ultra": "Stable Image Ultra",
      "flux11-pro-ultra": "Flux 1.1 Pro Ultra",
      "flux11-pro-ultra-redux": "Flux 1.1 Pro Ultra Redux",
      "flux11-pro": "Flux 1.1 Pro",
      "flux1-pro-fill": "Flux.1 Pro Fill",
      "flux11-pro-redux": "Flux 1 Pro Redux",
      "flux1-pro-canny": "Flux 1 Pro Canny",
      "flux1-pro-depth": "Flux 1 Pro Depth",
      "flux1-dev": "Flux.1 Dev",
      "flux1-dev-redux": "Flux 1 Dev Redux",
      "flux1-dev-fill": "Flux.1 Dev Fill",
      "flux1-dev-canny-lora": "Flux 1 Dev Canny",
      "flux1-dev-depth-lora": "Flux 1 Dev Depth",
      "flux1-schnell": "Flux.1 Schnell",
      "flux1-schnell-redux": "Flux 1 Schnell Redux",
      "flux1-realism-lora": "Flux.1 with Realism LoRA",
      "flux-juno": "Flux.1 with STUDIO JUNO",
      "flux-palid": "Flux PuLID",
      "recraft-v3": "Recraft V3",
      "kolors-vto": "Kolors Vitrual Try-On",
      ideogram2: "Ideogram 2.0",
      "ideogram2-turbo": "Ideogram 2.0 Turbo",
      "ideogram2-edit": "Ideogram 2.0 Edit",
      "ideogram2-turbo-edit": "Ideogram 2.0 Turbo Edit",
      "fal-aura-sr": "Upscaler AuraSR",
      "fal-clarity-upscaler": "Upscaler Clarity Upscaler",
      "fal-creative-upscaler": "Upscaler Creative Upscaler",
      "fal-recraft-creative-upscaler": "Recraft Creative Upscaler",
      "fal-recraft-clarity-upscaler": "Recraft Clarity Upscaler",
    },
    video: {
      title: "Video Generation",
      "runway-gen3a-turbo": "Runway Gen3 Alpha Turbo",
      "fal-kling-v1.0-standard": "Kling v1.0 Standard",
      "fal-kling-v1.6-standard": "Kling v1.6 Standard",
      "fal-kling-v1.0-pro": "Kling v1.0 Pro",
      "fal-kling-v1.5-pro": "Kling v1.5 Pro",
      "fal-kling-v1.6-pro": "Kling v1.6 Pro",
      "fal-luma-dream-machine-v1.5": "Dream Machine v1.5",
      "fal-hailuo-mini-max": "Mini Max",
      "fal-haiper-v2.0": "Haiper v2.0",
      "fal-haiper-v2.5": "Haiper v2.5",
    },
    voice: {
      title: "Voice Generation",
      "google-tts": "Google TTS (1,000 characters)",
      "openai-tts-hd": "OPEN AI TTS (1,000 tokens)",
      "openai-tts-hd-advanced": "OPEN AI Advanced Voice Mode (1,000 tokens)",
      note: "NOTE: OPEN AI Advanced Voice Mode is a mode for voice generation using a chat model, so the number of credits may vary.",
    },
    mindmap: {
      title: "Mindmap Generation",
      description: "When not using AI (depends on text generation when using AI generator)",
    },
    annotation:
      "* The above is a rough estimate and may vary depending on the content and usage. * The calculation will be rounded up to the second decimal place.",
  },
  faq: {
    title: "Frequently Asked Questions",
    description: "Common questions about Tenak AIs plans and pricing",
    q1: "Can credits be carried over?",
    a1: "No. Credits cannot be carried over.",
    q2: "If I upgrade, will the fee be prorated?",
    a2: "No. Since we use a credit system, the full amount will be charged. Then, the credits you have and the credits from the new plan will be combined, and one month from that day will be the expiration date for the credits and also the next billing date.",
    q3: "Can I purchase credits only?",
    a3: "No. Credits are included in the plan, and you will receive credits by purchasing a plan.",
    q4: "What happens to my credits if I cancel the plan?",
    a4: "If you cancel the plan, your credits will expire at the renewal month.",
    q5: "What should I do if I run out of credits?",
    a5: "If you run out of credits, please upgrade your plan.",
  },
};
export default pricing;
