import React from "react";
import { useTranslation } from "react-i18next";
import { AiEngineList, AiParams, MenuList } from "../types/junoTypes";

export const useJunoModels = () => {
  const { t } = useTranslation();
  const getModelParams = (aiEngine: AiEngineList, menu: MenuList) => {
    let params: AiParams[] = [];
    switch (aiEngine) {
      case "DallE3":
        params.push("prompt", "height", "width", "aspectRatio", "style", "revisedPrompt");
        break;
      case "SD35":
      case "SDUltra":
        params.push("prompt", "aspectRatio", "negativePrompt", "seed");
        break;
      case "Flux11Pro":
      case "Flux11ProRedux":
        params.push("prompt", "aspectRatio", "seed");
        break;
      case "Flux11ProUltra":
      case "Flux11ProUltraRedux":
        params.push("prompt", "aspectRatio", "rawMode", "seed");
        break;
      case "Flux1ProCanny":
      case "Flux1ProDepth":
        params.push("prompt", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "Flux1DevRedux":
      case "Flux1Dev":
      case "Flux1SchnellRedux":
        params.push("sample", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "Flux1Schnell":
        params.push("prompt", "sample", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "FluxPuLID":
        params.push("aspectRatio", "seed", "step", "guidanceScale", "negativePrompt");
        break;
      case "Flux1RealismLoRA":
        params.push("prompt", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "FluxJuno":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "seed", "step", "guidanceScale");
        break;
      case "Flux1DevCannyLoRA":
      case "Flux1DevDepthLoRA":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "seed", "step", "guidanceScale");
        break;
      case "Flux1ProFill":
        params.push("prompt", "editor", "seed");
        break;
      case "Flux1DevFill":
        params.push("prompt", "editor", "style", "styleWeight", "seed", "step", "sample");
        break;
      case "KolorsVTO":
        params.push("prompt");
        break;
      case "RecraftV3":
        params.push("prompt", "aspectRatio", "style", "colors");
        break;
      case "Ideogram2":
      case "Ideogram2Turbo":
        params.push("sample", "prompt", "aspectRatio", "style", "negativePrompt", "revisedPrompt", "seed", "style");
        break;
      case "Ideogram2Edit":
      case "Ideogram2TurboEdit":
        params.push("sample", "prompt", "editor", "style", "negativePrompt", "revisedPrompt", "seed", "style");
        break;
      // case "AuraSR":
      //   params.push("image", "scale");
      //   break;
      // case "ClarityUpscaler":
      //   params.push("image", "prompt", "scale", "creativity", "resemblance");
      //   break;
      // case "CreativeUpscaler":
      //   params.push("image", "prompt", "scale", "creativity", "resemblance", "texture");
      //   break;
      default:
        break;
    }

    // I2Iの場合は画像パラメータを追加
    if (["img2img", "editor"].includes(menu)) {
      if (
        [
          "SD35",
          "FluxJuno",
          "FluxPuLID",
          "Flux1Dev",
          "Flux11ProUltra",
          "Flux11ProRedux",
          "Flux11ProUltraRedux",
          "Flux1ProCanny",
          "Flux1ProDepth",
          "Flux1DevRedux",
          "Flux1DevCannyLoRA",
          "Flux1DevDepthLoRA",
          "Flux1SchnellRedux",
          "KolorsVTO",
          "Ideogram2",
          "Ideogram2Turbo",
        ].includes(aiEngine)
      ) {
        params.push("initImage");
      }

      if (["KolorsVTO"].includes(aiEngine)) {
        params.push("initImage2");
      }

      // I2Iの場合は画像強度パラメータを追加
      if (
        [
          "SD35",
          "FluxJuno",
          "Flux1Dev",
          "FluxPuLID",
          "Flux11ProUltra",
          "Flux11ProUltraRedux",
          "Ideogram2",
          "Ideogram2Turbo",
        ].includes(aiEngine)
      ) {
        params.push("imageStrength");
      }

      // I2Iの場合に特定のパラメータ（例えばアスペクト比）があれば削除
      if (["Flux1Dev"].includes(aiEngine)) {
        params = params.filter((param) => param !== "aspectRatio");
      }
    }
    return params;
  };

  const getImageModels = React.useMemo(() => {
    return {
      txt2img: [
        {
          value: "DallE3",
          label: t("juno.input.engine.DallE3"),
          description: t("juno.input.engine.DallE3Description"),
          category: [],
        },
        {
          value: "Flux11ProUltra",
          label: t("juno.input.engine.Flux11ProUltra"),
          description: t("juno.input.engine.Flux11ProUltraDescription"),
          category: ["pro", "2k"],
        },
        {
          value: "Flux11Pro",
          label: t("juno.input.engine.Flux11Pro"),
          description: t("juno.input.engine.Flux1ProDescription"),
          category: [],
        },
        {
          value: "Flux1Dev",
          label: t("juno.input.engine.Flux1Dev"),
          description: t("juno.input.engine.Flux1DevDescription"),
          category: [],
        },
        {
          value: "Flux1Schnell",
          label: t("juno.input.engine.Flux1Schnell"),
          description: t("juno.input.engine.Flux1SchnellDescription"),
          category: [],
        },
        {
          value: "Flux1RealismLoRA",
          label: t("juno.input.engine.Flux1RealismLoRA"),
          description: t("juno.input.engine.Flux1RealismLoRADescription"),
          category: [],
        },
        {
          value: "FluxJuno",
          label: t("juno.input.engine.FluxJuno"),
          description: t("juno.input.engine.FluxJunoDescription"),
          category: [],
        },
        {
          value: "RecraftV3",
          label: t("juno.input.engine.RecraftV3"),
          description: t("juno.input.engine.RecraftV3Description"),
          category: ["vector"],
        },
        {
          value: "Ideogram2",
          label: t("juno.input.engine.Ideogram2"),
          description: t("juno.input.engine.Ideogram2Description"),
          category: ["pro"],
        },
        {
          value: "Ideogram2Turbo",
          label: t("juno.input.engine.Ideogram2Turbo"),
          description: t("juno.input.engine.Ideogram2TurboDescription"),
          category: [],
        },
        {
          value: "SD35",
          label: t("juno.input.engine.SD35"),
          description: t("juno.input.engine.SD35Description"),
          category: [],
        },
        {
          value: "SDUltra",
          label: t("juno.input.engine.SDUltra"),
          description: t("juno.input.engine.SDUltraDescription"),
          category: [],
        },
      ],
      img2img: [
        {
          value: "Flux1Dev",
          label: t("juno.input.engine.Flux1Dev"),
          description: t("juno.input.engine.Flux1DevDescription"),
          category: [],
        },
        {
          value: "FluxJuno",
          label: t("juno.input.engine.FluxJuno"),
          description: t("juno.input.engine.FluxJunoDescription"),
          category: [],
        },
        {
          value: "FluxPuLID",
          label: t("juno.input.engine.FluxPuLID"),
          description: t("juno.input.engine.FluxPuLIDDescription"),
          category: [],
        },
        {
          value: "Ideogram2",
          label: t("juno.input.engine.Ideogram2"),
          description: t("juno.input.engine.Ideogram2Description"),
          category: ["pro"],
        },
        {
          value: "Ideogram2Turbo",
          label: t("juno.input.engine.Ideogram2Turbo"),
          description: t("juno.input.engine.Ideogram2TurboDescription"),
          category: [],
        },
        {
          value: "SD35",
          label: t("juno.input.engine.SD35"),
          description: t("juno.input.engine.SD35Description"),
          category: [],
        },
        {
          value: "Flux11ProUltraRedux",
          label: t("juno.input.engine.Flux11ProUltraRedux"),
          description: t("juno.input.engine.Flux11ProUltraReduxDescription"),
          category: ["pro", "vary", "2k"],
        },
        {
          value: "Flux11ProRedux",
          label: t("juno.input.engine.Flux11ProRedux"),
          description: t("juno.input.engine.Flux11ProReduxDescription"),
          category: ["vary"],
        },
        {
          value: "Flux1ProCanny",
          label: t("juno.input.engine.Flux1ProCanny"),
          description: t("juno.input.engine.Flux1ProCannyDescription"),
          category: ["pro", "controlNet"],
        },
        {
          value: "Flux1ProDepth",
          label: t("juno.input.engine.Flux1ProDepth"),
          description: t("juno.input.engine.Flux1ProDepthDescription"),
          category: ["pro", "controlNet"],
        },
        {
          value: "Flux1DevRedux",
          label: t("juno.input.engine.Flux1DevRedux"),
          description: t("juno.input.engine.Flux1DevReduxDescription"),
          category: ["vary"],
        },
        {
          value: "Flux1DevCannyLoRA",
          label: t("juno.input.engine.Flux1DevCannyLoRA"),
          description: t("juno.input.engine.Flux1DevCannyLoRADescription"),
          category: ["controlNet"],
        },
        {
          value: "Flux1DevDepthLoRA",
          label: t("juno.input.engine.Flux1DevDepthLoRA"),
          description: t("juno.input.engine.Flux1DevDepthLoRADescription"),
          category: ["controlNet"],
        },
        {
          value: "Flux1SchnellRedux",
          label: t("juno.input.engine.Flux1SchnellRedux"),
          description: t("juno.input.engine.Flux1SchnellReduxDescription"),
          category: ["vary"],
        },
        {
          value: "KolorsVTO",
          label: t("juno.input.engine.KolorsVTO"),
          description: t("juno.input.engine.KolorsVTODescription"),
          category: ["editor"],
        },
      ],
      editor: [
        {
          value: "Flux1ProFill",
          label: t("juno.input.engine.Flux1ProFill"),
          description: t("juno.input.engine.Flux1ProFillDescription"),
          category: ["pro", "editor"],
        },
        {
          value: "Flux1DevFill",
          label: t("juno.input.engine.Flux1DevFill"),
          description: t("juno.input.engine.Flux1DevFillDescription"),
          category: ["editor"],
        },
        {
          value: "Ideogram2Edit",
          label: t("juno.input.engine.Ideogram2Edit"),
          description: t("juno.input.engine.Ideogram2EditDescription"),
          category: ["pro", "editor"],
        },
        {
          value: "Ideogram2TurboEdit",
          label: t("juno.input.engine.Ideogram2TurboEdit"),
          description: t("juno.input.engine.Ideogram2TurboEditDescription"),
          category: ["editor"],
        },
      ],
      upscale: [
        {
          value: "AuraSR",
          label: t("juno.input.engine.AuraSR"),
          description: t("juno.input.engine.AuraSRDescription"),
          category: [],
        },
        {
          value: "ClarityUpscaler",
          label: t("juno.input.engine.ClarityUpscaler"),
          description: t("juno.input.engine.ClarityUpscalerDescription"),
          category: [],
        },
        {
          value: "CreativeUpscaler",
          label: t("juno.input.engine.CreativeUpscaler"),
          description: t("juno.input.engine.CreativeUpscalerDescription"),
          category: [],
        },
        {
          value: "RecraftClarityUpscaler",
          label: t("juno.input.engine.RecraftClarityUpscaler"),
          description: t("juno.input.engine.RecraftClarityUpscalerDescription"),
          category: [],
        },
        // コスト高すぎるため削除
        // {
        //   value: "RecraftCreativeUpscaler",
        //   label: t("juno.input.engine.RecraftCreativeUpscaler"),
        //   description: t("juno.input.engine.RecraftCreativeUpscalerDescription"),
        //   category: [],
        // },
      ],
    };
  }, [t]);

  const getVideoModels = React.useMemo(() => {
    return [
      {
        value: "LUMA",
        label: t("juno.video.ai.luma.title"),
        description: t("juno.video.ai.luma.description"),
        disabled: [],
      },
      {
        value: "KLING",
        label: t("juno.video.ai.kling.title"),
        description: t("juno.video.ai.kling.description"),
        disabled: [],
      },
      {
        value: "HAILUO",
        label: t("juno.video.ai.hailuo.title"),
        description: t("juno.video.ai.hailuo.description"),
        disabled: [],
      },
      {
        value: "HAIPER",
        label: t("juno.video.ai.haiper.title"),
        description: t("juno.video.ai.haiper.description"),
        disabled: [],
      },
      {
        value: "RUNWAY",
        label: t("juno.video.ai.runway.title"),
        description: t("juno.video.ai.runway.description"),
        disabled: ["t2v"],
      },
    ];
  }, [t]);

  return {
    getModelParams,
    getImageModels,
    getVideoModels,
  };
};
