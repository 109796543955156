import AnswerBox from "../pages/Chat/components/AnswerBox";
import UserBox from "../pages/Chat/components/UserBox";
import { HistoryItem } from "../types/chatTypes";

export const useChatHistory = (
  historyData: HistoryItem[],
  sendMessage: (message: string, images?: File[], files?: File[], editMessageUuid?: string | null) => Promise<void>,
  processingUuid: string | null,
  setSelectSourceId: (id: string) => void
) => {
  const makeHistoryElement = (historyItem: HistoryItem) => {
    switch (historyItem.role) {
      case "user":
        return <UserBox key={historyItem.message_uuid} historyItem={historyItem} sendMessage={sendMessage} />;
      case "assistant":
        return (
          <AnswerBox
            key={historyItem.message_uuid}
            processingId={processingUuid}
            historyItem={historyItem}
            setSelectSourceId={setSelectSourceId}
          />
        );
      default:
        return null;
    }
  };

  return { makeHistoryElement };
};
