import CreditCardIcon from "@mui/icons-material/CreditCard";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useAvatar } from "../../../context/AvatarContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import AIEngineSwitch from "./AIEngineSwitch";
import Credit from "./Credit";

const AccountPopup = memo(function AccountPopup() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const navigate = useCustomNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const listStyle = {
    py: 0,
  };

  const { user } = useLoginStatus();
  const { avatarUrl, isAvatarExist } = useAvatar();

  return (
    <>
      <IconButton size="large" aria-describedby={id} onClick={handleClick} color="inherit">
        {isAvatarExist ? (
          <Avatar alt="User avatar" src={avatarUrl ? avatarUrl[0] : ""} sx={{ width: 24, height: 24 }} />
        ) : (
          <Avatar color="primary" sx={{ width: 24, height: 24 }}>
            {user?.username.slice(0, 2)}
          </Avatar>
        )}
      </IconButton>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box width={320}>
          <Paper sx={listStyle}>
            <Box
              flexDirection={"column"}
              sx={{ py: 2 }}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <Typography variant={"subtitle1"}>@{user?.username}</Typography>
              {isAvatarExist === null || isAvatarExist === undefined ? (
                <Skeleton variant="circular" width={96} height={96} sx={{ my: 3 }} />
              ) : isAvatarExist ? (
                <Avatar alt="User avatar" src={avatarUrl ? avatarUrl[0] : ""} sx={{ width: 96, height: 96, my: 3 }} />
              ) : (
                <Avatar color="primary" sx={{ width: 96, height: 96, my: 3 }}>
                  {user?.username.slice(0, 2)}
                </Avatar>
              )}
              <Stack direction="row" alignItems="center">
                <Button
                  variant="outlined"
                  color={"primary"}
                  size={"small"}
                  sx={{ borderRadius: "12px" }}
                  onClick={() => {
                    handleClose();
                    navigate("/preferences/account");
                  }}
                  startIcon={<SettingsIcon />}
                >
                  {t("preferences.account.subtitle")}{" "}
                </Button>
              </Stack>
            </Box>
            <Divider />
            <ListItemButton onClick={() => navigate("/preferences/plan-and-billings")}>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={t("preferences.planAndBillings.title")} onClick={handleClose} />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/preferences/settings")}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t("preferences.settings.title")} onClick={handleClose} />
            </ListItemButton>
            <Divider />
            <Stack direction="column" alignItems="center" sx={{ p: 2, width: "100%", gap: 2 }}>
              <Credit />
            </Stack>
            <Divider />
            <Stack direction="column" alignItems="center" sx={{ p: 2, width: "100%", gap: 2 }}>
              <AIEngineSwitch />
            </Stack>
          </Paper>
        </Box>
      </Popover>
    </>
  );
});
export default AccountPopup;
