import { CircularProgress, Fade } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import AlertMessage from "./components/common/AlertMessage";
import Layout from "./components/layout/Layout";
import { AlertProvider } from "./context/AlertContext";
import { DarkModeProvider, useDarkMode } from "./context/DarkModeContext";
import { LoginStatusProvider } from "./context/LoginStatusContext";
import Maintenance from "./pages/Maintenance";
import theme from "./theme";

const App: React.FC = () => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : "";
  const lang = window.location.pathname.split("/")[1] === "en" ? "en" : "ja";

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={lang} />
      </Helmet>
      <GoogleOAuthProvider clientId={googleClientId}>
        <LoginStatusProvider>
          <DarkModeProvider>
            <Router>
              <AppContent />
            </Router>
          </DarkModeProvider>
        </LoginStatusProvider>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
};

interface MaintenanceModeResponse {
  maintenance_mode: boolean;
}

const AppContent = () => {
  const [loading, setLoading] = React.useState(true);
  const { darkMode } = useDarkMode();
  const [maintenanceMode, setMaintenanceMode] = React.useState(false);

  const fetchCSRFToken = async () => {
    try {
      await axios.get("/api/v1/users/csrf-cookie");
      console.log("CSRF token obtained.");
    } catch (error) {
      console.error("Failed to obtain CSRF token:", error);
    }
  };

  const fetchMaintenanceMode = async (): Promise<MaintenanceModeResponse | undefined> => {
    try {
      const res = await axios.get<MaintenanceModeResponse>("/api/v1/maintenance");
      return res.data;
    } catch (error) {
      console.error("Failed to obtain maintenance mode status:", error);
      return { maintenance_mode: true };
    }
  };

  useEffect(() => {
    setLoading(true);
    const initialize = async () => {
      if (window.location.pathname.includes("/notes-nebula")) {
        return;
      }
      const maintenanceData = await fetchMaintenanceMode();
      if (maintenanceData) {
        setMaintenanceMode(maintenanceData.maintenance_mode);
      }
      fetchCSRFToken().then();
    };
    initialize().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme(darkMode)}>
        <Fade in={true} timeout={500}>
          <Box>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  backgroundColor: "background.default",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {maintenanceMode ? (
                  <Maintenance />
                ) : (
                  <AlertProvider>
                    <AlertMessage />
                    <CssBaseline />
                    <Layout />
                  </AlertProvider>
                )}
              </>
            )}
          </Box>
        </Fade>
      </ThemeProvider>
    </>
  );
};

export default App;
