const junoPreset = {
  icon: "Inspiration",
  description: "Click the chip to add it to the prompt.",
  illumination: {
    sunlight: "Sunlight",
    naturalLighting: "Natural Lighting",
    directSunlight: "Direct Sunlight",
    candlelight: "Candlelight",
    moonlight: "Moonlight",
    backlight: "Backlight",
    accentLighting: "Accent Lighting",
    spotlight: "Spotlight",
    fluorescent: "Fluorescent",
    neonLamp: "Neon Lamp",
    blacklight: "Blacklight",
    ultraviolet: "Ultraviolet",
    strobe: "Strobe",
    dusk: "Dusk",
    glowing: "Glowing",
    blindingLight: "Blinding Light",
    glowstick: "Glowstick",
    edisonBulb: "Edison Bulb",
    fire: "Fire Light",
    crepuscularRays: "Crepuscular Rays",
    concertLighting: "Concert Lighting",
    nightclubLighting: "Nightclub Lighting",
    electricArc: "Electric Arc",
    lavaGlow: "Lava Glow",
    glowingRadioactively: "Glowing Radioactively",
    nuclearWasteGlow: "Nuclear Waste Glow",
    quantumDotDisplay: "Quantum Dot Display",
    studioLight: "Studio Light",
    softBoxLighting: "Soft Box Lighting",
    threePointLighting: "Three-Point Lighting",
    highKeyLighting: "High Key Lighting",
    lowKeyLighting: "Low Key Lighting",
    edgeLighting: "Edge Lighting",
    bounceLighting: "Bounce Lighting",
    practicalLighting: "Practical Lighting",
    motivatedLighting: "Motivated Lighting",
    cinematicOverhead: "Cinematic Overhead",
    strongSunsetLight: "Strong Sunset Light",
    goldenHourRadiance: "Golden Hour Radiance",
    softMorningLight: "Soft Morning Light",
    candleLitAmbience: "Candlelit Ambience",
    glowingCampfireLight: "Glowing Campfire Light",
    forestSunbeams: "Forest Sunbeams",
    mistyMorningGlow: "Misty Morning Glow",
    underwaterRadiance: "Underwater Radiance",
    twilightShimmer: "Twilight Shimmer",
    fierySunset: "Fiery Sunset",
    rainReflectionGlow: "Rain Reflection Glow",
    shimmeringWaterReflection: "Shimmering Water Reflection",
    glowingStreetLamps: "Glowing Street Lamps",
    industrialSpotlight: "Industrial Spotlight",
    dramaticStageLighting: "Dramatic Stage Lighting",
    auroraBorealisLight: "Aurora Borealis Light",
    starlitSky: "Starlit Sky",
    warmAutumnAfternoon: "Warm Autumn Afternoon",
    cascadingSunlightThroughLeaves: "Cascading Sunlight Through Leaves",
    mysteriousCavernGlow: "Mysterious Cavern Glow",
    glowingCityScapeAtNight: "Glowing Cityscape at Night",
    vividRainforestLight: "Vivid Rainforest Light",
    sunsetOverCityscape: "Sunset Over Cityscape",
    etherealCathedralLight: "Ethereal Cathedral Light",
    radiantSpringMeadow: "Radiant Spring Meadow",
    festiveHolidayLights: "Festive Holiday Lights",
    forestFloorDappledLight: "Forest Floor Dappled Light",
    bustlingMarketLight: "Bustling Market Light",
    glowingVolcanicCrater: "Glowing Volcanic Crater",
    desertMirageLight: "Desert Mirage Light",
    eveningFirefliesGlow: "Evening Fireflies Glow",
    lighthouseBeacon: "Lighthouse Beacon",
    chiaroscuroEffect: "Chiaroscuro Effect",
    harshSpotlight: "Harsh Spotlight",
    softAmbientGlow: "Soft Ambient Glow",
    diffuseDaylight: "Diffuse Daylight",
    directionalBeam: "Directional Beam",
    goldenBacklight: "Golden Backlight",
    softDiffusedWindowLight: "Soft Diffused Window Light",
    hazyLavenderFieldGlow: "Hazy Lavender Field Glow",
    dramaticRimLighting: "Dramatic Rim Lighting",
    singleSourceAthleteSpotlight: "Single Source Athlete Spotlight",
    coldMoonlightGraveyard: "Cold Moonlight Graveyard",
    warmCandlelightRomance: "Warm Candlelight Romance",
    sereneYogaStudioLight: "Serene Yoga Studio Light",
    nostalgicChildhoodSunlight: "Nostalgic Childhood Sunlight",
    goldenDinnerCandlelight: "Golden Dinner Candlelight",
    UVBlacklightParty: "UV Blacklight Party",
  },
  background: {
    officeChat: "Office Chat",
    bustlingNewYorkStreet: "Bustling New York Street",
    traditionalJapaneseGarden: "Traditional Japanese Garden",
    modernTokyoIntersection: "Modern Tokyo Intersection",
    suburbanNeighborhood: "Suburban Neighborhood",
    quietLibrary: "Quiet Library",
    historicalLandmark: "Historical Landmark",
    vibrantCafe: "Vibrant Cafe",
    trainStationPlatform: "Train Station Platform",
    urbanPark: "Urban Park",
    minimalistWhiteStudio: "Minimalist White Studio",
    mountainPeaks: "Mountain Peaks",
    oceanHorizon: "Ocean Horizon",
    citySkyline: "City Skyline",
    desertDunes: "Desert Dunes",
    snowyLandscape: "Snowy Landscape",
    tropicalBeach: "Tropical Beach",
    medievalCastle: "Medieval Castle",
    futuristicCity: "Futuristic City",
    underwaterScene: "Underwater Scene",
    bustlingMarket: "Bustling Market",
    gothicCathedral: "Gothic Cathedral",
    tranquilLake: "Tranquil Lake",
    goldenWheatField: "Golden Wheat Field",
    cobblestoneStreet: "Cobblestone Street",
    carnivalGrounds: "Carnival Grounds",
    bambooForest: "Bamboo Forest",
    sunflowerField: "Sunflower Field",
    volcanicLandscape: "Volcanic Landscape",
    sereneVillage: "Serene Village",
    cherryBlossomPath: "Cherry Blossom Path",
    bustlingUrbanAlley: "Bustling Urban Alley",
    ancientLibrary: "Ancient Library",
    bustlingHarbor: "Bustling Harbor",
    sereneZenGarden: "Serene Zen Garden",
    vibrantCityPlaza: "Vibrant City Plaza",
    sunlitCountryside: "Sunlit Countryside",
    grandOperaHall: "Grand Opera Hall",
    twilightMountainVillage: "Twilight Mountain Village",
    cozyCottageInterior: "Cozy Cottage Interior",
    majesticWaterfall: "Majestic Waterfall",
    hiddenJungleTemple: "Hidden Jungle Temple",
    neonCyberpunkCity: "Neon Cyberpunk City",
    snowyMountainCabin: "Snowy Mountain Cabin",
    underwaterCoralReef: "Underwater Coral Reef",
    ancientDesertRuins: "Ancient Desert Ruins",
    livelyFarmyard: "Lively Farmyard",
    vastGalaxyView: "Vast Galaxy View",
    enchantedForest: "Enchanted Forest",
    luxuriousBallroom: "Luxurious Ballroom",
    spaceshipInterior: "Spaceship Interior",
    sereneBeachSunset: "Serene Beach Sunset",
    industrialFactoryFloor: "Industrial Factory Floor",
    medievalVillageMarket: "Medieval Village Market",
    mysticalFloatingIslands: "Mystical Floating Islands",
    urbanGraffitiAlley: "Urban Graffiti Alley",
    rusticFarmhouse: "Rustic Farmhouse",
    crowdedCityFestival: "Crowded City Festival",
    peacefulMonastery: "Peaceful Monastery",
    futuristicScienceLab: "Futuristic Science Lab",
    vibrantCarnivalParade: "Vibrant Carnival Parade",
    tranquilHotSpring: "Tranquil Hot Spring",
    abandonedHauntedMansion: "Abandoned Haunted Mansion",
  },
  style: {
    realisticPhoto: "Realistic Photo Style",
    rendering3D: "3D Rendering",
    watercolorPainting: "Watercolor Painting Style",
    oilPainting: "Oil Painting Style",
    pencilSketch: "Pencil Sketch Style",
    abstractArt: "Abstract Art Style",
    popArt: "Pop Art Style",
    pixelArt: "Pixel Art Style",
    cartoonStyle: "Cartoon Style",
    animeStyle: "Anime Style",
    disneyStyle: "Disney Style",
    impressionistPainting: "Impressionist Painting Style",
    modernMinimalist: "Modern Minimalist Style",
    surrealismArt: "Surrealism Art Style",
    steampunkArt: "Steampunk Art Style",
    cyberpunkArt: "Cyberpunk Art Style",
    fantasyArt: "Fantasy Art Style",
    gothicArt: "Gothic Art Style",
    baroqueArt: "Baroque Art Style",
    retroFuturism: "Retro Futurism Style",
    photorealism: "Photorealism",
    cubism: "Cubism Style",
    fauvism: "Fauvism Style",
    ukiyoe: "Ukiyo-e Style",
    renaissanceArt: "Renaissance Art Style",
    newMakotoShinkaiStyle: "New Makoto Shinkai Style",
    vincentVanGoghStyle: "Vincent van Gogh Style",
    picassoStyle: "Picasso Style",
    comicBookStyle: "Comic Book Style",
    graffitiArt: "Graffiti Art Style",
    photorealCGI: "Photoreal CGI Style",
    monochromeFilm: "Monochrome Film Style",
    digitalPainting: "Digital Painting Style",
    lowPoly: "Low Polygon Style",
    stopMotion: "Stop Motion Animation Style",
    paperCraft: "Paper Craft Style",
    botanicalArt: "Botanical Art Style",
    silhouetteArt: "Silhouette Art Style",
    retroArcade: "Retro Arcade Style",
    photoCollage: "Photo Collage Style",
    splatterArt: "Splatter Art Style",
    pastelColor: "Pastel Color Art Style",
    highFantasy: "High Fantasy Illustration Style",
    darkFantasy: "Dark Fantasy Illustration Style",
    flatDesign: "Flat Design Style",
    midCenturyModern: "Mid-Century Modern Style",
    visualNovel: "Visual Novel Art Style",
    conceptualArt: "Conceptual Art Style",
    dadaism: "Dadaism Art Style",
    victorianArt: "Victorian Art Style",
    artDeco: "Art Deco Style",
    artNouveau: "Art Nouveau Style",
    cartoonyRealism: "Cartoony Realism",
    gameAsset: "Game Asset Style",
    militaryFiction: "Military Fiction Style",
    cuteChibi: "Cute Chibi Style",
    medievalFantasy: "Medieval Fantasy Style",
    neoNoir: "Neo-Noir Style",
    technoArt: "Techno Art Style",
    proceduralArt: "Procedural Art Style",
  },
};

export default junoPreset;
