import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import FolderIcon from "@mui/icons-material/Folder";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ButtonAI from "./ButtonAI";

const Voice = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        {/* 音声生成 */}
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <GraphicEqIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.voice")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/library/voices")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <ButtonAI
            title={t("drawer.voiceGenerator")}
            icon={<GraphicEqIcon />}
            path={"/voice-generator"}
            image={"voice_1"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Voice;
