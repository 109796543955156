const junoPreset = {
  icon: "インスピレーション",
  description: "チップをクリックしてプロンプトに追加して下さい",
  illumination: {
    sunlight: "日光",
    naturalLighting: "自然光",
    directSunlight: "直射日光",
    candlelight: "キャンドルの光",
    moonlight: "月明かり",
    backlight: "逆光",
    accentLighting: "アクセント照明",
    spotlight: "スポットライト",
    fluorescent: "蛍光灯の光",
    neonLamp: "ネオンランプの光",
    blacklight: "ブラックライトの光",
    ultraviolet: "紫外線の光",
    strobe: "ストロボ光",
    dusk: "夕暮れの光",
    glowing: "輝く光",
    blindingLight: "眩しい光",
    glowstick: "グロースティックの光",
    edisonBulb: "エジソン電球の光",
    fire: "火の光",
    crepuscularRays: "薄明光線",
    concertLighting: "コンサートの照明",
    nightclubLighting: "ナイトクラブの照明",
    electricArc: "電弧の光",
    lavaGlow: "溶岩の輝き",
    glowingRadioactively: "放射性の輝き",
    nuclearWasteGlow: "核廃棄物の輝き",
    quantumDotDisplay: "量子ドットディスプレイの光",
    studioLight: "スタジオライト",
    softBoxLighting: "ソフトボックス照明",
    threePointLighting: "スリーポイント照明",
    highKeyLighting: "ハイキー照明",
    lowKeyLighting: "ローキー照明",
    edgeLighting: "エッジ照明",
    bounceLighting: "バウンス照明",
    practicalLighting: "実用照明",
    motivatedLighting: "動機付けられた照明",
    cinematicOverhead: "映画的オーバーヘッドの光",
    strongSunsetLight: "夕日の強い日差し",
    goldenHourRadiance: "ゴールデンアワーの輝き",
    softMorningLight: "柔らかな朝の光",
    candleLitAmbience: "キャンドルライトの雰囲気",
    glowingCampfireLight: "輝くキャンプファイヤーの光",
    forestSunbeams: "森の木漏れ日",
    mistyMorningGlow: "霧の朝の光",
    underwaterRadiance: "水中の輝き",
    twilightShimmer: "黄昏の輝き",
    fierySunset: "燃えるような夕日の光",
    rainReflectionGlow: "雨の反射の輝き",
    shimmeringWaterReflection: "きらめく水の反射",
    glowingStreetLamps: "輝く街灯",
    industrialSpotlight: "工業的なスポットライト",
    dramaticStageLighting: "ドラマチックな舞台照明",
    auroraBorealisLight: "オーロラの光",
    starlitSky: "星明かりの空の光",
    warmAutumnAfternoon: "暖かな秋の午後の光",
    cascadingSunlightThroughLeaves: "葉を通る滝のような日差し",
    mysteriousCavernGlow: "神秘的な洞窟の光",
    glowingCityScapeAtNight: "夜の輝く都市景観",
    vividRainforestLight: "鮮やかな熱帯雨林の光",
    sunsetOverCityscape: "都市の夕日の光",
    etherealCathedralLight: "幻想的な大聖堂の光",
    radiantSpringMeadow: "輝く春の草原",
    festiveHolidayLights: "お祭りのホリデーライト",
    forestFloorDappledLight: "森林の床に差し込む斑点状の光",
    bustlingMarketLight: "にぎやかな市場の光",
    glowingVolcanicCrater: "輝く火山の噴火口",
    desertMirageLight: "砂漠の蜃気楼の光",
    eveningFirefliesGlow: "夕暮れのホタルの輝き",
    lighthouseBeacon: "灯台の光",
    chiaroscuroEffect: "キアロスクーロ効果",
    harshSpotlight: "厳しいスポットライト",
    softAmbientGlow: "柔らかな環境光",
    diffuseDaylight: "拡散する日光",
    directionalBeam: "指向性の光",
    goldenBacklight: "金色の逆光",
    softDiffusedWindowLight: "柔らかく拡散された窓の光",
    hazyLavenderFieldGlow: "霞んだラベンダー畑の輝き",
    dramaticRimLighting: "ドラマチックなリムライト",
    singleSourceAthleteSpotlight: "アスリートに焦点を当てた単一光源",
    coldMoonlightGraveyard: "冷たい月明かりが照らす墓地",
    warmCandlelightRomance: "暖かいキャンドルライトのロマンス",
    sereneYogaStudioLight: "静かなヨガスタジオの光",
    nostalgicChildhoodSunlight: "懐かしい子供時代の日差し",
    goldenDinnerCandlelight: "黄金色のディナーキャンドルの光",
    UVBlacklightParty: "UVブラックライトパーティーの光",
  },
  background: {
    officeWorkspace: "オフィスの作業スペース",
    bustlingNewYorkStreet: "にぎやかなニューヨークの通り",
    traditionalJapaneseGarden: "伝統的な日本庭園",
    modernTokyoIntersection: "現代の東京の交差点",
    suburbanNeighborhood: "郊外の住宅街",
    quietLibrary: "静かな図書館",
    historicalLandmark: "歴史的なランドマーク",
    vibrantCafe: "活気のあるカフェ",
    trainStationPlatform: "駅のプラットフォーム",
    urbanPark: "都会の公園",
    minimalistWhiteStudio: "ミニマリストの白いスタジオ",
    mountainPeaks: "山の頂上",
    oceanHorizon: "海の地平線",
    citySkyline: "都市のスカイライン",
    desertDunes: "砂漠の砂丘",
    snowyLandscape: "雪景色",
    tropicalBeach: "トロピカルなビーチ",
    medievalCastle: "中世の城",
    futuristicCity: "未来都市",
    underwaterScene: "水中の風景",
    bustlingMarket: "にぎやかな市場",
    gothicCathedral: "ゴシックの大聖堂",
    tranquilLake: "静かな湖",
    goldenWheatField: "金色の麦畑",
    cobblestoneStreet: "石畳の通り",
    carnivalGrounds: "カーニバルの敷地",
    bambooForest: "竹林",
    sunflowerField: "ひまわり畑",
    volcanicLandscape: "火山の風景",
    sereneVillage: "静かな村",
    cherryBlossomPath: "桜並木",
    bustlingUrbanAlley: "にぎやかな都会の路地",
    ancientLibrary: "古代の図書館",
    bustlingHarbor: "活気のある港",
    sereneZenGarden: "静かな禅の庭",
    vibrantCityPlaza: "活気ある都市広場",
    sunlitCountryside: "陽光あふれる田舎",
    grandOperaHall: "壮大なオペラホール",
    twilightMountainVillage: "夕暮れの山村",
    cozyCottageInterior: "居心地の良いコテージのインテリア",
    majesticWaterfall: "壮大な滝",
    hiddenJungleTemple: "密林の中の隠れた寺院",
    neonCyberpunkCity: "ネオンのサイバーパンク都市",
    snowyMountainCabin: "雪山のキャビン",
    underwaterCoralReef: "水中のサンゴ礁",
    ancientDesertRuins: "古代の砂漠の遺跡",
    livelyFarmyard: "にぎやかな農場",
    vastGalaxyView: "広大な銀河の眺め",
    enchantedForest: "魔法にかけられた森",
    luxuriousBallroom: "豪華な舞踏室",
    spaceshipInterior: "宇宙船の内部",
    sereneBeachSunset: "静かなビーチの夕日",
    industrialFactoryFloor: "工業的な工場の床",
    medievalVillageMarket: "中世の村の市場",
    mysticalFloatingIslands: "神秘的な浮遊島",
    urbanGraffitiAlley: "都会の落書きの路地",
    rusticFarmhouse: "素朴な農家",
    crowdedCityFestival: "にぎやかな都市のお祭り",
    peacefulMonastery: "静かな修道院",
    futuristicScienceLab: "未来的な科学研究所",
    vibrantCarnivalParade: "活気あるカーニバルのパレード",
    tranquilHotSpring: "静かな温泉",
    abandonedHauntedMansion: "放棄された幽霊屋敷",
  },
  style: {
    realisticPhoto: "リアルフォトスタイル",
    Rendering3D: "3Dレンダリング",
    watercolorPainting: "水彩画スタイル",
    oilPainting: "油絵スタイル",
    pencilSketch: "鉛筆スケッチスタイル",
    abstractArt: "抽象芸術スタイル",
    popArt: "ポップアートスタイル",
    pixelArt: "ピクセルアートスタイル",
    cartoonStyle: "漫画スタイル",
    animeStyle: "アニメスタイル",
    disneyStyle: "ディズニースタイル",
    impressionistPainting: "印象派の絵画スタイル",
    modernMinimalist: "モダンミニマリストスタイル",
    surrealismArt: "シュルレアリスムアートスタイル",
    steampunkArt: "スチームパンクアートスタイル",
    cyberpunkArt: "サイバーパンクアートスタイル",
    fantasyArt: "ファンタジーアートスタイル",
    gothicArt: "ゴシックアートスタイル",
    baroqueArt: "バロックアートスタイル",
    retroFuturism: "レトロフューチャリズムスタイル",
    photorealism: "フォトリアリズム",
    cubism: "キュビスムスタイル",
    fauvism: "フォーヴィスムスタイル",
    ukiyoe: "浮世絵スタイル",
    renaissanceArt: "ルネサンスアートスタイル",
    newMakotoShinkaiStyle: "新海誠スタイル",
    vincentVanGoghStyle: "ヴィンセント・ヴァン・ゴッホスタイル",
    picassoStyle: "ピカソスタイル",
    comicBookStyle: "コミックブックスタイル",
    graffitiArt: "グラフィティアートスタイル",
    photorealCGI: "フォトリアルCGIスタイル",
    monochromeFilm: "モノクロームフィルムスタイル",
    digitalPainting: "デジタルペイントスタイル",
    lowPoly: "ローポリゴンスタイル",
    stopMotion: "ストップモーションアニメスタイル",
    paperCraft: "ペーパークラフトスタイル",
    botanicalArt: "ボタニカルアートスタイル",
    silhouetteArt: "シルエットアートスタイル",
    retroArcade: "レトロアーケードスタイル",
    photoCollage: "フォトコラージュスタイル",
    splatterArt: "スプラッターアートスタイル",
    pastelColor: "パステルカラーアートスタイル",
    highFantasy: "ハイファンタジーイラストスタイル",
    darkFantasy: "ダークファンタジーイラストスタイル",
    flatDesign: "フラットデザインスタイル",
    midCenturyModern: "ミッドセンチュリーモダンスタイル",
    visualNovel: "ビジュアルノベルアートスタイル",
    conceptualArt: "コンサプチュアルアートスタイル",
    dadaism: "ダダイズムアートスタイル",
    victorianArt: "ビクトリア朝アートスタイル",
    artDeco: "アールデコスタイル",
    artNouveau: "アールヌーボースタイル",
    cartoonyRealism: "カートゥーニッシュリアリズム",
    gameAsset: "ゲームアセットスタイル",
    militaryFiction: "ミリタリーフィクションスタイル",
    cuteChibi: "キュートチビスタイル",
    medievalFantasy: "メディーバルファンタジースタイル",
    neoNoir: "ネオノワールスタイル",
    technoArt: "テクノアートスタイル",
    proceduralArt: "プロシージャルアートスタイル",
  },
};

export default junoPreset;
