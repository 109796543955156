const voice = {
  title: "音声ジェネレーター",
  description: "Tenak AI がテキストから音声を生成します。",
  content: "テキスト",
  advanceVoiceMode:
    "高度な音声モードはチャットモデルを使用しているため追加指示を指定することが可能ですが、そのため、意図せぬ結果になることがあります。",
  instructions: "追加指示",
  instructionsPlaceholder: "明るい感じで読んでください。",
  submit: "音声を生成",
  speed: "速度",
  pitch: "ピッチ",
  language: "言語",
  voice: "声",
  library: "ライブラリ",
  typeDescription: "音声の種類について",
  googleVoiceLabel: {
    neural: "プレミアム",
    wavenet: "ナチュラル",
    studio: "スタジオ(プレビュー)",
    standard: "スタンダード",
    journey: "旅の声（実験的）",
    news: "ニュース",
    polyglot: "多言語",
  },
  openaiVoiceLabel: {
    Alloy: "Alloy",
    Ash: "Ash",
    Coral: "Coral",
    Echo: "Echo",
    Fable: "Fable",
    Onyx: "Onyx",
    Nova: "Nova",
    Sage: "Sage",
    Shimmer: "Shimmer",
    Ash_A: "Ash (高度な音声モード)",
    Ballad_A: "Ballad (高度な音声モード)",
    Coral_A: "Coral (高度な音声モード)",
    Sage_A: "Sage (高度な音声モード)",
    Verse_A: "Verse (高度な音声モード)",
  },
  voiceDescription: {
    neural:
      "最も自然で人間のような音声品質を提供します。このタイプはディープラーニング技術に基づいており、非常に高いリアリズムを実現しています。（Neural2）",
    wavenet:
      "高品質で自然な音声を提供します。GoogleのWaveNet技術を使用しており、リアルな音声合成が可能です。（Wavenet）",
    studio:
      "プロフェッショナルなスタジオ品質の音声を提供します。特定のアプリケーションやシナリオ向けに設計されています。",
    standard: "従来の音声生成技術を使用しています。一般的な用途に適しており、基本的な音声合成ニーズに対応します。",
    journey:
      "特定のストーリーやナラティブ向けにデザインされた実験的な音声です。特定の状況やテーマに合わせたユニークな音声体験を提供します。",
  },
  gender: {
    male: "男性",
    female: "女性",
  },
  provider: {
    title: "AI ENGINE",
    google: "Google",
    openai: "OPEN AI",
  },
  message: {
    success: "音声を生成しました。音声はライブラリに保存されています。",
    error: "音声の生成に失敗しました。後ほど再度試してください。",
  },
};
export default voice;
