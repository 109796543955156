import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageUpload from "../ImageUpload";

type ImageProps = {
  type: "primary" | "secondary";
};

const Image: React.FC<ImageProps> = ({ type }) => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  const title = () => {
    switch (aiEngine) {
      case "FluxPuLID":
        return t("juno.input.imageFace");
      case "KolorsVTO":
        return type === "primary" ? t("juno.input.imageHuman") : t("juno.input.imageVTO");
      default:
        return t("juno.input.image");
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
        <Typography variant={"body2"}>{title()}</Typography>
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <ImageUpload type={type} />
      </Box>
    </Box>
  );
};
export default Image;
