import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BrowsingState = {
  triggerFetch: boolean;
  processing: { [chatUuid: string]: boolean };
  inputQuery: { [chatUuid: string]: string };
  refreshChatHistories: boolean;
};

const initialState: BrowsingState = {
  triggerFetch: false,
  processing: {},
  inputQuery: {},
  refreshChatHistories: false,
};

const browsingSlice = createSlice({
  name: "browsing",
  initialState,
  reducers: {
    triggerChatFetch: (state) => {
      state.triggerFetch = !state.triggerFetch;
    },
    setProcessing: (state, action: PayloadAction<{ chatUuid: string; processing: boolean }>) => {
      const { chatUuid, processing } = action.payload;
      state.processing[chatUuid] = processing;
    },
    setInputQuery: (state, action: PayloadAction<{ chatUuid: string; inputQuery: string }>) => {
      const { chatUuid, inputQuery } = action.payload;
      state.inputQuery[chatUuid] = inputQuery;
    },
    refreshChatHistories: (state) => {
      state.refreshChatHistories = !state.refreshChatHistories;
    },
  },
});

export const { triggerChatFetch, setProcessing, setInputQuery, refreshChatHistories } = browsingSlice.actions;
export const browsingReducer = browsingSlice.reducer;
