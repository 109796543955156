export const styleTranslate = (styleParam) => {
  switch (styleParam) {
    case "any":
      return "juno.input.style.recraft.any";
    case "realistic_image":
      return "juno.input.style.recraft.realisticImage";
    case "realistic_image/b_and_w":
      return "juno.input.style.recraft.realisticImageBW";
    case "realistic_image/hard_flash":
      return "juno.input.style.recraft.realisticImageHardFlash";
    case "realistic_image/hdr":
      return "juno.input.style.recraft.realisticImageHDR";
    case "realistic_image/natural_light":
      return "juno.input.style.recraft.realisticImageNaturalLight";
    case "realistic_image/studio_portrait":
      return "juno.input.style.recraft.realisticImageStudioPortrait";
    case "realistic_image/enterprise":
      return "juno.input.style.recraft.realisticImageEnterprise";
    case "realistic_image/motion_blur":
      return "juno.input.style.recraft.realisticImageMotionBlur";
    case "realistic_image/evening_light":
      return "juno.input.style.recraft.realisticImageEveningLight";
    case "realistic_image/faded_nostalgia":
      return "juno.input.style.recraft.realisticImageFadedNostalgia";
    case "realistic_image/forest_life":
      return "juno.input.style.recraft.realisticImageForestLife";
    case "realistic_image/mystic_naturalism":
      return "juno.input.style.recraft.realisticImageMysticNaturalism";
    case "realistic_image/natural_tones":
      return "juno.input.style.recraft.realisticImageNaturalTones";
    case "realistic_image/organic_calm":
      return "juno.input.style.recraft.realisticImageOrganicCalm";
    case "realistic_image/real_life_glow":
      return "juno.input.style.recraft.realisticImageRealLifeGlow";
    case "realistic_image/retro_realism":
      return "juno.input.style.recraft.realisticImageRetroRealism";
    case "realistic_image/retro_snapshot":
      return "juno.input.style.recraft.realisticImageRetroSnapshot";
    case "realistic_image/urban_drama":
      return "juno.input.style.recraft.realisticImageUrbanDrama";
    case "realistic_image/village_realism":
      return "juno.input.style.recraft.realisticImageVillageRealism";
    case "realistic_image/warm_folk":
      return "juno.input.style.recraft.realisticImageWarmFolk";
    case "digital_illustration":
      return "juno.input.style.recraft.digitalIllustration";
    case "digital_illustration/pixel_art":
      return "juno.input.style.recraft.digitalIllustrationPixelArt";
    case "digital_illustration/hand_drawn":
      return "juno.input.style.recraft.digitalIllustrationHandDrawn";
    case "digital_illustration/grain":
      return "juno.input.style.recraft.digitalIllustrationGrain";
    case "digital_illustration/infantile_sketch":
      return "juno.input.style.recraft.digitalIllustrationInfantileSketch";
    case "digital_illustration/2d_art_poster":
      return "juno.input.style.recraft.digitalIllustration2DArtPoster";
    case "digital_illustration/2d_art_poster_2":
      return "juno.input.style.recraft.digitalIllustration2DArtPoster2";
    case "digital_illustration/handmade_3d":
      return "juno.input.style.recraft.digitalIllustrationHandmade3D";
    case "digital_illustration/hand_drawn_outline":
      return "juno.input.style.recraft.digitalIllustrationHandDrawnOutline";
    case "digital_illustration/engraving_color":
      return "juno.input.style.recraft.digitalIllustrationEngravingColor";
    case "digital_illustration/antiquarian":
      return "juno.input.style.recraft.digitalIllustrationAntiquarian";
    case "digital_illustration/bold_fantasy":
      return "juno.input.style.recraft.digitalIllustrationBoldFantasy";
    case "digital_illustration/child_book":
      return "juno.input.style.recraft.digitalIllustrationChildBook";
    case "digital_illustration/child_books":
      return "juno.input.style.recraft.digitalIllustrationChildBooks";
    case "digital_illustration/cover":
      return "juno.input.style.recraft.digitalIllustrationCover";
    case "digital_illustration/crosshatch":
      return "juno.input.style.recraft.digitalIllustrationCrosshatch";
    case "digital_illustration/digital_engraving":
      return "juno.input.style.recraft.digitalIllustrationDigitalEngraving";
    case "digital_illustration/expressionism":
      return "juno.input.style.recraft.digitalIllustrationExpressionism";
    case "digital_illustration/freehand_details":
      return "juno.input.style.recraft.digitalIllustrationFreehandDetails";
    case "digital_illustration/grain_20":
      return "juno.input.style.recraft.digitalIllustrationGrain20";
    case "digital_illustration/graphic_intensity":
      return "juno.input.style.recraft.digitalIllustrationGraphicIntensity";
    case "digital_illustration/hard_comics":
      return "juno.input.style.recraft.digitalIllustrationHardComics";
    case "digital_illustration/long_shadow":
      return "juno.input.style.recraft.digitalIllustrationLongShadow";
    case "digital_illustration/modern_folk":
      return "juno.input.style.recraft.digitalIllustrationModernFolk";
    case "digital_illustration/multicolor":
      return "juno.input.style.recraft.digitalIllustrationMulticolor";
    case "digital_illustration/neon_calm":
      return "juno.input.style.recraft.digitalIllustrationNeonCalm";
    case "digital_illustration/noir":
      return "juno.input.style.recraft.digitalIllustrationNoir";
    case "digital_illustration/nostalgic_pastel":
      return "juno.input.style.recraft.digitalIllustrationNostalgicPastel";
    case "digital_illustration/outline_details":
      return "juno.input.style.recraft.digitalIllustrationOutlineDetails";
    case "digital_illustration/pastel_gradient":
      return "juno.input.style.recraft.digitalIllustrationPastelGradient";
    case "digital_illustration/pastel_sketch":
      return "juno.input.style.recraft.digitalIllustrationPastelSketch";
    case "digital_illustration/pop_art":
      return "juno.input.style.recraft.digitalIllustrationPopArt";
    case "digital_illustration/pop_renaissance":
      return "juno.input.style.recraft.digitalIllustrationPopRenaissance";
    case "digital_illustration/street_art":
      return "juno.input.style.recraft.digitalIllustrationStreetArt";
    case "digital_illustration/tablet_sketch":
      return "juno.input.style.recraft.digitalIllustrationTabletSketch";
    case "digital_illustration/urban_glow":
      return "juno.input.style.recraft.digitalIllustrationUrbanGlow";
    case "digital_illustration/urban_sketching":
      return "juno.input.style.recraft.digitalIllustrationUrbanSketching";
    case "digital_illustration/vanilla_dreams":
      return "juno.input.style.recraft.digitalIllustrationVanillaDreams";
    case "digital_illustration/young_adult_book":
      return "juno.input.style.recraft.digitalIllustrationYoungAdultBook";
    case "digital_illustration/young_adult_book_2":
      return "juno.input.style.recraft.digitalIllustrationYoungAdultBook2";
    case "vector_illustration":
      return "juno.input.style.recraft.vectorIllustration";
    case "vector_illustration/bold_stroke":
      return "juno.input.style.recraft.vectorIllustrationBoldStroke";
    case "vector_illustration/chemistry":
      return "juno.input.style.recraft.vectorIllustrationChemistry";
    case "vector_illustration/colored_stencil":
      return "juno.input.style.recraft.vectorIllustrationColoredStencil";
    case "vector_illustration/contour_pop_art":
      return "juno.input.style.recraft.vectorIllustrationContourPopArt";
    case "vector_illustration/cosmics":
      return "juno.input.style.recraft.vectorIllustrationCosmics";
    case "vector_illustration/cutout":
      return "juno.input.style.recraft.vectorIllustrationCutout";
    case "vector_illustration/depressive":
      return "juno.input.style.recraft.vectorIllustrationDepressive";
    case "vector_illustration/editorial":
      return "juno.input.style.recraft.vectorIllustrationEditorial";
    case "vector_illustration/emotional_flat":
      return "juno.input.style.recraft.vectorIllustrationEmotionalFlat";
    case "vector_illustration/infographical":
      return "juno.input.style.recraft.vectorIllustrationInfographical";
    case "vector_illustration/marker_outline":
      return "juno.input.style.recraft.vectorIllustrationMarkerOutline";
    case "vector_illustration/mosaic":
      return "juno.input.style.recraft.vectorIllustrationMosaic";
    case "vector_illustration/naivector":
      return "juno.input.style.recraft.vectorIllustrationNaivector";
    case "vector_illustration/roundish_flat":
      return "juno.input.style.recraft.vectorIllustrationRoundishFlat";
    case "vector_illustration/segmented_colors":
      return "juno.input.style.recraft.vectorIllustrationSegmentedColors";
    case "vector_illustration/sharp_contrast":
      return "juno.input.style.recraft.vectorIllustrationSharpContrast";
    case "vector_illustration/thin":
      return "juno.input.style.recraft.vectorIllustrationThin";
    case "vector_illustration/vector_photo":
      return "juno.input.style.recraft.vectorIllustrationVectorPhoto";
    case "vector_illustration/vivid_shapes":
      return "juno.input.style.recraft.vectorIllustrationVividShapes";
    case "vector_illustration/engraving":
      return "juno.input.style.recraft.vectorIllustrationEngraving";
    case "vector_illustration/line_art":
      return "juno.input.style.recraft.vectorIllustrationLineArt";
    case "vector_illustration/line_circuit":
      return "juno.input.style.recraft.vectorIllustrationLineCircuit";
    case "vector_illustration/linocut":
      return "juno.input.style.recraft.vectorIllustrationLinocut";
    default:
      return styleParam;
  }
};
