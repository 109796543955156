import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Container, Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Define the props interface for CreditItemRow
interface CreditItemRowProps {
  title?: string;
  description: string;
  cost?: string;
  tooltip?: string;
}

// Reusable component for credit items
const CreditItemRow: React.FC<CreditItemRowProps> = ({ title, description, cost, tooltip }) => (
  <Grid container spacing={2} justifyContent="center" px={1} mb={2}>
    {title && (
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
    )}
    {description && (
      <Grid item xs={10} md={9}>
        <Typography>{description}</Typography>
      </Grid>
    )}
    {cost && (
      <Grid item xs={2} md={3}>
        <Typography textAlign="right">
          {cost}
          {tooltip && (
            <Tooltip title={tooltip}>
              <IconButton size="small" sx={{ ml: 1 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      </Grid>
    )}
  </Grid>
);

// Data structures for different credit categories
const creditData = {
  text: {
    sectionTitle: "pricing.aboutCredit.text.title",
    items: [
      {
        description: "pricing.aboutCredit.text.description",
        cost: "0.1 / 4K tokens",
        tooltip: "pricing.aboutCredit.text.tooltip",
      },
    ],
  },
  images: {
    sectionTitle: "pricing.aboutCredit.image.title",
    items: [
      {
        description: "pricing.aboutCredit.image.flux11proUltra",
        cost: "7.5 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux11proUltraRedux",
        cost: "6.3 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1pro",
        cost: "5 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1proFill",
        cost: "6.3 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1proCanny",
        cost: "6.3 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1proDepth",
        cost: "6.3 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1dev",
        cost: "3.2 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1devRedux",
        cost: "3.2 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1devFill",
        cost: "5 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1devCanny",
        cost: "5 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1devDepth",
        cost: "5 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1Schnell",
        cost: "0.4 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1SchnellRedux",
        cost: "3.2 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1lora",
        cost: "4.4 / img",
      },
      {
        description: "pricing.aboutCredit.image.ideogram2",
        cost: "10 / img",
      },
      {
        description: "pricing.aboutCredit.image.ideogram2turbo",
        cost: "6.3 / img",
      },
      {
        description: "pricing.aboutCredit.image.flux1juno",
        cost: "4.4 / img",
      },
      {
        description: "pricing.aboutCredit.image.fluxPulID",
        cost: "4.4 / img",
      },
      {
        description: "pricing.aboutCredit.image.dalle3",
        cost: "15 / req",
      },
      {
        description: "pricing.aboutCredit.image.recraftV3",
        cost: "7.5 / img",
      },
      {
        description: "pricing.aboutCredit.image.sdxl",
        cost: "4 / req",
      },
      {
        description: "pricing.aboutCredit.image.sd3",
        cost: "8.2 / req",
      },
      {
        description: "pricing.aboutCredit.image.sdUltra",
        cost: "10 / req",
      },
      {
        description: "pricing.aboutCredit.image.auraSR",
        cost: "0.25 / 1MP",
      },
      {
        description: "pricing.aboutCredit.image.clarityUpscaler",
        cost: "2.5 / 1MP",
      },
      {
        description: "pricing.aboutCredit.image.creativeUpscaler",
        cost: "2.8 / 1MP",
      },
    ],
  },
  videos: {
    sectionTitle: "pricing.aboutCredit.video.title",
    items: [
      {
        description: "pricing.aboutCredit.video.runway",
        cost: "17.9 / 5sec",
      },
      {
        description: "pricing.aboutCredit.video.kling1standard",
        cost: "10.8 / 5sec",
      },
      {
        description: "pricing.aboutCredit.video.kling1pro",
        cost: "44.7 / 5sec",
      },
      {
        description: "pricing.aboutCredit.video.dreamMachine15",
        cost: "35.8 / 5sec",
      },
      {
        description: "pricing.aboutCredit.video.hailuo",
        cost: "35.8 / 5sec",
      },
    ],
  },
  voice: {
    sectionTitle: "pricing.aboutCredit.voice.title",
    items: [
      {
        description: "pricing.aboutCredit.voice.google",
        cost: "2",
      },
      {
        description: "pricing.aboutCredit.voice.openai",
        cost: "3.75",
      },
      {
        description: "pricing.aboutCredit.voice.openai2",
        cost: "20",
      },
    ],
  },
  mindmap: {
    sectionTitle: "pricing.aboutCredit.mindmap.title",
    description: "pricing.aboutCredit.mindmap.description",
    cost: "0",
  },
};

const AboutCredit: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Paper elevation={1} sx={{ py: 8, boxShadow: 0, borderRadius: 0 }}>
      <Container maxWidth="md">
        {/* クレジットについて */}
        <Box>
          <Typography variant="h4" gutterBottom>
            {t("pricing.aboutCredit.title")}
          </Typography>
          <Typography paragraph sx={{ my: 4 }}>
            {t("pricing.aboutCredit.description")}
          </Typography>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Text Credit */}
        <CreditItemRow
          title={t(creditData.text.sectionTitle)}
          description=""
          cost=""
          tooltip={t("pricing.aboutCredit.text.tooltip")}
        />
        {creditData.text.items.map((item, index) => (
          <CreditItemRow
            key={`text-${index}`}
            description={t(item.description)}
            cost={item.cost}
            tooltip={t(item.tooltip)}
          />
        ))}

        <Divider sx={{ mb: 2 }} />

        {/* 画像生成 */}
        <CreditItemRow title={t(creditData.images.sectionTitle)} description="" cost="" />
        {creditData.images.items.map((item, index) => (
          <CreditItemRow key={`image-${index}`} description={t(item.description)} cost={item.cost} />
        ))}

        <Divider sx={{ mb: 2 }} />

        {/* 動画生成 */}
        <CreditItemRow title={t(creditData.videos.sectionTitle)} description="" cost="" />
        {creditData.videos.items.map((item, index) => (
          <CreditItemRow key={`video-${index}`} description={t(item.description)} cost={item.cost} />
        ))}

        <Divider sx={{ mb: 2 }} />

        {/* 音声生成 */}
        <CreditItemRow title={t(creditData.voice.sectionTitle)} description="" cost="" />
        {creditData.voice.items.map((item, index) => (
          <CreditItemRow key={`voice-${index}`} description={t(item.description)} cost={item.cost} />
        ))}

        <Divider sx={{ mb: 2 }} />

        {/* Mindmap生成 */}
        <CreditItemRow
          title={t(creditData.mindmap.sectionTitle)}
          description={t(creditData.mindmap.description)}
          cost={creditData.mindmap.cost}
        />

        <Divider sx={{ mb: 1 }} />

        {/* Annotation */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="caption">{t("pricing.aboutCredit.annotation")}</Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default AboutCredit;
