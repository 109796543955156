import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { Avatar, Box, CircularProgress, Divider, Grid, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { ImageInfo } from "../../../../types/junoTypes";
import { useLoginStatus } from "../../../../context/LoginStatusContext";
import { localDate } from "../../../../utils/utils";
import ActionLabels from "./ActionLabels";
import Like from "./Like";
import { useCommonContext } from "../../../../context/juno/CommonContext";
import HistoryParams from "./HistoryParams";
import HistoryActions from "./HistoryActions";
import HistoryParamsModel from "./HistoryParamsModel";

type ImageModalProps = {
  images: ImageInfo[];
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ImageModalDetails = ({ images, activeIndex, setActiveIndex, open, setOpen }: ImageModalProps) => {
  const { t } = useTranslation();
  const { user } = useLoginStatus();
  const theme = useTheme();
  const { setRefreshTrigger } = useCommonContext();
  const uiLanguage = Cookies.get("ui_language") || "en";
  const { setAlert } = useAlert();
  const [userInfo, setUserInfo] = useState<any>();

  useEffect(() => {
    if (open && images[activeIndex]) {
      // アクティブ画像が変更されたら、ユーザー情報を取得する
      getUserInfo(images[activeIndex].uuid).then((userInfo) => setUserInfo(userInfo));
    }
  }, [activeIndex, images, open]);

  const [params, setParams] = useState<any>(images[activeIndex]?.params || {});

  // パラメータが変更されたら、それを更新する
  useEffect(() => {
    setParams(images[activeIndex]?.params);
  }, [images, activeIndex]);

  const handleModalClose = () => {
    setOpen(false);
    setActiveIndex(0);
  };

  // ユーザー情報取得
  const getUserInfo = async (imageUuid: string) => {
    try {
      const url = `/api/v1/juno/user-info?uuid=${imageUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const userInfo = await response.json();
      return userInfo;
    } catch (e) {
      console.error(e);
    }
  };

  const [deleting, setDeleting] = useState<boolean>(false);

  // 画像単体削除
  const handleDeleteImg = async () => {
    try {
      setDeleting(true);
      const uuid = images[activeIndex].uuid;
      const url = `/api/v1/juno/image/${uuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      const result = await response.json();
      if (result.success) {
        setOpen(false);
        setActiveIndex(0);
        setRefreshTrigger(new Date().getTime());
        setAlert("success", t("juno.message.delete"));
        setDeleting(false);
      }
    } catch (e) {
      setDeleting(false);
      console.error(e);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }} gap={1}>
        <ActionLabels params={params} onClose={handleModalClose} />
        <Box flexGrow={1} />
        <IconButton onClick={() => handleModalClose()} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* info */}
      <Box display={"flex"} flexDirection={"row"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
          gap={4}
        >
          <Avatar src={userInfo?.avatar}>
            {userInfo?.username != "unknown_user" ? userInfo?.username.slice(0, 2) : ""}
          </Avatar>
          <Typography variant={"body1"} component={"p"}>
            @{userInfo?.username}
            {userInfo?.username === "unknown_user" && (
              <Tooltip title={"The user who generated this image has deleted his account and is therefore anonymous."}>
                <IconButton size={"small"}>
                  <InfoIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            )}
          </Typography>

          <Typography
            variant={"caption"}
            component={"p"}
            sx={{
              border: "1px solid",
              borderRadius: 1,
              px: 1,
              borderColor: images[activeIndex]?.is_public ? theme.palette.success.main : theme.palette.info.main,
              color: images[activeIndex]?.is_public ? theme.palette.success.main : theme.palette.info.main,
              py: 0.2,
            }}
          >
            {images[activeIndex]?.is_public ? t("juno.input.public") : t("juno.input.private")}
          </Typography>

          <Box flexGrow={1} />

          <Typography variant={"body1"} component={"p"}>
            {images[activeIndex] && localDate(images[activeIndex]["created_at"], uiLanguage)}
          </Typography>
          <Like uuid={images[activeIndex]?.uuid} onImage={false} />
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* model */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "calc(100% - 235px)",
          overflowY: "auto",
        }}
      >
        <HistoryParamsModel params={params} setOpen={setOpen} />

        {/* プロンプト */}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2, width: "100%" }} gap={2}>
          {params?.["prompt"] && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <TextField
                label={t("juno.history.prompt")}
                fullWidth
                multiline
                rows={5}
                value={params?.["prompt"]}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}

          {/* ネガティブプロンプト */}
          {params?.["negative_prompt"] && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <TextField
                label={t("juno.input.negativePrompt")}
                fullWidth
                multiline
                rows={5}
                value={params?.["negative_prompt"]}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}
        </Box>

        {/* 各パラメータ */}
        <Box>
          <HistoryParams params={params} grid={3} />
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
        {/* アクション */}
        <Grid container spacing={1} mb={2}>
          <HistoryActions
            params={params}
            grid={3}
            request={images}
            targetIndex={activeIndex}
            onClose={handleModalClose}
          />
          {userInfo?.username === user?.username && (
            <Grid item xs={12} md={3}>
              <Button
                size={"small"}
                disableElevation
                variant={"outlined"}
                disabled={deleting}
                onClick={() => handleDeleteImg()}
                color={"error"}
                startIcon={deleting ? <CircularProgress size={20} /> : <DeleteIcon />}
                fullWidth
              >
                <Typography variant={"caption"} fontWeight={600}>
                  {t("common.delete")}
                </Typography>
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <Button
              size={"small"}
              disableElevation
              variant={"outlined"}
              fullWidth
              onClick={() => handleModalClose()}
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <CloseIcon fontSize="small" />
              <Typography variant={"caption"} fontWeight={600}>
                {t("common.close")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ImageModalDetails;
