import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../utils/styledBox";
import AspectRatioIcons from "./AspectRatioIcons";
import ImageUpload from "./ImageUpload";

const ParamsHailuo = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, duration, modelVersion } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [{ ratio: "AUTO", icon: HdrAutoIcon }];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 10,
      label: "10s",
    },
  ];

  // T2V の HAILUO MINI_MAX_REF は MINI_MAX_LIVE に変更
  useEffect(() => {
    if (menu === "t2v" && modelVersion.HAILUO === "MINI_MAX_REF") {
      updateParams("HAILUO", "modelVersion", "MINI_MAX_LIVE");
    }
  }, [menu, modelVersion]);

  return (
    <ColumnBox sx={{ gap: 4 }}>
      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {modelVersion.HAILUO == "MINI_MAX_REF" ? t("juno.video.params.uploadFace") : t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <Select
          value={modelVersion.HAILUO}
          onChange={(e) => updateParams("HAILUO", "modelVersion", e.target.value)}
          variant="standard"
          fullWidth
        >
          <MenuItem value={"MINI_MAX_LIVE"}>{t("juno.video.ai.hailuo.model.MINI_MAX_LIVE")}</MenuItem>
          <MenuItem value={"MINI_MAX_REF"} disabled={menu == "t2v"}>
            {t("juno.video.ai.hailuo.model.MINI_MAX_REF")}
          </MenuItem>
          <MenuItem value={"MINI_MAX"}>{t("juno.video.ai.hailuo.model.MINI_MAX")}</MenuItem>
        </Select>
      </ColumnBox>

      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={10}
          step={5}
          value={duration.HAILUO}
          onChange={(_, value) => updateParams("HAILUO", "duration", value)}
          disabled
        />
      </ColumnBox>
      <ColumnBox>
        {/* aspect */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.aspect")}
        </Typography>
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsHailuo;
