const colorPalette = {
  title: "Color Palette Generator",
  description: "Generates color palettes from keywords with a psychological perspective by Tenak AI",
  placeholder: "e.g. Autumn vibes in pastel colors",
  generate: "Generate Color Palette",
  generated: "Generated Color Palette",
  caution: "* The generated color palette will be published.",
  colorDescription: "* From left to right, main color, sub color, sub color 2, accent color, accent color 2",
  list: "Everyone's Color Palette List",
  samples: "Color Palette Samples",
};
export default colorPalette;
