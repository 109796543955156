import { CircularProgress, Fade, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnCenteredBox, RowCenteredBox } from "../../../utils/styledBox";

interface ChatStatusProps {
  aiStatus: string | null;
}

const ChatStatus: React.FC<ChatStatusProps> = ({ aiStatus }) => {
  const { t } = useTranslation();
  const getStatusText = (status: string): string => {
    switch (status) {
      case "searching":
        return t("browsing.aiStatus.searching");
      case "reading":
        return t("browsing.aiStatus.reading");
      case "analyzing":
        return t("browsing.aiStatus.analyzing");
      case "thinking":
        return t("browsing.aiStatus.thinking");
      default:
        return status;
    }
  };

  return (
    <Fade in={!!aiStatus} timeout={300} unmountOnExit>
      <ColumnCenteredBox sx={{ position: "relative", bottom: 0, width: "100%" }}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={50}
          sx={{
            borderRadius: 1,
          }}
        />
        <RowCenteredBox
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 50,
            zIndex: 1000,
          }}
        >
          <CircularProgress size={20} sx={{ mr: 2 }} />
          <Typography
            component={"p"}
            variant="subtitle2"
            color="textSecondary"
            textAlign={"left"}
            fontWeight={600}
            key={aiStatus}
          >
            {aiStatus && `${getStatusText(aiStatus)}...`}
          </Typography>
        </RowCenteredBox>
      </ColumnCenteredBox>
    </Fade>
  );
};

export default ChatStatus;
