import { Box, Paper, Typography } from "@mui/material";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import ChatInput from "./ChatInput";

type ChatMode = "llm" | "web" | "youtube" | "research";

interface ChatAreaProps {
  messages: { user: string; text: string }[];
  inputValue: string;
  onInputChange: (value: string) => void;
  onSubmit: (images?: File[], files?: File[]) => void;
  drawerWidth: number;
  onModeChange?: (mode: ChatMode) => void;
}

const ChatArea = ({ messages, inputValue, onInputChange, onSubmit, onModeChange }: ChatAreaProps) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        px: 2,
        width: { sm: "100%" },
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DrawerHeader />
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          p: 2,
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {messages.map((msg, index) => (
          <Paper
            key={index}
            elevation={1}
            sx={{
              p: 2,
              mb: 2,
              maxWidth: "80%",
              ml: msg.user === "You" ? "auto" : 0,
              backgroundColor: msg.user === "You" ? "primary.light" : "background.paper",
              color: msg.user === "You" ? "primary.contrastText" : "text.primary",
            }}
          >
            <Typography variant="body2">{msg.text}</Typography>
          </Paper>
        ))}
      </Box>
      <ChatInput
        inputValue={inputValue}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        onModeChange={onModeChange}
      />
    </Box>
  );
};

export default ChatArea;
