import React from "react";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";

const AnnouncementIcon = (color) => {
  const navigate = useCustomNavigate();

  return (
    <IconButton onClick={() => navigate("/announcements")} size="large" sx={{ color: color }}>
      <NotificationsIcon />
    </IconButton>
  );
};
export default AnnouncementIcon;
