import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import { MarkdownContentBox } from "../../../components/common/MarkdownContentBox";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";

import { AnswerBoxProps } from "../../../types/chatTypes";

export default function AnswerBox({ historyItem, processingId, setSelectSourceId }: AnswerBoxProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const handleNavigate = (key: string) => {
    switch (key) {
      case "article":
        navigate("/writing-ai/article", { state: { text: historyItem.content } });
        break;
      case "mindMap":
        navigate("/mind-map-generator", { state: { text: historyItem.content } });
        break;
      case "summary":
        navigate("/writing-ai/summarizer", { state: { text: historyItem.content } });
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      data-id={historyItem.message_uuid}
      elevation={theme.palette.mode === "dark" ? 2 : 0}
      sx={{
        px: { xs: 2, sm: 3 },
        pt: 2,
        pb: 2,
        mb: 1,
        transition: "all 0.3s ease",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "left",
          flexDirection: "row",
        }}
      >
        <Avatar alt="User avatar" src="/images/logo/logoZI-small.png" sx={{ width: 32, height: 32 }} />
        <Typography variant="subtitle1" component={"p"} sx={{ ml: 2 }} color={"textSecondary"}>
          {t("title")}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" justifyContent="right" alignItems={"flex-start"} gap={1}>
          <Tooltip title={t("browsing.article")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("article")}>
              <ArticleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.mindMap")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("mindMap")}>
              <AccountTreeOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.summary")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("summary")}>
              <ShortTextIcon />
            </IconButton>
          </Tooltip>

          <CopyButtonOrIcon displayType={"icon"} textToCopy={historyItem.content} size={"small"} />
        </Box>
      </Box>
      <Box flexDirection={"column"} justifyContent={"left"} display={"flex"} sx={{ overflowWrap: "break-word" }} mt={1}>
        {historyItem.content && historyItem.content.length > 0 && (
          <>
            <MarkdownContentBox>{historyItem.content}</MarkdownContentBox>
            {historyItem.search_results && (
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setSelectSourceId(historyItem.message_uuid ?? "")}
                >
                  <Typography variant="button">SOURCE</Typography>
                </Button>
              </Box>
            )}
          </>
        )}

        {(!historyItem.content || historyItem.content.length === 0) && (
          <Typography variant="body2" textAlign="left" color={"error"} sx={{ fontWeight: "bold", ml: 2, mt: 2 }}>
            No answer
          </Typography>
        )}
      </Box>
    </Paper>
  );
}
