import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { GradationButton } from "../../utils/gradationButton";
import { formatBytes, getFileDateName, localDate } from "../../utils/utils";

export default function Voice() {
  const { t } = useTranslation();
  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });
  const [voices, setVoices] = React.useState([]);
  const location = useLocation();
  const [playingAudio, setPlayingAudio] = React.useState<HTMLAudioElement | null>(null);
  const [playingUuid, setPlayingUuid] = React.useState<string | null>(null);
  const [loadingUuid, setLoadingUuid] = React.useState<string | null>(null);
  const [audioCache, setAudioCache] = React.useState<{ [key: string]: string }>({});
  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(0);
  const [rows, setRows] = React.useState({} as { [key: string]: number });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const { setAlert } = useAlert();
  const itemBoxRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const uiLanguage = Cookies.get("ui_language") || "en";
  const navigate = useCustomNavigate();
  const [disabledDeleteButton, setDisabledDeleteButton] = useState<{ [key: string]: boolean }>({});

  const fetchData = async (url: string, method: string, body: any = {}, responseType = "json") => {
    try {
      const options: RequestInit = {
        method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);

      if (responseType === "blob") {
        return await response.blob();
      } else {
        const responseData = await response.json();
        return responseData.success ? responseData.data : [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    const TableHeight = itemBoxRef.current!.offsetHeight;
    let items = Math.floor(TableHeight / 100);
    items = items < 10 ? 10 : items;
    setItemsPerPage(items);
  }, [itemBoxRef]);

  useEffect(() => {
    // ページ遷移が発生したときに実行される
    return () => {
      // 音声が再生中の場合、停止する
      if (playingAudio) {
        playingAudio.pause();
        setPlayingAudio(null);
      }
    };
  }, [location, playingAudio]);

  const getVoices = async () => {
    return await fetchData("/api/v1/voice-generator/voices", "GET");
  };

  useEffect(() => {
    getVoices().then((voices) => {
      setVoices(voices);
    });
  }, []);

  // 秒数を整数にする
  function customRound(num: number) {
    if (num - Math.floor(num) >= 0.001) {
      return Math.ceil(num);
    } else {
      return Math.floor(num);
    }
  }

  // ダウンロード
  const downloadVoice = async (uuid: string) => {
    const blob = await fetchData("/api/v1/voice-generator/download/" + uuid, "GET", {}, "blob");
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "voice-" + getFileDateName() + ".mp3";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  // 再生操作
  const toggleAudio = (uuid: string) => {
    if (playingUuid === uuid) {
      // 同じ音声を停止
      playingAudio?.pause();
      setPlayingAudio(null);
      setPlayingUuid(null);
    } else {
      // 新しい音声を再生
      playAudio(uuid);
    }
  };

  // 再生
  const playAudio = async (uuid: string) => {
    setLoadingUuid(uuid);

    // 現在の音声を停止
    if (playingAudio) {
      playingAudio.pause();
      setPlayingAudio(null);
    }

    // キャッシュから音声を取得したい
    let url = audioCache[uuid];

    // キャッシュが存在しない場合は新しい音声をダウンロード
    if (!url) {
      const blob = await fetchData("/api/v1/voice-generator/download/" + uuid, "GET", {}, "blob");
      url = window.URL.createObjectURL(blob);

      // キャッシュに保存
      setAudioCache((prevState) => ({
        ...prevState,
        [uuid]: url,
      }));
    }

    const audio = new Audio(url);
    audio.play();

    setPlayingAudio(audio);
    setPlayingUuid(uuid);
    setLoadingUuid(null);

    audio.onended = () => {
      setPlayingAudio(null);
      setPlayingUuid(null);
    };
  };

  // 削除確認ダイアログを開く
  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  // 削除
  const handleConfirmedDelete = async (uuid: string) => {
    try {
      setDisabledDeleteButton((prevState) => ({
        ...prevState,
        [uuid]: true,
      }));
      const response = await fetch("/api/v1/voice-generator/delete/" + uuid, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken!,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result && result.success) {
        setAlert("success", t("library.voice.delete.success"));
        setVoices(voices.filter((voice: any) => voice.uuid !== uuid));
        setDisabledDeleteButton((prevState) => ({
          ...prevState,
          [uuid]: false,
        }));
      } else {
        setAlert("error", t("library.voice.delete.error"));
        setDisabledDeleteButton((prevState) => ({
          ...prevState,
          [uuid]: false,
        }));
      }
    } catch (error) {
      console.error("Error during the fetch operation:", error);
      setAlert("error", t("library.voice.delete.error"));
      setDisabledDeleteButton((prevState) => ({
        ...prevState,
        [uuid]: false,
      }));
    }
  };

  const googleVoiceLabel = (gender: string, type: string, voice_number: string) => {
    return t(`voice.googleVoiceLabel.${type}`) + " - " + t(`voice.gender.${gender}`) + " " + voice_number;
  };

  const openaiVoiceLabel = (name: string, gender: string) => {
    return t(`voice.openaiVoiceLabel.${name}`) + " - " + t(`voice.gender.${gender}`);
  };

  // ページ切り替え
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // テキスト行数変更
  const changeRows = (id: string) => {
    setRows((prevState) => ({
      ...prevState,
      [id]: 10,
    }));
  };

  // テキスト行数リセット
  const resetRows = (id: string) => {
    setRows((prevState) => ({
      ...prevState,
      [id]: 2,
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const [expanded, setExpanded] = React.useState({} as { [key: string]: boolean });

  return (
    <>
      <Meta
        title={t("library.voice.title") + " " + t("drawer.library")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
      />
      <DrawerHeader />
      <Grow in={true}>
        <Box
          sx={{
            height: "100%",
            backgroundColor: "background.paper",
            mx: { xs: 0, sm: 2 },
            mb: { xs: 0, sm: 2 },
            borderRadius: { xs: 0, sm: 2 },
          }}
        >
          <Box sx={{ mx: { xs: 1, md: 2 }, display: "flex", flexDirection: "column" }}>
            <Box sx={{ justifyContent: "space-between", display: "flex", mt: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <GraphicEqIcon sx={{ mr: 1 }} color={"secondary"} />
                <Typography variant="h6" component={"h2"} sx={{ position: "relative" }}>
                  {t("library.voice.title")}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                {t("library.voice.description")}
              </Typography>
              <GradationButton onClick={() => navigate("/voice-generator")} variant="contained">
                <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
                {t("voice.title")}
              </GradationButton>
            </Box>

            <TableContainer sx={{ maxHeight: { xs: `calc(100vh - 158px)`, sm: `calc(100vh - 174px)` } }}>
              <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "background.paper" }}>No</TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.name")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.text")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.seconds")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.speakingRate")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.pitch")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.voice_name")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.created_at")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper" }} align="right">
                      {t("library.voice.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voices
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage) // Add this line
                    .map((voice: any, index) => (
                      <StyledTableRow key={voice.uuid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <StyledTableCell align="center">{(page - 1) * itemsPerPage + index + 1}</StyledTableCell>
                        <StyledTableCell align="right">
                          <TextField
                            multiline
                            maxRows={2}
                            value={voice.uuid}
                            variant="standard"
                            size={"small"}
                            fullWidth
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ minWidth: 400 }}>
                          <TextField
                            multiline
                            rows={rows[voice.uuid] || 2}
                            value={voice.text}
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <IconButton
                                  onClick={() => {
                                    setExpanded((prevState) => ({
                                      ...prevState,
                                      [voice.uuid]: !prevState[voice.uuid],
                                    }));
                                    if (expanded[voice.uuid]) {
                                      resetRows(voice.uuid);
                                    } else {
                                      changeRows(voice.uuid);
                                    }
                                  }}
                                >
                                  {expanded[voice.uuid] ? (
                                    <UnfoldLessIcon fontSize={"small"} />
                                  ) : (
                                    <UnfoldMoreIcon fontSize={"small"} />
                                  )}
                                </IconButton>
                              ),
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="right">{customRound(voice.seconds)}</StyledTableCell>
                        <StyledTableCell align="right">{voice.speaking_rate}</StyledTableCell>
                        <StyledTableCell align="right">{voice.pitch}</StyledTableCell>
                        <StyledTableCell align="right">
                          {voice.provider === "openai" && openaiVoiceLabel(voice.voice_name, voice.gender)}
                          {voice.provider === "google" && (
                            <>
                              {voice.language_label} <br />
                              {googleVoiceLabel(voice.gender, voice.type, voice.number)}
                            </>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">{localDate(voice["created_at"], uiLanguage)}</StyledTableCell>
                        <StyledTableCell align="right">
                          <Tooltip title={t("common.play") + " / " + t("common.stop")}>
                            <IconButton onClick={() => toggleAudio(voice.uuid)}>
                              {playingUuid === voice.uuid ? (
                                <StopCircleIcon color={"error"} />
                              ) : loadingUuid === voice.uuid ? (
                                <CircularProgress size={24} />
                              ) : (
                                <PlayCircleIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("common.download") + ": " + formatBytes(voice.size)}>
                            <IconButton onClick={(e) => downloadVoice(voice.uuid)}>
                              <DownloadForOfflineIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("common.delete")}>
                            <span>
                              <IconButton
                                onClick={(e) => handleDialogOpen(voice.uuid)}
                                disabled={disabledDeleteButton[voice.uuid]}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box flexGrow={1} ref={itemBoxRef} />
            <Pagination
              sx={{ my: 1, display: "flex", justifyContent: "center" }}
              count={Math.ceil(voices.length / itemsPerPage) || 1}
              page={page}
              onChange={handleChangePage}
            />
          </Box>

          {/* 削除確認ダイアログ */}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t("library.voice.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("library.voice.delete.description")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
                {t("common.cancel")}
              </Button>
              <Button
                onClick={() => {
                  handleConfirmedDelete(selectedUuid!);
                  setDialogOpen(false);
                }}
                color="error"
              >
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grow>
    </>
  );
}
