import BrushIcon from "@mui/icons-material/Brush";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import RepeatIcon from "@mui/icons-material/Repeat";
import { CircularProgress, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useJunoMethods } from "../../../../hooks/useJunoMethods";
import { getFileDateName } from "../../../../utils/utils";

interface HistoryActionsProps {
  params: any;
  grid: number;
  request: any;
  targetIndex: number;
  onClose?: () => void;
}

const HistoryActions = ({ params, grid, request, targetIndex, onClose }: HistoryActionsProps) => {
  const { t } = useTranslation();
  const { handleDownload, handleImg2Img, handleUpscale, handleSetSameParams, handleImg2Vid, handleEdit } =
    useJunoMethods();
  const [settingI2I, setSettingI2I] = useState(false); // img2img設定中
  const [downloading, setDownloading] = useState<{ [request_uuid: string]: boolean }>({});

  if (!params) return null;

  // 画像をダウンロード
  const handleDownloadImg = async (request) => {
    try {
      if (request[targetIndex].r2_image) {
        downloadR2Image(request);
      } else {
        setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: true }));
        const res = await handleDownload(request[targetIndex]["uuid"]);
        if (res["success"]) {
          setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: false }));
        }
      }
    } catch (e) {
      console.error(e);
      setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: false }));
    }
  };

  const downloadR2Image = async (request) => {
    setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: true }));
    const url = request[targetIndex].r2_image;
    const a = document.createElement("a");
    a.href = url;
    a.download = "juno-" + getFileDateName() + ".png";

    // 画像をバイナリデータとして取得し、Blobに変換
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        a.href = objectURL;
        a.click();
        URL.revokeObjectURL(objectURL); // メモリ解放
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      })
      .finally(() => {
        setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: false }));
        return;
      });
  };

  const setImg2Img = async (request) => {
    try {
      setSettingI2I(true);
      const res = await handleImg2Img(request[targetIndex]["uuid"], request[targetIndex].params);
      setSettingI2I(false);
      onClose && onClose();
    } catch (e) {
      console.error(e);
      setSettingI2I(false);
    }
  };

  const setImg2Vid = async (request) => {
    try {
      setSettingI2I(true);
      await handleImg2Vid(request[targetIndex]["uuid"], request[targetIndex].params["prompt"]);
      setSettingI2I(false);
    } catch (e) {
      console.error(e);
      setSettingI2I(false);
    }
  };

  const handleEditButton = async (request) => {
    try {
      setSettingI2I(true);
      await handleEdit(request[targetIndex]["uuid"]);
      onClose && onClose();
      setSettingI2I(false);
    } catch (e) {
      console.error(e);
      setSettingI2I(false);
    }
  };

  const handleUpscaleImage = async (uuid) => {
    try {
      setSettingI2I(true);
      await handleUpscale(uuid);
      setSettingI2I(false);
    } catch (e) {
      console.error(e);
      setSettingI2I(false);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={grid}>
        <Button
          size={"small"}
          disableElevation
          variant={"outlined"}
          fullWidth
          onClick={() => {
            handleEditButton(request);
          }}
          disabled={
            settingI2I ||
            params["width"] > 3000 ||
            params["height"] > 3000 ||
            request?.[targetIndex]?.request_status === "Processing" ||
            request?.[targetIndex]?.request_status === "Failure" ||
            !!request?.[targetIndex]?.r2_image ||
            params["style"]?.startsWith("vector")
          }
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <BrushIcon fontSize="small" sx={{}} />
          <Typography variant={"caption"} fontWeight={600}>
            {t("juno.history.edit")}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={grid}>
        <Button
          size={"small"}
          disableElevation
          variant={"outlined"}
          fullWidth
          onClick={() => {
            handleUpscaleImage(request[targetIndex]["uuid"]);
            onClose && onClose();
          }}
          disabled={
            settingI2I ||
            params["width"] > 3000 ||
            params["height"] > 3000 ||
            request?.[targetIndex]?.request_status === "Processing" ||
            request?.[targetIndex]?.request_status === "Failure" ||
            !!request?.[targetIndex]?.r2_image ||
            params["style"]?.startsWith("vector")
          }
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <PhotoSizeSelectLargeIcon fontSize="small" />
          <Typography variant={"caption"} fontWeight={600}>
            {t("juno.history.upscale")}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={grid}>
        <Button
          size={"small"}
          disableElevation
          variant={"outlined"}
          fullWidth
          onClick={() => {
            setImg2Img(request);
          }}
          disabled={
            request?.[targetIndex]?.request_status === "Processing" ||
            request?.[targetIndex]?.request_status === "Failure" ||
            !!request?.[targetIndex]?.r2_image ||
            settingI2I ||
            params["style"]?.startsWith("vector")
          }
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <ImageIcon fontSize="small" />
          <Typography variant={"caption"} fontWeight={600}>
            {t("juno.history.img2img")}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={grid}>
        <Button
          size={"small"}
          disableElevation
          variant={"outlined"}
          fullWidth
          onClick={() => {
            setImg2Vid(request);
          }}
          disabled={
            request?.[targetIndex]?.request_status === "Processing" ||
            request?.[targetIndex]?.request_status === "Failure" ||
            !!request?.[targetIndex]?.r2_image ||
            settingI2I ||
            params["style"]?.startsWith("vector")
          }
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <MovieIcon fontSize="small" />
          <Typography variant={"caption"} fontWeight={600}>
            {t("juno.history.img2vid")}
          </Typography>
        </Button>
      </Grid>

      <Grid item xs={12} sm={grid}>
        <Button
          size={"small"}
          disableElevation
          variant={"outlined"}
          fullWidth
          onClick={() => {
            handleSetSameParams(params);
            onClose && onClose();
          }}
          disabled={params["action"] === "upscale"}
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <RepeatIcon fontSize="small" />
          <Typography variant={"caption"} fontWeight={600}>
            {t("juno.history.useParams")}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={grid}>
        <Button
          size={"small"}
          disableElevation
          variant={"contained"}
          fullWidth
          onClick={() => handleDownloadImg(request)}
          disabled={
            request?.[targetIndex]?.request_status === "Processing" ||
            request?.[targetIndex]?.request_status === "Failure" ||
            (request?.[targetIndex] && downloading[request[targetIndex]?.request_uuid])
          }
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          {downloading[request[targetIndex]?.request_uuid] ? (
            <CircularProgress size={20} />
          ) : (
            <DownloadIcon fontSize="small" />
          )}
          <Typography variant={"caption"} fontWeight={600}>
            {t("juno.history.download")}
          </Typography>
        </Button>
      </Grid>
    </>
  );
};
export default HistoryActions;
