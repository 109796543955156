import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { loadUserLanguageFromCookie } from "../i18n/i18n";

export function useCustomNavigate() {
  const navigate = useNavigate();
  const language = useMemo(() => loadUserLanguageFromCookie(), []); // 言語をメモ化
  const isMetaKeyPressed = useRef(false);
  const isCtrlKeyPressed = useRef(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey) {
        isMetaKeyPressed.current = true;
      }
      if (event.ctrlKey) {
        isCtrlKeyPressed.current = true;
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.metaKey) {
        isMetaKeyPressed.current = false;
      }
      if (!event.ctrlKey) {
        isCtrlKeyPressed.current = false;
      }
    };

    const handleBlur = () => {
      isMetaKeyPressed.current = false;
      isCtrlKeyPressed.current = false;
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  const customNavigate = useCallback(
    (to: string, options?: any) => {
      let newPath = `/${language}${to}`;
      if (newPath.endsWith("/")) {
        newPath = newPath.slice(0, -1);
      }

      if (isMetaKeyPressed.current || isCtrlKeyPressed.current) {
        window.open(newPath, "_blank");
      } else {
        navigate(newPath, options);
      }
    },
    [language, navigate]
  );

  return customNavigate;
}
