import { Route, Routes } from "react-router-dom";
import React from "react";
import Account from "../pages/Preferences/Account";
import Settings from "../pages/Preferences/Settings";
import PlanAndBillings from "../pages/Preferences/PlanAndBillings";
import Misc from "../pages/Preferences/Misc";

const PreferencesRouter = () => {
  return (
    <Routes>
      <Route path="account" element={<Account />} />
      <Route path="settings" element={<Settings />} />
      <Route path="plan-and-billings" element={<PlanAndBillings />} />
      <Route path="misc" element={<Misc />} />
    </Routes>
  );
};
export default PreferencesRouter;
