import AddBoxIcon from "@mui/icons-material/AddBox";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, Fade, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ScrollableBox from "../../../components/common/ScrollableBox";
import { useAlert } from "../../../context/AlertContext";
import { useBrowsingMethods } from "../../../hooks/useBrowsingMethods";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { RootState } from "../../../redux/store";
import { ColumnBox } from "../../../utils/styledBox";
import HistoryMenu from "./HistoryMenu";

type ChatType = {
  name: string;
  path: string;
  last_history: string;
  time: string;
};

const BrowsingListSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const [chats, setChats] = useState<ChatType[]>([]);
  const updatedChat = useSelector((state: RootState) => state.browsing.triggerFetch);
  const { addNewChat } = useBrowsingMethods();

  const getRelativeTimeString = (dateString: string): string => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return t("browsing.time.now");
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return t("browsing.time.minutes", { count: diffInMinutes });
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return t("browsing.time.hours", { count: diffInHours });
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return t("browsing.time.days", { count: diffInDays });
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return t("browsing.time.weeks", { count: diffInWeeks });
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return t("browsing.time.months", { count: diffInMonths });
    }

    const diffInYears = Math.floor(diffInDays / 365);
    return t("browsing.time.years", { count: diffInYears });

    // デフォルトの戻り値を追加
    return "";
  };

  const newChat = async () => {
    try {
      const res = await addNewChat();
      if (!res) return;
      chats.push(res.data);
      setOpenBrowsing(true);
      get_chats().then(() => {
        setAlert("success", t("browsing.createChat"));
        setChats(chats);
        navigate(res.data.path);
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    get_chats();
  }, [updatedChat]);

  const get_chats = async () => {
    try {
      const res = await axios.get("/api/v1/chat/");
      setChats(res.data.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };
  const theme = useTheme();
  const isSmOrLarger = useMediaQuery(theme.breakpoints.up("sm"));

  const [openBrowsing, setOpenBrowsing] = React.useState(false);

  return (
    <ScrollableBox
      sx={{ borderRadius: "0 0 4px 4px" }}
      onMouseOver={!isSmOrLarger ? () => setOpenBrowsing(true) : undefined}
      onMouseLeave={!isSmOrLarger ? () => setOpenBrowsing(false) : undefined}
      onClick={!isSmOrLarger ? () => setOpenBrowsing(!openBrowsing) : undefined}
    >
      <List>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ListItem>
            <ListItemIcon>
              <ScheduleIcon fontSize={"small"} color={"primary"} />
            </ListItemIcon>
            <ListItemText sx={{ mr: 2 }}>
              <Typography variant={"body2"} component={"span"}>
                {t("browsing.history")}
              </Typography>
            </ListItemText>
            {!isSmOrLarger && (openBrowsing ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <HistoryMenu historyExists={true} chatCount={chats.length} />
        </Box>
        {isSmOrLarger ? (
          <List component="div" sx={{ display: "flex", flexDirection: "column" }}>
            {chats.map((chat, index) => (
              <Fade in={true} key={chat.path} timeout={index * 300}>
                <ListItemButton
                  key={chat.path}
                  onClick={() => navigate(chat.path)}
                  selected={location.pathname.split("/")[3] === chat.path.split("/")[2]}
                  sx={{
                    "&:hover": {
                      backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                    },
                  }}
                >
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon fontSize={"small"} color={"secondary"} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                    }}
                  >
                    <ColumnBox>
                      <Typography
                        variant={"body2"}
                        component={"span"}
                        fontWeight={location.pathname.split("/")[3] === chat.path.split("/")[2] ? 600 : 400}
                      >
                        {chat.name}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"span"}
                        fontWeight={location.pathname.split("/")[3] === chat.path.split("/")[2] ? 600 : 400}
                      >
                        {chat.last_history ? chat.last_history : t("browsing.noHistory")}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"span"}
                        color="text.secondary"
                        sx={{ fontSize: "0.75rem" }}
                      >
                        {chat.time && getRelativeTimeString(chat.time)}
                      </Typography>
                    </ColumnBox>
                  </ListItemText>
                </ListItemButton>
              </Fade>
            ))}

            <ListItemButton
              onClick={() => newChat()}
              sx={{
                "&:hover": {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                },
              }}
              disabled={chats.length >= 10}
            >
              <ListItemIcon>
                <AddBoxIcon fontSize={"small"} color={"primary"} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant={"body2"} component={"span"}>
                  {t("browsing.addChat")}
                </Typography>
              </ListItemText>
            </ListItemButton>
            <Box sx={{ flexGrow: 1 }} />
          </List>
        ) : (
          <Collapse in={openBrowsing} timeout="auto" unmountOnExit>
            <List component="div">
              {chats.map((chat, index) => (
                <Fade in={true} key={chat.path} timeout={index * 100}>
                  <ListItemButton
                    onClick={() => navigate(chat.path)}
                    selected={location.pathname.split("/")[3] === chat.path.split("/")[2]}
                    sx={{
                      "&:hover": {
                        backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                      },
                    }}
                  >
                    <ListItemIcon>
                      <ChatBubbleOutlineIcon fontSize={"small"} color={"secondary"} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                      }}
                    >
                      <ColumnBox>
                        <Typography
                          variant={"body2"}
                          component={"span"}
                          fontWeight={location.pathname.split("/")[3] === chat.path.split("/")[2] ? 600 : 400}
                        >
                          {chat.name}
                        </Typography>
                        <Typography
                          variant={"caption"}
                          component={"span"}
                          fontWeight={location.pathname.split("/")[3] === chat.path.split("/")[2] ? 600 : 400}
                        >
                          {chat.last_history ? chat.last_history : t("browsing.noHistory")}
                        </Typography>
                        <Typography
                          variant={"caption"}
                          component={"span"}
                          color="text.secondary"
                          sx={{ fontSize: "0.75rem" }}
                        >
                          {chat.time && getRelativeTimeString(chat.time)}
                        </Typography>
                      </ColumnBox>
                    </ListItemText>
                  </ListItemButton>
                </Fade>
              ))}
              <ListItemButton
                onClick={() => newChat()}
                sx={{
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                  },
                }}
                disabled={chats.length >= 5}
              >
                <ListItemIcon>
                  <AddBoxIcon fontSize={"small"} color={"primary"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body2"} component={"span"}>
                    {t("browsing.addChat")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        )}
      </List>
    </ScrollableBox>
  );
};
export default BrowsingListSidebar;
