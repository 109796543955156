import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { localDate } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import useTheme from "@mui/material/styles/useTheme";
import axios from "axios";
import { useDispatch } from "react-redux";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, CardHeader, Divider, Grid, Menu, MenuItem } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grow from "@mui/material/Grow";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Pagination from "@mui/material/Pagination";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { uiHeight } from "../../utils/uiHeight";
import { GradationButton } from "../../utils/gradationButton";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import Hidden from "@mui/material/Hidden";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from "@mui/material/CircularProgress";

type TextFileType = {
  uuid: string;
  title: string;
  nodes: [];
  created_at: string;
};

export default function MindMap() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [itemsPerPage, setItemsPerPage] = React.useState(12);
  const itemBoxRef = React.useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [mindMapLists, setMindMapLists] = useState<TextFileType[]>([]);
  const itemHeight = 250;
  const uiLanguage = Cookies.get("ui_language") || "en";
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUuid, setSelectedUuid] = React.useState<string | null>(null);
  const [deletingUuid, setDeletingUuid] = React.useState<string | null>(null);

  useEffect(() => {
    if (itemBoxRef.current) {
      const itemBoxHeight = itemBoxRef.current.clientHeight;
      const itemsPerPage = Math.floor(itemBoxHeight / (itemHeight + 10)) * 6;
      setItemsPerPage(itemsPerPage);
    }
  }, [itemBoxRef]);

  const getMindMapLists = async () => {
    try {
      const res = await axios.get("/api/v1/mind-map/");
      setMindMapLists(res.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    getMindMapLists();
  }, []);

  const dispatch = useDispatch();

  const handleDelete = async (uuid: string) => {
    setDeletingUuid(uuid);
    axios.defaults.withCredentials = true;
    const csrftoken = Cookies.get("csrftoken");
    const config = {
      headers: { "X-CSRFToken": csrftoken },
    };
    const url = "/api/v1/mind-map/";
    try {
      await axios.delete(url + uuid, config);
      setAlert("success", t("file.deleteFile.success"));
      getMindMapLists();
    } catch (error) {
      console.error("Error deleting item:", error);
      setAlert("error", t("file.deleteFile.error"));
    } finally {
      setDeletingUuid(null);
    }
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmedDelete = () => {
    if (selectedUuid) {
      handleDelete(selectedUuid);
    }
    handleDialogClose();
  };

  const handleNewFile = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      await axios.post("/api/v1/mind-map/create", {}, config);
      getMindMapLists().then(() => {
        setAlert("success", t("library.mindmap.message.create"));
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const firstLabel = (nodes: any) => {
    if (nodes && nodes[0] && nodes[0].data) {
      return nodes[0].data.label.trim();
    } else {
      return "";
    }
  };

  const hoverStyles = {
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-5px)",
      opacity: 0.9,
    },
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, uuid: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedUuid(uuid);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Meta
        title={t("library.mindmap.title") + " " + t("drawer.library")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "background.default",
        }}
      >
        <DrawerHeader />
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: uiHeight(),
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }} mb={1} gap={1}>
            <AccountTreeOutlinedIcon color={"secondary"} />
            <Typography variant="h6" component={"h2"} sx={{ position: "relative" }}>
              {t("library.mindmap.title")}
            </Typography>
            <Box flexGrow={1} />
            <Button
              disableElevation
              onClick={handleNewFile}
              variant={"contained"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <AddCircleIcon sx={{ mr: 1 }} />
              <Typography variant="button">{t("library.mindmap.create")}</Typography>
            </Button>
            <GradationButton onClick={() => navigate("/mind-map-generator")}>
              <AutoAwesomeIcon sx={{ mr: 1 }} />
              <Typography variant="button">{t("library.mindmap.generator")}</Typography>
            </GradationButton>
          </Box>

          <Box sx={{ height: "100%", overflowY: "auto", pt: 1, width: "100%" }} ref={itemBoxRef}>
            <Grid container spacing={2}>
              {mindMapLists.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((mindMap, index) => (
                <Grow in={true} key={index}>
                  <Grid item xs={6} md={3} lg={2} key={index}>
                    <Box
                      onClick={() => navigate("/mind-map/" + mindMap.uuid)}
                      sx={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <Card
                        elevation={theme.palette.mode === "light" ? 0 : 1}
                        sx={{
                          height: itemHeight,
                          display: "flex",
                          flexDirection: "column",
                          ...hoverStyles,
                          backgroundColor: "background.paper",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: 10,
                            backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                          }}
                        />
                        <CardHeader
                          title={mindMap.title}
                          titleTypographyProps={{
                            variant: "subtitle1",
                            sx: {
                              whiteSpace: "normal",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              wordBreak: "break-all",
                              height: "3.5em",
                            },
                          }}
                          action={
                            <IconButton
                              edge="end"
                              aria-label="more"
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                handleMenuOpen(event, mindMap.uuid);
                              }}
                              sx={{ mr: 0 }}
                            >
                              <MoreVertIcon fontSize={"small"} />
                            </IconButton>
                          }
                        />
                        <Divider />
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              whiteSpace: "normal",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {firstLabel(mindMap.nodes)}
                          </Typography>
                        </CardContent>
                        <Box flexGrow={1} />
                        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="body2" color="text.secondary">
                            {mindMap.nodes.length} <Hidden smDown>nodes</Hidden>
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {localDate(mindMap.created_at, uiLanguage)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", my: 2 }}>
            <Pagination
              color={"primary"}
              count={Math.ceil(mindMapLists.length / itemsPerPage)}
              variant="outlined"
              page={page + 1}
              onChange={(event, value) => setPage(value - 1)}
            />
          </Box>
        </Container>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => selectedUuid && handleDialogOpen(selectedUuid)}>
            {deletingUuid === selectedUuid ? (
              <CircularProgress size={24} />
            ) : (
              <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
            )}
            {t("common.delete")}
          </MenuItem>
        </Menu>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{t("file.deleteFile.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("file.deleteFile.description")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disableElevation onClick={handleDialogClose} color="primary">
              {t("common.cancel")}
            </Button>
            <Button onClick={handleConfirmedDelete} color="error" disabled={Boolean(deletingUuid)}>
              {deletingUuid ? <CircularProgress size={24} /> : t("common.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
