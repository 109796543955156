const summarize = {
  title: "Powerful Summarizer",
  library: "Library",
  description: "Tenak AI generates summary sentences from the given content.",
  type: "Select Output Type",
  outputText: "Full Text",
  outputList: "Bulleted List",
  URLToSummarize: "URL",
  URLToSummarizeDescription: "AI analyzes the content of the URL and generates a summary.",
  YouTubeToSummarize: "YouTube",
  YouTubeToSummarizeDescription: "AI analyzes the content of YouTube and generates a summary.",
  TextToSummarize: "Text",
  TextToSummarizeDescription: "AI analyzes the text and generates a summary. (High precision)",
  URL: "URL",
  titleInput: "Title",
  titlePlaceholder: 'e.g. "Idea for xxxx", "Task for xxxx", "Cause of xxxx" (Max 100 characters)',
  urlPlaceholder: "https://www.example.com",
  youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )",
  textPlaceholder: "Please enter the text you want to summarize. (Up to 30,000 characters)",
  additional: "Additional instructions (Optional)",
  additionalPlaceholder: "If you have additional instructions, please enter them here. (Up to 400 characters)",
  submit: "Generate Summary",
};
export default summarize;
