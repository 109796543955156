const colorPalette = {
  title: "カラーパレットジェネレーター",
  description: "Tenak AI がキーワードからカラーパレットを心理学的観点から生成",
  placeholder: "（例）パステルカラーの秋模様、高級感のを与えるドリンク",
  generate: "カラーパレットを生成",
  generated: "生成されたカラーパレット",
  caution: "* 生成されたカラーパレットは公開されます。",
  colorDescription: "* 左から順に、メインカラー、サブカラー、サブカラー2、アクセントカラー、アクセントカラー2 ",
  list: "みんなのカラーパレットリスト",
  samples: "カラーパレットサンプル",
};
export default colorPalette;
